import React, { useState } from 'react'
import { Row, Col, FormGroup, Label, Button, Input, Table } from 'reactstrap';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ApiConfig } from "../../../../../api/apiConfig/apiConfig";
import DeleteIcon from '@material-ui/icons/Delete';

const AcademicsStudentsList = (props) => {
    const { studentsDetail,studentImageData } = props;
    const [name, setName] = useState("");
    const [url,setUrl] = useState("");
    const [bio,setBio] = useState("");
    const [countDetails, setCountDetails] = useState([]);
    const [countId, setCountId] = useState(0);
    const [isCountEdit, setIsCountEdit] = useState(false);
    const [countEditIndex, setCountEditIndex] = useState(0);
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);
    const [galleryList, setGalleryList] = useState([]);
    const [fileType, setFileType] = useState('image/jpeg, image/jpg, image/png');

    React.useEffect(() => {
        setCountDetails([...studentsDetail])
    }, [studentsDetail]);

    const handleName = event => {
        setName(event.target.value);
    };

    const handleUrl = event => {
        setUrl(event.target.value)
    }
    const handleBio = event => {
        setBio(event.target.value)
    }
    const getGalleryList = (event) => {
        let fileList = [...event.target.files];
        let item = [];
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (len === item.length) {
                    setGalleryList([...galleryList, ...item])
                }
            };
            reader.readAsDataURL(file);
        });
    }
    const handleGalleryImageDelete = (index) => {
        galleryList.splice(index, 1);
        setGalleryList([...galleryList]);
    }
    const handleProfessorCancel = () => {
        setName("");
        setUrl("");
        setBio("");
        setGalleryList([]);
    };

    const handleProfessorSave = () => {
        let data;
        let fname = {};
        // let imgFile = {};
        // imgFile.push
        studentImageData.push(galleryList)
        console.log("studentImageData",studentImageData)
        galleryList.map((item) => {
            console.log("name",item.name)
            fname ={
            imgPath: item.file ? `/contributors/${item.file.name}` : item.imgPath,
            }
        });
        console.log("fname",fname.imgPath)
        if (isCountEdit) {
            const item = countDetails[countEditIndex];
            item.name = name;
            item.url = url;
            item.bio = bio;
            item.imgUrl = fname.imgPath;
            setCountDetails([...countDetails]);
            props.handleStudentsDetail(countDetails);
        } else {
            data = [
                {
                    name: name,
                    url: url,
                    bio:bio,
                    imgUrl: fname.imgPath,
                }
            ];
            setCountDetails([...data, ...countDetails]);
            let array = [...data, ...countDetails]
            props.handleStudentsDetail(array);
        }
        handleProfessorCancel();
        setIsCountEdit(false);
    };


    const handleProfessorDelete = index => {
        countDetails.splice(index, 1);
        setCountDetails([...countDetails]);
        props.handleStudentsDetail(countDetails);
    };

    const handleProfessorEdit = index => {
        const item = countDetails[index];
        setName(item.name);
        setUrl(item.url);
        setBio(item.bio);
        setIsCountEdit(true);
        setCountEditIndex(parseInt(index));
    };



    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h6 className="h6-small external-title align-center title-bright-grey bg-zircon-grey p-2">
                   Add Students Info :
                </h6>
            </Col>
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="m-auto mt-4"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Student Name :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Professor Name"
                                    id="professorName"
                                    name="professorName"
                                    className="form-control common-form-control"
                                    value={name}
                                    onChange={event => handleName(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Link :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Link"
                                    id="url"
                                    name="url"
                                    className="form-control common-form-control"
                                    value={url}
                                    onChange={(event) => handleUrl(event)}
                                    dateFormat="MM/DD/YYYY"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                Bio :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Bio"
                                    id="bio"
                                    name="bio"
                                    className="form-control common-form-control"
                                    value={bio}
                                    onChange={(event) => handleBio(event)}
                                    dateFormat="MM/DD/YYYY"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Label className="common-label text-left">
                                            Upload Gallery Images* :
                                        </Label>
                                        <div>
                                        </div>
                                        <div
                                            className={`position-relative`}
                                        >
                                            <div className="file-form-container">
                                                <input type="file" value={""} placeholder="Upload File" multiple={true} accept={`${fileType}`} className="form-control common-form-control" onChange={getGalleryList} />
                                                <p className="file-text">{galleryList.length > 0 ? `${galleryList.length} uploaded` : 'upload Files'}</p>
                                            </div>

                                            <div className="pdf-thumbnail-container">
                                                <CloudUploadIcon
                                                    style={{
                                                        color: "#fff",
                                                        fontSize: "2rem",
                                                        borderRadius: 4,
                                                        padding: 2,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                            </FormGroup>
                            {galleryList.length > 0 && fileType !== 'application/pdf' ?
                            <FormGroup row className="mx-0 align-items-center">
                                {galleryList.map((it, index) => (
                                    <Col xs={6} sm={6} md={3} lg={3} xl={3} key={index} className="mb-2">
                                        <div className="position-relative">
                                            <img src={it.imgPath ? `${ApiConfig.url}${it.imgPath}` : it.base64} alt="" className="grid-img-container" />
                                            <div className="trash-icon-container" onClick={() => handleGalleryImageDelete(index)}>
                                                <DeleteIcon style={{
                                                    color: "#fff",
                                                    fontSize: "1rem",

                                                }} />
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </FormGroup>
                            : null}
                    </Col>
                    <Col
                        xs={10}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={6}
                        className="ml-auto mb-3"
                    >
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                    onClick={handleProfessorCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                    onClick={handleProfessorSave}
                                    disabled={name && url && bio ? false : false}
                                >
                                    Save
                                </Button>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Table bordered responsive>
                                    <thead className="custom-table-thead">
                                        <tr>
                                            <th>Name</th>
                                            <th>Link</th>
                                            <th>Bio</th>
                                            <th>Action</th>


                                        </tr>
                                    </thead>
                                    <tbody className="custom-table-tbody">
                                        {studentsDetail.map((it, index) => (
                                            <tr key={index}>
                                                <td>{it.name}</td>
                                                <td>{it.url}</td>
                                                <td>{it.bio}</td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            onClick={() =>
                                                                handleProfessorDelete(index)
                                                            }
                                                        >
                                                            <Button className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                Delete
                                                            </Button>
                                                        </div>
                                                       
                                                            <Button 
                                                             onClick={() =>
                                                                handleProfessorEdit(index)
                                                            }
                                                            className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Edit
                                                            </Button>
                                                       
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
         </Row>
    )
}


export default AcademicsStudentsList;