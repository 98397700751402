import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Bengali/male/Bengali_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Bengali/male/Bengali_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Bengali/female/Bengali_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Bengali/female/Bengali_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Bengali/male/Bengali_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Bengali/male/Bengali_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Bengali/female/Bengali_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Bengali/female/Bengali_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Bengali/male/Bengali_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Bengali/male/Bengali_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Bengali/female/Bengali_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Bengali/female/Bengali_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Bengali/male/Bengali_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Bengali/male/Bengali_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Bengali/female/Bengali_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Bengali/female/Bengali_df_2.mp3"; // Female audio

import audio_em1 from "../../../assets/datasetsample/Bengali/male/Bengali_em_1.mp3"; // Male audio
import audio_em2 from "../../../assets/datasetsample/Bengali/male/Bengali_em_2.mp3"; // Male audio
import audio_ef1 from "../../../assets/datasetsample/Bengali/female/Bengali_ef_1.mp3"; // Female audio
import audio_ef2 from "../../../assets/datasetsample/Bengali/female/Bengali_ef_2.mp3"; // Female audio


function Bengali(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Northern Bengali */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Western Bengali </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">একটো ধরণকার বার্ষিক ফসল যেটা মহিলাদের লিগে উপকারী</p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Western Bengali</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">একুয়াটিকে ওয়িড হতিছে ইক রকমের আগাছা যেটা জলের নিচে জন্মাইতিছে</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Western Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">গরিব গ্রামের লোকদের লিগে এটি সরকার থেকে শুরু করতিছে </p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Western Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">কাটাকুট এক রকমের ন্যাচারাল ফাইবার যাতে করে শক্ত সুতা বানাতিছে</p></td>
            </tr>
            {/* Table Dialect 2 == Varendri / Northern Bengali */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Varendri / Northern Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">কাশ্মিরী উল সব থেকে ভালো এটা অনেক ভাবে ভারতে আর এশিয়াতে পাবো</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Varendri / Northern Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">আমারা যেহেতু জলের অপচয়ের করি তাই আমাদের দুনিয়াতে ওয়াটার ক্রাইসিস হয়</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Varendri / Northern Bengali </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">এক ধরনের যন্ত্র যেটাতে ধান থেকে চাল বানায়</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Varendri / Northern Bengali</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">মাকড়সা প্রাণীর বোনাজালে এক ধরনের প্রোটিন থাকে</p></td>
            </tr>
            {/* Table Dialect 3 == Standard Colloquium Bengali */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Standard Colloquium Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ভারতের কেন্দ্রীয় সরকারের তরফ থেকে দুহাজার সাত সালে চাষের জন্যে স্কিম শুরু হয়েছিল</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Colloquium Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing"> জৈব জ্বালানি পুড়িয়ে কাজ করলে তাতে প্রাকৃতিক দূষন হতে পারে </p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Standard Colloquium Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">আপেল পাহাড়ি অঞ্চলের একপ্রকার পুষ্টিকর ফল যেটা লাল এবং সবুজ রঙে পাওয়া যায়</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Colloquium Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing"> ব্ল্যাক কার্ডামম্ হল কালো এলাচ যেটি ভেষজ হিসেবে ব্যবহৃত হয় </p></td>
            </tr>

            {/* Table Dialect 4 == Southern Bengali */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Jharkhandi Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">উইলউইং মেসিল হছে ইকট ধরলের মেসিল যেটতে শস্য থ্যাকে খড় বা খোসা সরানো হ্যয়</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Jharkhandi Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing"> চাষে রাসায়নিক দ্রব্য ব্যবহারের চেয়ে অর্গানিক চাষ পদ্ধতি অনেক বেশি কার্যকর</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Jharkhandi Bengali </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ডিরেট কেরডিট ব্যাভার ক্যরলে একজলের ব্যাংক থ্যাকে আরেকজলের ব্যাংকে টাকা চ্যলে যায়</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Jharkhandi Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">সাধারণ সেচ এর চেয়ে ড্রিপ সেচ পদ্ধতি বেশি কার্যকর</p></td>
            </tr>

            {/* Table Dialect 3 == Rajbangshi / Jalpaiguri Bengali */}
            <tr> {/* Table Row Sample 17 */}
                <td className="tableEvenDialect"><p className="p-large">Rajbangshi / Jalpaiguri Bengali</p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">জ্বারকালত রবি শস্য হিসাবত বাজারজাত করাং যাই পচার মাধ্যমত আউয়াল বিদ্ধির তানে</p></td>
            </tr>
            <tr> {/* Table Row Sample 18 */}
                    <td className="tableOddDialect"><p className="p-large">Rajbangshi / Jalpaiguri Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing"> টাকা পাঠাইলে কি সঙ্গে সঙ্গে টাকাটা যাবে </p></td>
            </tr>
            <tr> {/* Table Row Sample 19 */}
                <td className="tableEvenDialect"><p className="p-large">Rajbangshi / Jalpaiguri Bengali</p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing"> মেলাগিলা সময়তে মানসি কাজা নাই দিয়ার চেষ্টা করং</p></td>
            </tr>
            <tr> {/* Table Row Sample 20 */}
                    <td className="tableOddDialect"><p className="p-large">Rajbangshi / Jalpaiguri Bengali</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing"> টাকা লেনদেন এর জইন্যে মাসে কত টাকা হামাক ট্যাক্স দিবার নাগে </p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleBengali = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleBengali"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleBengali"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleBengali"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleBengali" />
                    <Strip
                        id="tst-strip-SampleBengali"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Bengali Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Bengali />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleBengali;