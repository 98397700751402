import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Hindi/male/Hindi_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Hindi/male/Hindi_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Hindi/female/Hindi_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Hindi/female/Hindi_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Hindi/male/Hindi_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Hindi/male/Hindi_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Hindi/female/Hindi_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Hindi/female/Hindi_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Hindi/male/Hindi_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Hindi/male/Hindi_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Hindi/female/Hindi_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Hindi/female/Hindi_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Hindi/male/Hindi_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Hindi/male/Hindi_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Hindi/female/Hindi_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Hindi/female/Hindi_df_2.mp3"; // Female audio

import audio_em1 from "../../../assets/datasetsample/Hindi/male/Hindi_em_1.mp3"; // Male audio
import audio_em2 from "../../../assets/datasetsample/Hindi/male/Hindi_em_2.mp3"; // Male audio
import audio_ef1 from "../../../assets/datasetsample/Hindi/female/Hindi_ef_1.mp3"; // Female audio
import audio_ef2 from "../../../assets/datasetsample/Hindi/female/Hindi_ef_2.mp3"; // Female audio


function Hindi(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Hindustani Malvi */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Hindustani Malvi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    कीटनाशक और शाकनाशी भी कृषि रसायन हैं
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Hindustani Malvi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">लकड़ी के गूदे से कागज को बनाया जाता है</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Hindustani Malvi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">देखो राजू मुझे मेरी गाड़ी के बदले कुछ पैसे चाहिए थे</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Hindustani Malvi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">भैया मैंने सुना है कि अरारोट का सेवन केक पुडिंग और बिस्कुट के रूप में किया जाता है</p></td>
            </tr>
            {/* Table Dialect 2 == Kanauji Braj */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Kanauji Braj </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">अमरूद मेरा पसंदीदा फल है</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Kanauji Braj</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">रजनीगंधा बहुत ही खुशबूदार फूल होता है</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Kanauji Braj </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">प्रत्येक खाते को लिमिट के अनुसार ही धन निकासी या ट्रांसफर की इजाजत दी जाती है</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Kanauji Braj</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">रमेश भैंस को बीमार होने की वजह से नजदीकी पशु कल्याण विभाग में ले गया</p></td>
            </tr>
            {/* Table Dialect 3 == Awadhi Bundeli */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Awadhi Bundeli </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">हम एक खाते से दूसरे खाते में पैसे कैसे भेज सकते हैं ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Awadhi Bundeli</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">सबसे अच्छा पशुओं को देने वाली परिशिष्ट क्या है ? कौन सी होती है ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Awadhi Bundeli </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">क्या मैं अपने नाम से एल.आई.सी बीमा खोल सकता हूँ ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Awadhi Bundeli</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">बचत खाता खोलने के क्या लाभ हैं ?</p></td>
            </tr>
                {/* Table Dialect 4 == Marwar Dhudhari */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Marwar Dhudhari </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">मूंगफली की खेती की सिंचाई के लिए पानी की कितनी मात्रा होनी चाहिए ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Marwar Dhudhari</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">यू.पी.आई खाता खोलने के लिए किन दस्तावेज़ों की आवश्यकता होती है ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Marwar Dhudhari </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">यू.पी.आई खाता खोलने के लिए किन दस्तावेज़ों की आवश्यकता होती है ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Marwar Dhudhari</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">सरकारी योजनाओं के लिए कैसे आवेदन कर सकते हैं ?</p></td>
            </tr>

        {/* Table Dialect 5 == Garhwali Tehri */}
            <tr> {/* Table Row Sample 17 */}
                <td className="tableEvenDialect"><p className="p-large">Garhwali Tehri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">मुझे अपने बचत खाते में कोई नया व्यक्ति जोड़ना है इसके लिए क्या क्या दस्तावेज़ चाहिए ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 18 */}
                    <td className="tableOddDialect"><p className="p-large">Garhwali Tehri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">नकदी फसलों के लिए मिट्टी की गुणवत्ता कैसी रखनी चाहिए ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 19 */}
                <td className="tableEvenDialect"><p className="p-large">Garhwali Tehri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">दूध उत्पादन के लिए कौन सा चारा सर्वोत्तम है ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 20 */}
                    <td className="tableOddDialect"><p className="p-large">Garhwali Tehri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">पहाड़ी क्षेत्रों में रबी के मौसम में हम कौन कौन सी फसल लगा सकते हैं ?</p></td>
            </tr>




            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleHindi = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleHindi"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleHindi"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleHindi"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleHindi" />
                    <Strip
                        id="tst-strip-SampleHindi"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Hindi Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Hindi />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleHindi;