import React, { useState } from 'react';
import Slider from "react-slick";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from 'reactstrap';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';

function SampleNextArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={`${className} next-icon-container`}
            style={{ ...style, display: "block",}}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined className= "lr-arrow" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={`${className} prev-icon-container`}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined className= "lr-arrow" />
        </div>
    );
}


const ImageList = (props) => {
    const [width] = useWindowSize();
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [imageData, setImageData] = useState([]);

    const settings = {
        dots: true,
        infinite: true,
        loop: true,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: width >= 1200 ? 3 : (width >= 768 && width <= 1023) ? 1 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow  />,
        prevArrow: <SamplePrevArrow  />,
    };

    const settingsNotLoop = {
        dots: true,
        infinite: width >= 1200 ? true : (width >= 768 && width <= 1023) ? true : 1,
        loop: width >= 1200 ? true : (width >= 768 && width <= 1023) ? true : 1,
        speed: 500,
        slidesToShow: width >= 1200 ? 2 : (width >= 768 && width <= 1023) ? 1 : 1,
        slidesToScroll: 1,
       
      
    };

    const handleToggle = () => {
        setImageData([...props.data])
        setIsModal(!isModal)
    }

    React.useEffect(() => {
        setImageData([...props.data])
    }, props.data)

    const handleToggleIndex = (imageListForPopUp, index) => {
        let list = imageData;
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }

    return (
        <Row className="mx-0 ">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                {props.data.length > 3 ?
                    <Slider {...settings}>
                        {props.data.length > 0 && props.data.map((item, index) => (
                            <Row key={index} className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0"  >
                                    {/* <ImageLists item={item} index={index} /> */}
                                    <div className="bg-white cursor-pointer aboutus-img">
                                    <img src={item} alt="" className="object-fit_fill" index={index} />
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Slider> :
                    <div>
                        <Slider {...settingsNotLoop}>
                            {props.data.length > 0 && props.data.map((item, index) => (
                                <Row key={index} className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                        <div className="bg-white cursor-pointer">
                                            <img src={item} alt=""  className="img-item " index={index} />
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </Slider>
                    </div>}
            </Col>
        </Row>
    )
}


export default ImageList;