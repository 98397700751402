import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const PartnerService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdatePartner } = ApiConfig;

        const url = baseUrl + insertOrUpdatePartner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id : data.id,
            imgPath : `${data.photo}`,
            description : `${data.description}`,
            link: `${data.link}`,
            linkText: `${data.linkText}`
        });

        const partnerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_partnerService",
            true
        );
        return partnerServicePromise;
    },

    getPartnerList: async () => {
        const { baseUrl, getPartnerList } = ApiConfig;

        const url = baseUrl + getPartnerList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const partnerServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_partner",
            true
        );
        return partnerServicePromise;
    },

    enableDisableUser: async (data) => {
        const { baseUrl, enableDisablePartner } = ApiConfig;

        const url = baseUrl + enableDisablePartner;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id : data.id,
            status : `${data.status}`,
        });

        const enableDisablePartnerServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisablePartner",
            true
        );
        return enableDisablePartnerServicePromise;
    },

};

export default PartnerService;
