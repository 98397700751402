import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import OpeningCardData from "../../Utils/Json/OpeningCardData"
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SocialIcon from '../../customComponent/SocialIcon/SocialIcon';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import ContributorService from '../../../api/services/ContributorService';
import debugLogger from '../../Utils/debugLogger';
import { usePeople } from "../../context/PeopleProvider";
import { alertDangerConfirmMessage } from "../../customComponent/confirmationMessage/ConfirmationMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
const Bio = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [peopleState, { getAllPeopleList }] = usePeople();
    const [width] = useWindowSize();
    const [advisorTeam, setAdvisorTeam] = useState([]);
    const [teamMember, setTeamMember] = useState([]);
    const [postdoctoralTeam, setPostDoctoralTeam] = useState([]);
    const [researchTeam, setReSearchTeam] = useState([]);
    const [managerTeam, setManagerTeam] = useState([]);
    const [professor,setProfessor] = useState('');
    const [name,setName] = useState('');
    const [designation,setDesignation] = useState('');
    const [bio,setBio] = useState('');
    const [imgUrl,setImgUrl] = useState('');
    const [firstClassName,setFirstClassName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    React.useEffect(() => {
      window.scrollTo(0, 0);
      getList();
      setSectionTitle(props.location.pathname.split('/')[2]);
    }, [])
  
    const getList = async () => {
        let it = [];
        await ContributorService.getContributorsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("result",result)
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.academicsList.list.map((item) => {
                                        if(item.professors !== null) {
                                            // it.push(item.professors)
                                            // console.log("it",it)
                                            // setProfessor(it ? JSON.parse(it) : [])
                                            // it.map((items) => {
                                                let prof = JSON.parse(item.professors)
                                                console.log("location",prof)
                                                prof.map((itemdata) => {
                                                    if(itemdata.name.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                                        // setProfessor(item.professors ? JSON.parse(item.professors) : [])
                                                        setProfessor(itemdata.name)
                                                        setName(itemdata.name)
                                                        setDesignation(itemdata.designation)
                                                        setImgUrl(itemdata.imgUrl)
                                                        setBio(itemdata.bio)
                                                         console.log("pass",professor)
                                                         
                                                 }
                                                 
                                                })
                                            //     console.log(it.name.toLowerCase())
                                            //     console.log(props.location.pathname.split('/')[2].toLowerCase())
                                            
                                            // })
                                           
                                        }
                                        // if (item.instituteName.toLowerCase() === props.location.pathname.split('/')[3].toLowerCase()) {
                                        //     // it.push(item);
                                        //     setTitle(item.instituteName)
                                        //     // setPlace(item.place)
                                        //     // setUrl(item.url)
                                        //     // setImgUrl(item.imgUrl)
                                        //     // // setCode(item.code)
                                        //     // // setDescription(item.description)
                                        //     // setProfessor(item.professors ? JSON.parse(item.professors) : [])
                                        //     // setStudents(item.students ? JSON.parse(item.students) : [])
                                        //     // setDescription(item.description ? JSON.parse(item.description) : [])
                                        //     // console.log("academics professors",professor)
                                        //     // console.log("item",item.keyTakeAways ? JSON.parse(item.keyTakeAways) : [])
                                        //     // setTableInfo(item.websiteSource ? JSON.parse(item.websiteSource) : [])
                                        // }                                       
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    console.log("data",data)
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }
  
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`/team/${sectionTitle}`} />
                        <Row className="mx-0 p-3">
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/contributors`} className="custom-link-item">{`Contributors> Academics > Professor >`}</Link>
                                    <span className="fw-600 pl-2 text-capitalize">{`${sectionTitle}`} </span>
                                </p>
                                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
                                    {title ? title : ''}
                                </h5> :
                                    <h2 className="h2-small section-title align-left pb-2 line-height-48px">
                                        {title ? title : ''}
                                    </h2>
                                }
                            </Col>
                                  <Row className="mx-0">
                                   <Col
                                     xs={12}
                                     ms={12}
                                     md={12}
                                     lg={12}
                                     xl={12}
                                     className="team-mobile-position pb-5 "
                                   >
                                    
                                       <div className="team-img-container"> <img src={`${ApiConfig.url}${imgUrl}`} alt="" />
                                       <div className="third-circle-container" style={{border: `15px solid ${firstClassName}`,}}>
                                           </div>
                                         </div>
                                     
                                 </Col>        
                             </Row>
                                     
                                   <Col xs={12} ms={12} md={12} lg={9} xl={9} className="mx-0 p-0">
                                   {/* {teamMember.length > 0 && teamMember.map((item, index) => ( */}
                                             <div>
                                               <Row className="pr-1 pb-5">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <h6 className="h6-medium mb-0 section-title align-left">{name}</h6>
                                                    </Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="mb-1 section-title align-left">{designation}</p>
                                                    </Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="mb-0 section-title align-left">{bio}</p>
                                                    </Col>
                                              {/* <Col xs={12} ms={12} md={12} lg={12} xl={12}></Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                <a href={`${item.link}`} rel="noreferrer" target="_blank" className="p-small section-title mb-0 line-height-20px custom-reference-links text-underline font-size-20px">
                                                {item.link}</a>
                                                </Col> */}
                                                </Row>
                                             </div> 
                                               {/* ))}  */}
                                            </Col>
                                            
                               {/* </Col> */}
                        </Row>
                    </Strip>
                    {/* <SocialIcon /> */}
                </div>
            </RenderPage>
        </ExternalLayout>
    )
}
export default Bio;