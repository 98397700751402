import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col, Nav, NavItem, NavLink,TabContent, TabPane,Button,FormGroup, Label, Input,yy } from "reactstrap";
import application_contants from "../../constants/application_contant";
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import ContributorsType from "../../Utils/Json/ContributorsType";
import ContributorService from '../../../api/services/ContributorService';
import Individual from "../../customPages/Contributors/Individual";
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import Experts from "./Experts";

const Contributors = (props) => {
    const [width] = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState([]);
    const [academics,setAcademics] = useState([]);
    const [industry,setIndustry] = useState([]);
    const [experts,setExperts] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [individual, setIndividual] = useState(0);
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])

    const handleIndividual = (item) => {
        setIndividual([...item])
    }

    const handleExperts = (item) => {
        setExperts([...item])
    }

    const getList = async () => {
        setIsLoading(true);
        await ContributorService.getContributorsList()
            .then(async (res) => {
                console.log("openings result",res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("before contributors data list",result)
                            if (result.code === 0) {
                                if (result.data) {
                                    const lists = result.data.academicsList.list.filter((it) => it.status === 1);
                                    setAcademics([...lists])
                                    const lists2 = result.data.industryList.list.filter((it) => it.status === 1);
                                    setIndustry([...lists2])
                                    const lists3 = result.data.individualList.list.filter((it) => it.status === 1);
                                    setIndividual([...lists3])
                                    const lists4 = result.data.expertsList.list.filter((it) => it.status === 1);
                                    setExperts([...lists4])
                                    
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-contributors"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-contributors"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Contributors"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/contributors" />
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={9} xl={11} className="m-auto px-0 pt-0">
                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title nunitoSans-Light pb-5 pt-4 align-left">  Contributors</h5>: 
                                <h5 className="h2-small section-title nunitoSans-Light align-left pb-5 line-height-48px pl-3"> Contributors</h5>}
                                <Nav tabs className="tab-container mx-0">
                                    {ContributorsType.map((it, index) => (
                                        <NavItem className="tab-nav-item" key={index}>
                                            <NavLink
                                                className={`tab-nav-link ${classnames({ active: activeTab === index })}`}
                                                onClick={() => { toggle(index); }}
                                            >
                                                {it.label}
                                            </NavLink>
                                        </NavItem> 
                                    ))}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={0} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                <div className="pb-5">
                                                    <Row className="mx-0 pt-2 pb-2">
                                                    {academics.length > 0 && academics.map((item, index) => {
                                                        return <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 12} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"  key={index} >
                                                                <a href={`/contributors/academics/${item.instituteName.toLowerCase()}`}>
                                                                <Row className="mb-0 mx-0">
                                                                <Col xs={3} sm={3} md={3} lg={11} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                                    <div>
                                                                        <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">{item.instituteName}</p>
                                                                        <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">{item.place}</p>
                                                                    </div>
                                                                        <div className="align-right pb-2">
                                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                        </div>
                                                                </Col>
                                                                </Row>
                                                                </a>
                                                            </Col>
                                                    })} 
                                                        </Row>
                                                    </div>
                                                    :
                                                    <div className="pb-5">
                                                    <Row className="mx-0 pt-2 pb-2">
                                                    {academics.length > 0 && academics.map((item, index) => {
                                                        return <Col xs={12} sm={12} md={3} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"  key={index} >
                                                                <a href={`/contributors/academics/${item.instituteName.toLowerCase()}`}>
                                                                <Row className="mb-0 mx-0">
                                                                <Col xs={12} sm={12} md={12} lg={11} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                                    <div>
                                                                        <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">{item.instituteName}</p>
                                                                        <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">{item.place}</p>
                                                                    </div>
                                                                        <div className="align-right pb-2">
                                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                        </div>
                                                                </Col>
                                                                </Row>
                                                                </a>
                                                            </Col>
                                                    })} 
                                                        </Row>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId={1} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                            {width >= application_contants.MOBILE_BREAKPOINT ?
                                                <div className="pb-5">
                                                    {industry.length > 0 ?
                                                    <Row className="mx-0 pt-2 pb-2">
                                                         {industry.length > 0 && industry.map((item, index) => {
                                                            return <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"  key={index} >
                                                            <a href={`contributors/Industry/${item.instituteName.toLowerCase()}`}>
                                                                    <Row className="mb-0 mx-0">
                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                                        <div>
                                                                            <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">{item.instituteName}</p>
                                                                            <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">{item.place}</p>
                                                                        </div>
                                                                            <div className="align-right pb-2">
                                                                                <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                            </div>
                                                                    </Col>
                                                                    </Row>
                                                                     </a>
                                                                </Col>
                                                            })}   
                                                        </Row> 
                                                           :
                                                           <div className="pb-5 mb-5">
                                                           <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-2 pr-2 pt-2 section-title align-left">
                                                          <Link to={`/Contributors`} className="pl-0 nunitoSans-Light align-left custom-link-item"></Link>
                                                          <span className="foo  line-height-48px nunitoSans-Light text-color-nero-grey text-capitalize"> Coming Soon</span>
                                                       </h5 >
                                                       </div>
                                                      }
                                                    </div>
                                                    :
                                                    <div className="pb-5">
                                                    <Row className="mx-0 pt-2 pb-2">
                                                         {industry.length > 0 && industry.map((item, index) => {
                                                            return <Col xs={12} sm={12} md={12} lg={width === 1024 ? 5 : 3} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"  key={index} >
                                                          <a href={`contributors/Industry/${item.instituteName.toLowerCase()}`}>
                                                                    <Row className="mb-0 mx-0">
                                                                    <Col xs={12} sm={12} md={12} lg={3} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                                        <div>
                                                                            <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">{item.instituteName}</p>
                                                                            <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">{item.place}</p>
                                                                        </div>
                                                                            <div className="align-right pb-2">
                                                                                <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                            </div>
                                                                    </Col>
                                                                    </Row>
                                                                     </a>
                                                                 </Col>
                                                            })}  
                                                        </Row>
                                                    </div>
                                                }
                                                </Col>
                                            </Row>
                                    </TabPane>
                                    <TabPane tabId={2} className="">
                                         <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                    <Row className="mx-0 pb-5">
                                                           
                                                            <Row className="mx-0 pt-4 pb-4 ">
                                                                 {individual.length > 0  && individual.map((item,index) => (
                                                                    <Col xs={12} sm={12} md={12} lg={4} xl={3} className="pt-4 pb-5">                                                                                           
                                                                     <div className="team-img-container team">
                                                                     </div>
                                                                                            
                                                                                    <Individual item={item} />
                                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                    </Row> 
                                                
                                                    :
                                                    
                                                      <Row className="mx-0 pt-4 pb-2 ">
                                                      {individual.length > 0  && individual.map((item,index) => (
                                                         <Col xs={12} sm={12} md={12} lg={8} xl={6} className="pt-4">                     
                                                         <div className="team-img-container team">
                                                                                
                                                                             </div> 
                                                                        
                                                                         <Individual item={item} />
                                                                         </Col>
                                                     ))}
                                                 </Row>
                                                }
                                            </Col>
                                        </Row> 
                                    </TabPane>
                                    <TabPane tabId={3} className="">
                                        <Row className="pt-4 mx-0">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 ">
                                                {width >= application_contants.MOBILE_BREAKPOINT ?
                                                    <Row className="mx-0 pb-5">
                                                           
                                                            <Row className="mx-0 pt-4 pb-4 ">
                                                                 {experts.length > 0  && experts.map((item,index) => (
                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pt-4 pb-5">                                                                                           
                                                                     <div className="team-img-container team">
                                                                     </div>
                                                                                            
                                                                                    <Experts item={item} />
                                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                    </Row> 
                                                
                                                    :
                                                    
                                                      <Row className="mx-0 pt-4 ">
                                                      {experts.length > 0  && experts.map((item,index) => (
                                                         <Col xs={12} sm={12} md={12} lg={12} xl={6} className="pt-2">                     
                                                         <div className="team-img-container team">
                                                                                
                                                                             </div> 
                                                                        
                                                                         <Experts item={item} />
                                                                         </Col>
                                                     ))}
                                                 </Row>
                                                }
                                            </Col>
                                        </Row> 
                                    </TabPane>
                                    
                                </TabContent>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};
Contributors.defaultProps = {
    isClassName: true,
};
Contributors.propTypes = {
    isClassName: PropTypes.bool,
};
export default Contributors;