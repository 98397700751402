import application_contants from "../../constants/application_contant";

const HomeVideoData = [
    {
        sectionTitle: 'How we are working on it.',
        img: application_contants.GalleryImg1,
        // videoTitle: 'How we are working on it and how research is done.',
        desc: 'Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good in the open source domain to spur research and innovation in speech recognition in nine different Indian languages in the area of agriculture and finance. Nine Indian languages considered for this project are Hindi, Bengali, Marathi, Telugu, Bhojpuri, Kannada, Magadhi, Chhattisgarhi, and Maithili. ',
    }
]

export default HomeVideoData;