import React from "react";
import memoizeOne from "memoize-one";
import { DeleteOutlineOutlined, CreateOutlined } from "@material-ui/icons";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const TablesColumns = memoizeOne((handleEdit, handleDelete) => [
    {
        name: "Reseach Title",
        selector: "reseachTitle",
        grow: 0.2,
        sortable: false,
        cell: (row) => <div>{row.reseachTitle} </div>,
    },
    {
        name: "Title",
        selector: "title",
        sortable: false,
        cell: (row) => <div>{row.title}</div>,
    },
    // {
    //     name: "Designation",
    //     selector: "name",
    //     sortable: false,
    //     cell: (row) => <div>{row.name}</div>,
    // },
    // {
    //     name: "Description",
    //     selector: "description",
    //     sortable: false,
    //     cell: (row) => <div>{`${row.description.length > 50 ? `${row.description.substr(0, 50)}...` : row.description}`}</div>,
    // },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        grow: 0.3,
        cell: (row) => <div>{`${row.status === 1 ? 'Active' : 'InActive'}`}</div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        grow: 0.3,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex">
                <div onClick={() => handleDelete(row)}>
                    <DeleteOutlineOutlined style={{ color: "#ec0707" }} />
                </div>
                <div onClick={() => handleEdit(row)}>
                    <CreateOutlined style={{ color: "#00BCD4" }} />
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
