import React, { useState } from 'react';
import Select from "react-select";
import Axios from "axios";
import { ModalBody, Row, Col, Modal, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';
import Loader from '../../../customComponent/Loader/Loader';
import Strip from '../../../customComponent/Strip/Strip';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import PeopleService from '../../../../api/services/PeopleService';
import PeopleTypeData from '../../../Utils/Json/PeopleTypeData';

const AddPeople = (props) => {
    const [photo, setPhoto] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [description, setDescription] = useState('');
    const [peopleId, setPeopleId] = useState('');
    const [designationData, setDesignationData] = useState([]);
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState('');
    const [typeId, setTypeId] = useState('');
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setName('');
            setDescription('');
            setPhotoFileName('');
            setDesignation('');
            setPeopleId('');
            setPhoto('')
            setLink('');
            setTypeId('');
        } else {
            setName(props.peopleItem.fullName)
            setDescription(props.peopleItem.description);
            let it = props.list.find((item) => item.value === props.peopleItem.designationId);
            setDesignation(it);
            setPhotoFileName(props.peopleItem.photoURL);
            setPeopleId(props.peopleItem.peopleId)
            setPhoto(props.peopleItem.photoURL)
            setLink(props.peopleItem.link);
            let typeItem = PeopleTypeData.find((it) => it.value === props.peopleItem.typeId);
            setTypeId(typeItem);
        }
        setIsFileChanged(false);
        setDesignationData(props.list)
    }, [isAdd, props]);

    const handleName = (event) => {
        setName(event.target.value)
    };

    const handleTypeId = (item) => {
        setTypeId(item);
    }

    const handleDesignation = (item) => {
        setDesignation(item);
    }

    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleLink = (event) => {
        setLink(event.target.value);
    }

    const getThumbnail = (item) => {
        setPhoto(item[0].base64);
        setPhotoFile(item[0].file);
        setPhotoFileName(item[0].file.name);
        setIsFileChanged(true);
    };

    const handleSubmit = async () => {
        const id = await localStorage.getItem('userId')
        let data = {
            photo: isFileChanged ? `/academics/${photoFileName}` : photoFileName,
            name: name,
            description: description,
            designationId: designation ? designation.value : '',
            userId: id,
            peopleId: peopleId,
            link: link,
            typeId: typeId ? typeId.value : 1
        };

        if (isFileChanged) {
            UploadImage(photoFile, data)
        } else {
            insertOrUpdate(data);
        }
    }

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/academics/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        console.log('photos : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };


    const insertOrUpdate = async (data) => {
        await PeopleService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-people"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-dark-pink">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={10} sm={10} md={10} lg={6} xl={6} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                            <Col
                                                                xs={6}
                                                                sm={6}
                                                                md={6}
                                                                lg={6}
                                                                xl={6}
                                                                className="d-flex"
                                                            >
                                                                {!photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={false}
                                                                    //isDefault={isDefaultThumbnail}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${isFileChanged ? `${photo}` : `${ApiConfig.url}${photo}`}`}
                                                                        alt={""}
                                                                    />
                                                                )}
                                                                {photo ? (
                                                                    <FileUploader
                                                                        isImg={true}
                                                                        getThumbnail={(item) =>
                                                                            getThumbnail(item)
                                                                        }
                                                                        isEditable={true}

                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Name*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Name"
                                                                    id="name"
                                                                    name="name"
                                                                    className="form-control common-form-control"
                                                                    value={name}
                                                                    onChange={(event) => handleName(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Link:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="link"
                                                                    id="link"
                                                                    name="link"
                                                                    className="form-control common-form-control"
                                                                    value={link}
                                                                    onChange={(event) => handleLink(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    TypeId*:
                                                                </Label>
                                                                <Select
                                                                    value={typeId}
                                                                    onChange={handleTypeId}
                                                                    options={PeopleTypeData}
                                                                    placeholder={"Select Type"}
                                                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                                    isSearchable={true}
                                                                    isClearable
                                                                    maxMenuHeight={200}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Designation*:
                                                                </Label>
                                                                <Select
                                                                    value={designation}
                                                                    onChange={handleDesignation}
                                                                    options={designationData}
                                                                    placeholder={"Select Designation"}
                                                                    classNamePrefix="common-select-drop-down common-select-drop-down-large"
                                                                    isSearchable={true}
                                                                    isClearable
                                                                    maxMenuHeight={200}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Label className="common-label text-left">
                                                                    Description:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="description"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 4, size: 6 }}
                                                    lg={{ offset: 4, size: 6 }}
                                                    xl={{ offset: 4, size: 6 }}
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                                disabled={
                                                                    !name &&
                                                                    !designation &&
                                                                    !typeId &&
                                                                    !photo
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}

export default AddPeople;