import React,{useState, useEffect} from 'react';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import ContactUsMap from '../ContactUsMap/ContactUsMap';


const ContactComponent = () => {

    return (
        <Row className="mx-0">
        {/*     <Col xs={12} sm={12} md={6} lg={5} xl={5} className="px-0">
                <ContactUsForm />
            </Col> */}
            <Col xs={12} sm={12} md={6} lg={7} xl={7}>
                <Row className="no-mobile--margin">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/*    <div className="map-container map-padding">
                             <ContactUsMap /> */}
                             <div className='map-padding'>
                         <a href="https://goo.gl/maps/Xx7ZSdG2u8Brjx5R6" target='_blank'><img src={application_contants.spiremap} alt="" /></a>
                        </div>
                    </Col>
                </Row>
                </Col>
                <Col>
                {/* <Row className='mobile-contactus-padding'> */}
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-3">
                        <div className="d-flex align-items-start">
                            <div><img src={application_contants.emailImg} alt="" className="contact-icon-email---width" /></div>
                            <div>
                                <p className="sub-section-title pl-2">Email :</p>
                                <p className="mb-0 sub-section-title fw-600 pl-2">
                                    contact.respin@iisc.ac.in, contact@respin.co.in, contact.respin@gmail.com
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="d-flex align-items-start">
                            <div><img src={application_contants.locationImg} alt="" className="contact-icon--width" /></div>
                            <div>
                                <p className="sub-section-title pl-2">Address :</p>
                                <p className="mb-0 sub-section-title fw-600 pl-2"> SPIRE LAB, Room No. EE C 326, Department of Electrical Engineering, Indian Institute of Science (IISc), Bangalore-560012, Karnataka, India</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ContactComponent;