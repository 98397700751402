import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import {
  useWindowSize
} from '@react-hook/window-size';
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import BannerService from "../../../api/services/BannerService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import MediaComponent from "../../customComponent/MediaComponent/MediaComponent";
import MediaData from "../../Utils/Json/MediaData";
import HomeContactComponent from "../../customComponent/HomeContactComponent/HomeContactComponent";
import ContactComponent from "../../customComponent/ContactComponent/ContactComponent";
import TitleComponent from "../../customComponent/TitleComponent/TitleComponent";
import ChallengesComponent from "../../customComponent/ChallengesComponent/ChallengesComponent";
import WorkshopComponent from "../../customComponent/WorkshopComponent/WorkshopComponent";
import OpeningComponent from "../../customComponent/OpeningComponent/OpeningComponent";
import SingleGridVideo from "../../customComponent/SingleGridVideo/SingleGridVideo";
import ImgGrid from "../../customComponent/ImgGrid/ImgGrid";

const Home = () => {
  const [width] = useWindowSize();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getList();
  }, [])

  const getList = async () => {
    setIsLoading(true)
    await BannerService.getBannerList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                if (result.data) {
                  const lists = result.data.list.filter((it) => it.status === 1);
                  setData([...lists])
                  setIsLoading(false);
                } else {
                  setData([]);
                  setIsLoading(false);
                }
              } else {
                setData([]);
                setIsLoading(false);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }


  return (
    <ExternalLayout>
      <RenderPage
        id="tst-landing"
        className={`render px-0 render-page`}
        containerType="container-fluid"
      >
        <Strip
          id="tst-Home"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <SEOComponent
            title="Home"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
            siteUrl="/Home" />
          <div>
            <div>
              {width <= application_contants.MOBILE_BREAKPOINT ?
                <div>
                  {data.length > 0 && data.map((item, index) => (
                    <Row className="mx-0 mt-5 pt-4">
                      <Col
                        xs={11}
                        sm={11}
                        md={11}
                        lg={12}
                        xl={10}
                        className="m-auto"
                      >
                         <h6 className="h6-large section-title  line-height-20px">{item.welcomeTitle}
                        </h6>
                        <h5 className="h5-medium section-title  line-height-35px pt-1">{item.title}
                        </h5>

                      </Col>
                      <Col xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12} className="px-0 pt-6 pb-5">
                        <img src={`${ApiConfig.url}${item.imgPath}`} alt="" />
                      </Col>
                      <Col xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}>
                        <Row className="mx-0 pt-5 pb-5">
                          <Col xs={11}
                            sm={11}
                            md={10}
                            lg={8}
                            xl={8} className="m-auto">
                            {item.description.split(/[\n\r]/g).map((params, i) => (
                              <p className="p-large mb-0 section-title mobile-text-position pb-3 text-center" key={i}>{params}</p>
                            ))}

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </div>
                :
                <div>
                  {data.length > 0 && data.map((it, index) => (
                    <div>
                      <Row className="mx-0 mt-2" key={index}>
                        <Col className="px-0 banner-padding">
                          <img src={`${ApiConfig.url}${it.imgPath}`} alt="" />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={10}
                          className="home-title__container"
                        >
                          <h1 className="h6-small welcome-header">
                            {it.welcomeTitle}
                          </h1>
                          
                          <h1 className="h1-large <br> section-title line-height-54px">
                            {it.title}
                          </h1>
                        </Col>
                      </Row>
                      <Row className="mx-0 pt-5">
                        <Col xs={11}
                          sm={11}
                          md={10}
                          lg={10}
                          xl={10} className="m-auto">
                          {it.description.split(/[\n\r]/g).map((params, i) => (
                            <p className="p-large mb-0 section-title mobile-text-position pb-3 text-center" key={i}>{params}</p>
                          ))}

                        </Col>
                      </Row>

                    </div>
                  ))}

                </div>
              }
            </div>
            <Strip
              id="tst-strip-Video"
              className="strip strip-no-padding bg-white-smoke-medium"
              containerType="container-fluid mb-5 mt-5">
              <div className="container">
                <Row className="mx-0">
                  <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-5 px-0 m-auto">
                    <SingleGridVideo />
                  </Col>
                </Row>
              </div>
            </Strip>
            <Strip
              id="tst-strip-gallery"
              className="strip strip-no-padding"
              containerType="container">
              <Row className="mx-0 pt-5">
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-1 px-0 m-auto">
                  <TitleComponent title={'Gallery'} pathName={'gallery'} isViewAll={true} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="pb-5 px-0 m-auto">
                  <ImgGrid />
                </Col>
              </Row>
            </Strip>
            {/* <Strip
              id="tst-strip-Media"
              className="strip strip-no-padding"
              containerType="container">
              <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <TitleComponent title={'Media'} pathName={'media'} isViewAll={true} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <MediaComponent data={MediaData} />
                </Col>
              </Row>
            </Strip> */}
            <Strip
              id="tst-strip-Challenges"
              className="strip strip-no-padding"
              containerType="container">
              <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <ChallengesComponent firstSectionTitle={`Upcoming Challenges and workshops`} secondSectionTitle={`Previous Challenges`} isSecond={false} />
                </Col>
              </Row>
            </Strip>
            <Strip
              id="tst-strip-opening"
              className="strip strip-no-padding"
              containerType="container">
              <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <OpeningComponent firstSectionTitle={`Openings`} secondSectionTitle={`Other Openings`} isSecond={false} />
                </Col>
              </Row>
            </Strip>
            <Strip
              id="tst-strip-Contact"
              className="strip strip-no-padding"
              containerType="container">
              <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <TitleComponent title={'Contact Us'} pathName={''} isViewAll={false} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={11} xl={11} className="py-4 px-0 m-auto">
                  <ContactComponent />
                </Col>
                
              </Row>
            </Strip>
          </div>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};

export default Home