import React, { Component,useRef, useState } from 'react';
import ReactPlayer from "react-player";

import audio1 from "../../../../../assets/Media/mediaaudio/DR_HITESH_KUMAR_1_11_22.mp3";

function MediaAudio() {
  return (
    <div className='container mt-5 pd-3'>
      <h3> युववाणी, आकाशवाणी, रायपुर  </h3>
      <h4>छत्तीसगढ़ी भाषा को डिजिटलाईज एवं परंपरा छत्तीसगढ़ के गढ़ हमर छत्तीसगढ़ </h4>
      <ReactPlayer   
        url={audio1}
        width="500px"
        height="50px"
        playing={false}
        controls={true}
        type = 'audio/mp3'
      />
    </div>
  );
}
export default MediaAudio;

  