import React from 'react';
import Helmet from 'react-helmet';
import SEO from 'react-seo-component';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';


const SEOComponent = (props) => {
    const { title, description, siteUrl, keywords } = props;
    return (
        <div>
            <Helmet>
                <meta name="keywords" content={keywords} />
            </Helmet>
            <SEO
                title={title}
                titleTemplate={'Speech recognition in agriculture and finance for the poor'}
                titleSeparator={`-`}
                description={description || ''}
                // image={image}
                pathname={`${ApiConfig.baseUrl}${siteUrl}`}
            // siteLanguage={siteLanguage}
            // siteLocale={siteLocale}
            // twitterUsername={twitterUsername}
            />
        </div>
    )
}

export default SEOComponent;