
const MegaMenuData = [
    {
        id: 0,
        title: 'about',
        isLink: true,
        link: '/about',
        name: 'about',
        menu: [
            {
                id: 'menu-1',
                name: 'RESPIN',
                selected: false,
                link: '/about',
            },
            {
                id: 'menu-2',
                name: 'partner',
                selected: false,
                link: '/partner',
            },
            {
                id: 'menu-3',
                name: 'funding',
                selected: false,
                link: '/funding',
            },

        ]
    },    
    {
        id: 1,
        title: 'research',
        isLink: true,
        link: '/publications',   /* ================ /areas   ===================== */
        name: 'research',
        menu: [
            {
                id: 'menu-4',
                name: 'areas',
                selected: false,
                link: '#', /* ======================================================================'/areas', ====================================================================== */
            },
            {
                id: 'menu-5',
                name: 'publications',
                selected: false,
                link: '/publications', 
            },
        ]
    }, 
    {
        id: 2,
        title: 'resources',
        isLink: true,
        link: '/datasets',
        name: 'resources',
        menu: [
            {
                id: 'menu-6',
                name: 'datasets',
                selected: false,
                link: '/datasets',
            },
            {
                id: 'menu-7',
                name: 'documents',
                selected: false,
                link: '/documents',
            }, 
            {
                id: 'menu-8',
                name: 'tools',
                selected: false,
                link: '/tools', /* ======================================================================'#', ====================================================================== */
            },
            {
                id: 'menu-9',
                name: 'challenges & workshops',
                selected: false,
                link: '/challenges',
            },
        ]
    },
    {
        id: 3,
        title: 'people',
        isLink: true,
        link: '/team',
        name: 'people',
        menu: [
            {
                id: 'menu-10',
                name: 'advisors',
                selected: false,
                link: '/advisors',
            },
            {
                id: 'menu-11',
                name: 'Team Members',
                selected: false,
                link: '/teamMembers',
            },
            {
                id: 'menu-12',
                name: 'contributors',
                selected: false,
                link: '/contributor',
            },
            // {
            //     id: 'menu-12',
            //     name: 'postdoc research associates',
            //     selected: false,
            //     link: '/team',
            // },
            // {
            //     id: 'menu-13',
            //     name: 'research associates',
            //     selected: false,
            //     link: '/team',
            // },
        ]
    },
    {
        id: 3,
        title: 'More',
        isLink: true,
        name: 'More',
        selected: false,
        link: '/media',/* ======================================================================'/gallery', ====================================================================== */
        menu: [
            {
                id: 'menu-13',
                name: 'Gallery',
                selected: false,
                link:'/gallery',
                /*link: 'https://ee.iisc.ac.in/~prasantg/respinsyspin/resgal.php', ======================================================================'/gallery', ====================================================================== */
            },
            {
                id: 'menu-14',
                name: 'media',
                selected: false,
                link: '/media',
            },
            {
                id: 'menu-15',
                name: 'openings',
                selected: false,
                link: '/openings',
            },
            {
                id: 'menu-16',
                name: 'contact us',
                selected: false,
                link: '/contact-us',
            },
            
        ]
    },
    // {
    //     id: 4,
    //     title: 'openings',
    //     isLink: true,
    //     name: 'openings',
    //     selected: false,
    //     link: '/openings',
    //     menu: []
    // },
    // {
    //     id: 5,
    //     title: 'contact us',
    //     isLink: true,
    //     name: 'contact us',
    //     selected: false,
    //     link: '/contact-us',
    //     menu: []
    // },
]

export default MegaMenuData;
