import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input, Table } from 'reactstrap';
import ChallengersService from '../../../../api/services/ChallengersService';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponent/Loader/Loader';
import Strip from '../../../customComponent/Strip/Strip';
import Axios from "axios";
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import GalleryImage from './GalleryImage'
import PhotoFileUploader from '../../../customComponent/PhotoFileUploader/PhotoFileUploader';
import ReseachAreaService from '../../../../api/services/ReseachAreaService ';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileUploader from '../../../customComponent/FileUploader/FileUploader';

const AddResearchArea = (props) => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [reseachTitle, setReseachTitle] = useState('');
    const [description, setDescription] = useState('');
    const [pointersDescription, setPointersDescription] = useState('');
    const [pointersPoints, setPointersPoints] = useState('');
    const [publications, setPublications] = useState('');
    const [downloadLinks, setDownloadLinks] = useState('');
    const [galleryList, setGalleryList] = useState([]);    
    const [imageTitle, setImageTitle] = useState('');
    const [imgFile, setImgFile] = useState('');
    const [imgFileName, setImgFileName] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [imagesTitle, setImagesTitle] = useState('');
    const [imgList, setImgList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setDescription('');
            setTitle('');
            setId('');
            setReseachTitle('')
            setPointersDescription('');
            setPointersPoints('')
            setPublications('');
            setDownloadLinks('');
            setGalleryList([]);
            setImageTitle('')
        } else {
            setDescription(props.item.description);
            setTitle(props.item.title);
            setId(props.item.id);
            setReseachTitle(props.item.reseachTitle)
            setPointersDescription(props.item.pointersDescription);
            setPointersPoints(props.item.pointersPoints)
            setPublications(props.item.publications);
            setDownloadLinks(props.item.downloadLinks);
            setId(props.item.id);
            setImageTitle(props.item.imageTitle)
            setGalleryList(props.item.galleryList ? JSON.parse(props.item.galleryList) : [])
        }
    }, [isAdd, props]);

    const handleDescription = (e) => {
        setDescription(e.target.value)
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleReseachTitle = (e) => {
        setReseachTitle(e.target.value)
    }

    const handlePointersDescription = (e) => {
        setPointersDescription(e.target.value)
    }

    const handlePointersPoints = (e) => {
        setPointersPoints(e.target.value)
    }

    const handlePublications = (e) => {
        setPublications(e.target.value)
    }

    const handleDownloadLinks = (e) => {
        setDownloadLinks(e.target.value)
    }
    const handleImageTitle = (e) => {
        setImagesTitle(e.target.value)
    }
    // const getThumbnail = async (event) => {
    //     let fileList = [...event.target.files];
    //     let item = [];
    //     let len = fileList.length;
    //     fileList.map(file => {
    //         const reader = new FileReader();
    //         reader.onabort = () => console.log("file reading was aborted");
    //         reader.onerror = () => console.log("file reading has failed");
    //         reader.onloadend = async () => {
    //             const binaryStr = reader.result;
    //             item.push({ base64: binaryStr, file: file });
    //             if (len === item.length) {
    //                 setImagesUrl([...imagesUrl, ...item])
    //                 console.log("imagesUrl")
    //                 console.log(imagesUrl)
    //             }

    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }


    const getThumbnail = async (item) => {
        await setImgFile(item[0].file);
        await setImgFileName(item[0].file.name);
       // setImagesUrl(...imagesUrl,...item[0].file)
        setIsFileChanged(true);
    }


    const handleAddImg = () => {
        let item = [];
        let obj = {
            imgFileName: imgFileName,
            imgPath : '/area/'+imgFileName,
            file: imgFile,
            imgTitle: imagesTitle
        }
        item.push(obj);
        setImgList([...imgList, ...item]);
        let imageItem = []
        let fileList = [];
        imgList.map(val => {
              fileList.push(val.file);
        })
        console.log("fileList");
        console.log(fileList)
        let len = fileList.length;
        fileList.map(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = async () => {
                const binaryStr = reader.result;
                imageItem.push({ base64: binaryStr, file: imgFile });
                if (len === imageItem.length) {
                    setGalleryList([...galleryList, ...imageItem])
                    console.log("galleryList")
                    console.log(galleryList)
                }
            };
            reader.readAsDataURL(file);
        });
        handleImgCancel();
        console.log("imglist",imgList)
    }

    const handleImgCancel = () => {
        setImagesTitle('');
        setImgFile('');
        setImgFileName('')
    }
    const handleImageDelete = index => {
        imgList.splice(index, 1);
        setGalleryList([...imgList]);
     };

     const handleLocationEdit = async (index) => {
        const item = galleryList[index];
        setImagesTitle(item.imgTitle);
        setImgFileName(item.imgFileName);
        console.log("india",item)
        // await setImgGallery(item.imgGallery && item.imgGallery.length > 0 ? item.imgGallery : []);
    };

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let finalGalleryFile = [];
        let finalGalleryArrayList = [];
        let finalGalleryListObj = {};
        if (imgList.length > 0) {
            imgList.map((it) => {
                if (it.file) {
                    finalGalleryFile.push(it.file);
                    console.log("image file check",it.file)
                }
                finalGalleryListObj = {
                    imgPath: it.file ? `/area/${it.file.name}` : it.imgPath,
                }
                finalGalleryArrayList.push(finalGalleryListObj);
            })
        }

        let data = {
            userId: userId,
            id: id,
            reseachTitle: reseachTitle,
            title: title,
            description: description,
            pointersDescription: pointersDescription,
            pointersPoints: pointersPoints,
            publications: publications,
            downloadLinks: downloadLinks,
            // galleryList: imgList.length > 0 ? JSON.stringify(imgList) : '[]'
            galleryList: imgList.length > 0 ? JSON.stringify(imgList) : JSON.stringify(galleryList),

        }
        setIsLoading(true);
        if (finalGalleryFile.length > 0) {
            UploadGallery(finalGalleryFile, data, true, "");
        } else {
            insertOrUpdate(data);
        }
        // insertOrUpdate(data);
        console.log("data", data)
        setIsLoading(false);
    }

    const UploadGallery = async (finalGalleryFile, insertOrUpdateData, isInsert) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/area/");
        console.log('*',finalGalleryFile)
        await finalGalleryFile.map((item) => {
            data.append("image", item);
            console.log("item", item)
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };

        if (data) {
            console.log("item 2", data)
            Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
                .then(async res => {
                    if (res.status === 200) {
                        try {
                            if (res.data.code === 0) {
                                if (isInsert) {
                                    await insertOrUpdate(insertOrUpdateData);
                                }
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${res.data.message}`);
                            }
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch(err => {
                    console.log("We will be back soon.");
                    return err;
                });
        }
    }

    const insertOrUpdate = async (data) => {
        await ReseachAreaService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);
                                props.handleRefresh();
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-dark-pink">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                    <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Research Area Title*:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Research Area Title"
                                                                        id="Research area title"
                                                                        name="Research area title"
                                                                        className="form-control common-form-control"
                                                                        value={reseachTitle}
                                                                        onChange={(event) => handleReseachTitle(event)}
                                                                    //rows={10}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                       Detail Title*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="title"
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control common-form-control"
                                                                        value={title}
                                                                        onChange={(event) => handleTitle(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Description*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="description"
                                                                        id="description"
                                                                        name="description"
                                                                        className="form-control common-form-control"
                                                                        value={description}
                                                                        onChange={(event) => handleDescription(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Pointers Description*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="text"
                                                                        id="pointersDescription"
                                                                        name="pointersDescription"
                                                                        className="form-control common-form-control"
                                                                        value={pointersDescription}
                                                                        onChange={(event) => handlePointersDescription(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Pointer Points*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="Points"
                                                                        id="pointerPoints"
                                                                        name="pointerPoints"
                                                                        className="form-control common-form-control"
                                                                        value={pointersPoints}
                                                                        onChange={(event) => handlePointersPoints(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Publications*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="text"
                                                                        id="publications"
                                                                        name="publications"
                                                                        className="form-control common-form-control"
                                                                        value={publications}
                                                                        onChange={(event) => handlePublications(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                       Download Links*:
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        placeholder="Links points"
                                                                        id="downloadLinks"
                                                                        name="downloadLinks"
                                                                        className="form-control common-form-control"
                                                                        value={downloadLinks}
                                                                        onChange={(event) => handleDownloadLinks(event)}
                                                                        rows={10}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                            
                                                        {/* <GalleryImage getThumbnail={(item) => getThumbnail(item)} imagesUrl={imagesUrl} imagetitle={imageTitle} handleImageTitle={(index) => handleImageTitle(index)} handleGalleryImageDelete={(index) => handleGalleryImageDelete(index)} /> */}

                                                    </Form>
                                                </Col>
                                            </Row>
                                            <FormGroup row className="mx-0 align-items-start">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormGroup row className="align-items-start">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                            <Label className="common-label text-left">
                                                               Image Title*:
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Image title"
                                                                id="imgTitle"
                                                                name="imgTitle"
                                                                className="form-control common-form-control"
                                                                value={imagesTitle}
                                                                onChange={(event) => handleImageTitle(event)}

                                                            />
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                            <Label className="common-label text-left">
                                                                Upload Image :
                                                            </Label>
                                                            <div className="file-form-container">
                                                           
                                                            <FileUploader
                                                                isImg={true}
                                                                isForm={true}
                                                                getThumbnail={(item) =>
                                                                    getThumbnail(item)
                                                                }
                                                                value={imgFileName}
                                                                isEditable={false}
                                                                labelName="Upload Image"
                                                            />
                                                            </div>
                                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <FormGroup row className="align-items-start">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="ml-auto">
                                                            <div className="d-flex align-items-end justify-content-end">
                                                                <div
                                                                    onClick={() =>
                                                                        handleImgCancel()
                                                                    }
                                                                >
                                                                    <Button className="btn custom-danger-btn custom-btn--small br-4px fs-10px">
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    className="ml-3"
                                                                    onClick={() =>
                                                                        handleAddImg()
                                                                    }
                                                                >
                                                                    <Button className="btn custom-theme-btn custom-btn--small br-4px fs-10px">
                                                                        Save
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Table bordered responsive>
                                                        <thead className="custom-table-thead">
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>image path</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="custom-table-tbody">
                                                            {galleryList.length > 0 && galleryList.map((it, index) => (
                                                                <tr key={index}>
                                                                    <td>{it.imgTitle}</td>
                                                                    <td>{it.imgFileName}</td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                            <div
                                                                            onClick={() =>
                                                                                handleImageDelete(index)
                                                                            }
                                                                            >
                                                                                <Button className="btn custom-danger-btn custom-btn--small br-4px fs-10px">
                                                                                    Delete
                                                                                </Button>
                                                                            </div>
                                                                            <div
                                                                                className="ml-2"
                                                                            onClick={() =>
                                                                                handleLocationEdit(index)
                                                                            }
                                                                            >
                                                                                <Button className="btn custom-theme-btn custom-btn--small br-4px fs-10px">
                                                                                    Edit
                                                                                </Button>
                                                                            </div>
                                                                            <div
                                                                                className="ml-2"
                                                                            // onClick={() =>
                                                                            //     handleLocationPosition(index)
                                                                            // }
                                                                            >
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </FormGroup>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 3, size: 6 }}
                                                    lg={{ offset: 3, size: 6 }}
                                                    xl={{ offset: 3, size: 6 }}
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                            // disabled={
                                                            //     !description &&
                                                            //     !photo
                                                            // }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}

                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}

export default AddResearchArea;