
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Slider from "react-slick";
import { useWindowSize } from '@react-hook/window-size';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import ContributorService from "../../../api/services/ContributorService";
import debugLogger from '../../Utils/debugLogger';
import { Button, Modal } from 'react-bootstrap';


const Individual = (props) => {
const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imageUrl, setImgurl] = useState([]);
    const [name, setName] = useState([]);
    const [bio, setBio] = useState([]);
    const [url, setUrl] = useState([]);

    const [discription, setDescription] = useState([]);
const [designation, setDesignation] = useState([]);

    const handleNavigationPopup=() => {
      setShow(true);
     setName(props.item.name)
      setImgurl(props.item.imageUrl)
      setBio(props.item.bio)
      setDesignation(props.item.designation)
      setDescription(props.item.discription)
      setUrl(props.item.url)

      console.log("item.firstClassName",props.item.firstClassName)
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position ">
        <Row className="mx-0">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-2"
              >
               <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-3"
              >
                <div className="container " onClick={() => handleNavigationPopup()}>   
                  <div variant="primary" >
                      <div className="team-img-container ">
                        <div className="team-img-container "> 
                          <img src={`${ApiConfig.url}${props.item.imageUrl}`} alt="" />
                         <div className="third-circle-container" style={{border: `15px solid`,}}>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 ">
                        <div>
                          <h6 className="h6-small section-title nunitoSans-Regular align-center pt-3">{props.item.name}</h6>
                        </div>
                      </div>
                  </div>
                </div>
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Body>
                    <div className="container" >   
                  <div variant="primary" >
                      <div className="popup-team-img-container pt-4">
                        <div className="academics-img-container"> 
                          <img src={`${ApiConfig.url}${imageUrl}`} alt="" />
                          <div className="third-circle-container" style={{border: `15px solid`,}}>
                          </div>
                        </div>
                     </div>
                      <h6 className="h6-medium mb-0 section-title align-center pt-4">{name}</h6>
                      <p className="mb-0 section-title align-center">{designation}</p>
                      <p className="mb-0 section-title align-center">{discription}</p>
                      {url ?
                      <a href={`${url}`} target="_blank" rel="noreferrer" className="team-img-container academics-img-container align-center custom-reference-links">View Profile</a>
                     
                        :null }
                    </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal> 
              </Col>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
 };

 export default Individual;
