import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Maithili/male/Maithili_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Maithili/male/Maithili_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Maithili/female/Maithili_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Maithili/female/Maithili_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Maithili/male/Maithili_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Maithili/male/Maithili_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Maithili/female/Maithili_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Maithili/female/Maithili_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Maithili/male/Maithili_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Maithili/male/Maithili_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Maithili/female/Maithili_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Maithili/female/Maithili_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Maithili/male/Maithili_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Maithili/male/Maithili_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Maithili/female/Maithili_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Maithili/female/Maithili_df_2.mp3"; // Female audio


function Maithili(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Bajjika */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Bajjika </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    लिक्विडिटी रिस्क मे मार्केट लिक्विडिटी आ फंडिंग लिक्विडिटी के चर्चा कएल जाइ छै
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Bajjika</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">रबर सँ बहुत तरहक समान बनाओल जाइ छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Bajjika </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">समतोला मे बिटामिन ए , बी , सी आ फास्फोरस होइ छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Bajjika</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">पुंजीक निवेश लेल सामान्य शेयर नीक विकल्प छै</p></td>
            </tr>
            {/* Table Dialect 2 == Eastern Maithili/Thethi */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Eastern Maithili/Thethi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">इंटरनेट बैंकिंग के माध्यम सं सेहो क्रेडिट कार्डक बैलेंस पता कैल जा सकैए</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Eastern Maithili/Thethi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">एकर चटनी , मुरब्बा आदि बनै छै , जे बड़ चहटगर होइ छै</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Eastern Maithili/Thethi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">एकरा अमेरिकी प्रतिभूति आ विनिमय आयोग सं मान्यता भेटल छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Eastern Maithili/Thethi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">भारतीय कृषि बाजार मे मध्यस्थ या बिचौलिया के संख्या घटेबाक चाही</p></td>
            </tr>
            {/* Table Dialect 3 == Southern Maithili */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Maithili </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">संस्थानक शेयर के हिस्सेदारी के न्यायसम्य कहल जाइत अछि</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Maithili</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">डेबिट कार्डक उपयोग सॅ इंटरनेट के माध्यम सॅ भुगतान कयल जा सकै छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Maithili </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">रबड़क उपयोग यातायातक साधन मे सेहो कयल जाइत अछि</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Maithili</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">सामाजिक स्थिति के हिसाब सॅ नागरिक कर जमा करैत अछि</p></td>
            </tr>
                {/* Table Dialect 4 == Angika */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Angika </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">कश्मीर आरु भूटान म पीला रंग केरो जंगली गुलाब खूब मिलै छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Angika</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">कृषि सहकारिता से उद्योग धंधा भी प्रभावित होलो छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Angika </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">शालिनी कहलकै कि व्यक्ति आरु परिवारो के देलो कर्जा मे बंधक ऋण आरु आयकर शामिल छै</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Angika</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">भारत मे पशुधन गणना घर घर जाय करि के आधार से जोरी रहलो छै</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleMaithili = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleMaithili"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleMaithili"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleMaithili"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleMaithili" />
                    <Strip
                        id="tst-strip-SampleMaithili"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Maithili Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Maithili />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleMaithili;