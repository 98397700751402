import NavanaImg from "../assets/Navana.png";
import facebookImg from "../assets/facebook.png";
import twitterImg from "../assets/twitter.png";
import footerImg from "../assets/footer.png";
import pathImg from "../assets/Path1.png";
import menuImg from "../assets/menu.png";
import bannerImg from '../assets/bannerImg.png';
import closeImg from '../assets/close.png';
import bmgfImg from "../assets/bmgf.png";
import rightImg from '../assets/right.png';
import spirelabImg from '../assets/spirelab.png';
import IISCImg from '../assets/IISC.png';
import emailImg from '../assets/email.png';
import locationImg from "../assets/location.png";
import logoImg from '../assets/logo.png';
import spireLab from '../assets/spire-lab.png';
import whiteLogoImg from '../assets/white-logo.png';
import timesImg from '../assets/times.png';
import img2 from '../assets/about/img2.png'
import img3 from '../assets/about/img3.png'
import RightArrow from '../assets/right-arrow.png';
import IMG1 from '../assets/IMG_1.png';
import right2 from '../assets/right2.png';
import PlayIcon from '../assets/play_icon.png';
import galleryImg1 from '../assets/Gallery/gallery1.jpg'
import galleryImg2 from '../assets/Gallery/gallery2.jpg'
import galleryImg3 from '../assets/Gallery/gallery3.jpg'
import galleryImg4 from '../assets/Gallery/gallery4.jpg'
import galleryImg5 from '../assets/Gallery/gallery5.jpg'
import galleryImg6 from '../assets/Gallery/gallery6.jpg'
import galleryImg7 from '../assets/Gallery/gallery7.jpg'
import galleryImg8 from '../assets/Gallery/gallery8.jpg'
import galleryImg9 from '../assets/Gallery/gallery9.jpg'
// import galleryImg10 from '../assets/Gallery/gallery10.jpg'
// import galleryImg11 from '../assets/Gallery/gallery11.jpg'
// import galleryImg12 from '../assets/Gallery/gallery12.jpg'
// import galleryImg13 from '../assets/Gallery/gallery13.jpg'
// import galleryImg14 from '../assets/Gallery/gallery14.jpg'
// import galleryImg15 from '../assets/Gallery/gallery15.jpg'
// import galleryImg16 from '../assets/Gallery/gallery16.jpg'
// import galleryImg17 from '../assets/Gallery/f2.jpg'
// import galleryImg18 from '../assets/Gallery/f3.jpg'
// import galleryImg19 from '../assets/Gallery/f4.jpg'
// import galleryImg20 from '../assets/Gallery/gallery20.jpg'
// import galleryImg21 from '../assets/Gallery/gallery21.jpg'
// import galleryImg22 from '../assets/Gallery/gallery22.jpg'
// import galleryImg23 from '../assets/Gallery/gallery23.jpg'
// import galleryImg24 from '../assets/Gallery/gallery24.jpg'
// import galleryImg25 from '../assets/Gallery/gallery25.jpg'
// import galleryImg26 from '../assets/Gallery/gallery26.jpg'
// import galleryImg27 from '../assets/Gallery/gallery27.jpg'
// import galleryImg28 from '../assets/Gallery/gallery28.jpg'
// import galleryImg29 from '../assets/Gallery/gallery29.jpg'
// import galleryImg30 from '../assets/Gallery/gallery30.jpg'
// import galleryImg31 from '../assets/Gallery/gallery31.jpg'
// import galleryImg32 from '../assets/Gallery/gallery32.jpg'
// import galleryImg33 from '../assets/Gallery/gallery33.jpg'
// import galleryImg34 from '../assets/Gallery/gallery34.jpg'
// import galleryImg35 from '../assets/Gallery/gallery35.jpg'
// import galleryImg36 from '../assets/Gallery/gallery36.jpg'
//             //import video from '../assets/Video/Respin_Video.mp4'
// import bmgfimg1 from '../assets/Gallery/BMGF_Visit_IISc/Img_wa0012_new.jpg'
// import bmgfimg2 from '../assets/Gallery/BMGF_Visit_IISc/Img_wa0022.jpg'
// import bmgfimg3 from '../assets/Gallery/BMGF_Visit_IISc/Img_wa0023.jpg'
// import bmgfimg4 from '../assets/Gallery/BMGF_Visit_IISc/Img_wa0024.jpg'
// import bmgfimg5 from '../assets/Gallery/BMGF_Visit_IISc/Img_wa0025.jpg'
// import cstsvisitImg1 from '../assets/Gallery/Csts_Prof_visit/Img_wa0041.jpg'
// import cstsvisitImg2 from '../assets/Gallery/Csts_Prof_visit/Img_wa0043.jpg'
// import cstsNgBglImg1 from '../assets/Gallery/CSTS_Nagour_Bhagalpur/Img_wa0003.jpg'
// import cstsNgBglImg2 from '../assets/Gallery/CSTS_Nagour_Bhagalpur/Img_wa0004.jpg'
// import cstsNgBglImg3 from '../assets/Gallery/CSTS_Nagour_Bhagalpur/Img_wa0005.jpg'
// import cstsNgBglImg4 from '../assets/Gallery/CSTS_Nagour_Bhagalpur/Img_wa0006.jpg'
// import sltHackImg3 from '../assets/Gallery/SLT_Hackathon/SLT_0001.jpg'
// import sltHackImg4 from '../assets/Gallery/SLT_Hackathon/SLT_0002.jpg'
// import sltHackImg16 from '../assets/Gallery/SLT_Hackathon/SLT_0003.jpg'
// import sltHackImg5 from '../assets/Gallery/SLT_Hackathon/SLT_0004.jpg'
// import sltHackImg6 from '../assets/Gallery/SLT_Hackathon/SLT_0005.jpg'
// import sltHackImg7 from '../assets/Gallery/SLT_Hackathon/SLT_0006.jpg'
// import sltHackImg15 from '../assets/Gallery/SLT_Hackathon/SLT_0007.jpg'
// import sltHackImg8 from '../assets/Gallery/SLT_Hackathon/SLT_0008.jpg'
// import sltHackImg9 from '../assets/Gallery/SLT_Hackathon/SLT_0009.jpg'
// import sltHackImg2 from '../assets/Gallery/SLT_Hackathon/SLT_0010.jpg'
// import sltHackImg1 from '../assets/Gallery/SLT_Hackathon/SLT_0011.jpg'
// import sltHackImg10 from '../assets/Gallery/SLT_Hackathon/SLT_0012.jpg'
// import sltHackImg11 from '../assets/Gallery/SLT_Hackathon/SLT_0013.jpg'
// import sltHackImg12 from '../assets/Gallery/SLT_Hackathon/SLT_0014.jpg'
// import sltHackImg13 from '../assets/Gallery/SLT_Hackathon/SLT_0015.jpg'
// import sltHackImg14 from '../assets/Gallery/SLT_Hackathon/SLT_0016.jpg'
// import bngFtImg1 from '../assets/Gallery/Bengali_Field_Trip/BFT_0001.jpg'
// import bngFtImg2 from '../assets/Gallery/Bengali_Field_Trip/BFT_0002.jpg'
// import bngFtImg3 from '../assets/Gallery/Bengali_Field_Trip/BFT_0003.jpg'
// import bngFtImg4 from '../assets/Gallery/Bengali_Field_Trip/BFT_0004.jpg'
// import bngFtImg5 from '../assets/Gallery/Bengali_Field_Trip/BFT_0005.jpg'
// import bngFtImg6 from '../assets/Gallery/Bengali_Field_Trip/BFT_0006.jpg'
// import bngFtImg7 from '../assets/Gallery/Bengali_Field_Trip/BFT_0007.jpg'
// import bngFtImg8 from '../assets/Gallery/Bengali_Field_Trip/BFT_0008.jpg'
// import bngFtImg9 from '../assets/Gallery/Bengali_Field_Trip/BFT_0009.jpg'
// import bngFtImg10 from '../assets/Gallery/Bengali_Field_Trip/BFT_0010.jpg'
// import bngFtImg11 from '../assets/Gallery/Bengali_Field_Trip/BFT_0011.jpg'
// import bngFtImg12 from '../assets/Gallery/Bengali_Field_Trip/BFT_0012.jpg'
// import bngFtImg13 from '../assets/Gallery/Bengali_Field_Trip/BFT_0013.jpg'
// import bngFtImg14 from '../assets/Gallery/Bengali_Field_Trip/BFT_0014.jpg'
// import restImg1 from '../assets/Gallery/Respin_team/Img_ca0003.jpg'
// import restImg2 from '../assets/Gallery/Respin_team/Img_ca0002.jpg'
// import restImg3 from '../assets/Gallery/Respin_team/Img_ca0004.jpg'
// import restImg4 from '../assets/Gallery/Respin_team/Img_ca0001.jpg'
// import restImg5 from '../assets/Gallery/Respin_team/Img_ca0005.jpg'
// import dectImg1 from '../assets/Gallery/Dec_outing_team/Img_dc0003.jpg'
// import dectImg2 from '../assets/Gallery/Dec_outing_team/Img_dc0005.jpg'
// import dectImg3 from '../assets/Gallery/Dec_outing_team/Img_dc0004.jpg'
// import dectImg4 from '../assets/Gallery/Dec_outing_team/Img_dc0001.jpg'
// import dectImg5 from '../assets/Gallery/Dec_outing_team/Img_dc0002.jpg'
// import makImg1 from '../assets/Gallery/Makaut/makaut_pic_1.jpg'
// import makImg2 from '../assets/Gallery/Makaut/makaut_pic_2.jpg'
// import makImg3 from '../assets/Gallery/Makaut/makaut_pic_3.jpg'
// import makImg4 from '../assets/Gallery/Makaut/makaut_pic_4.jpg'
// import makImg5 from '../assets/Gallery/Makaut/makaut_pic_5.jpg'
// import makImg6 from '../assets/Gallery/Makaut/makaut_pic_6.jpg'
// import makImg7 from '../assets/Gallery/Makaut/makaut_pic_7.jpg'
// import makImg8 from '../assets/Gallery/Makaut/makaut_pic_8.jpg'
// import makImg9 from '../assets/Gallery/Makaut/makaut_pic_9.jpg'
// import makImg10 from '../assets/Gallery/Makaut/makaut_pic_10.jpg'
// import makImg11 from '../assets/Gallery/Makaut/makaut_pic_11.jpg'
// import makImg12 from '../assets/Gallery/Makaut/makaut_pic_12.jpg'
// import makImg13 from '../assets/Gallery/Makaut/makaut_pic_13.jpg'
// import makImg14 from '../assets/Gallery/Makaut/makaut_pic_14.jpg'
// import makImg15 from '../assets/Gallery/Makaut/makaut_pic_15.jpg'

import spiremap from '../assets/staticmap.png'




const application_contants = {
  NavanaImg: NavanaImg,
  facebookImg: facebookImg,
  twitterImg: twitterImg,
  footerImg: footerImg,
  pathImg: pathImg,
  menuImg: menuImg,
  bannerImg: bannerImg,
  closeImg: closeImg,
  bmgfImg: bmgfImg,
  rightImg: rightImg,
  spirelabImg: spirelabImg,
  IISCImg: IISCImg,
  emailImg: emailImg,
  locationImg: locationImg,
  logoImg: logoImg,
  spireLab: spireLab,
  whiteLogoImg: whiteLogoImg,
  timesImg: timesImg,
  img1 : IMG1,
  img2 : img2,
  img3 : img3,
  img4 : IMG1,
  img5 : img3,
  RightArrow: RightArrow,
  right2: right2,
  GalleryImg1: IMG1,
  PlayIcon: PlayIcon,
  galleryImg1 : galleryImg1,
  galleryImg2 : galleryImg2,
  galleryImg3 : galleryImg3,
  galleryImg4 : galleryImg4,
  galleryImg5 : galleryImg5,
  galleryImg6 : galleryImg6,
  galleryImg7 : galleryImg7,
  galleryImg8 : galleryImg8,
  galleryImg9 : galleryImg9,
  // galleryImg10 : galleryImg10,
  // galleryImg11 : galleryImg11,
  // galleryImg12 : galleryImg12,
  // galleryImg13 : galleryImg13,
  // galleryImg14 : galleryImg14,
  // galleryImg15 : galleryImg15,
  // galleryImg16 : galleryImg16,
  // galleryImg17 : galleryImg17,
  // galleryImg18 : galleryImg18,
  // galleryImg19 : galleryImg19,
  // galleryImg20 : galleryImg20,
  // galleryImg21 : galleryImg21,
  // galleryImg22 : galleryImg22,
  // galleryImg23 : galleryImg23,
  // galleryImg24 : galleryImg24,
  // galleryImg25 : galleryImg25,
  // galleryImg26 : galleryImg26,
  // galleryImg27 : galleryImg27,
  // galleryImg28 : galleryImg28,
  // galleryImg29 : galleryImg29,
  // galleryImg30 : galleryImg30,
  // galleryImg31 : galleryImg31,
  // galleryImg32 : galleryImg32,
  // galleryImg33 : galleryImg33,
  // galleryImg34 : galleryImg34,
  // galleryImg35 : galleryImg35,
  // galleryImg36 : galleryImg36,
  //                         // video : video,
  // bmgfimg1 : bmgfimg1,
  // bmgfimg2 : bmgfimg2,
  // bmgfimg3 : bmgfimg3,
  // bmgfimg4 : bmgfimg4,
  // bmgfimg5 : bmgfimg5,
  // cstsvisitImg1 : cstsvisitImg1,
  // cstsvisitImg2 : cstsvisitImg2,
  // cstsNgBglImg1 : cstsNgBglImg1,
  // cstsNgBglImg2 : cstsNgBglImg2,
  // cstsNgBglImg3 : cstsNgBglImg3,
  // cstsNgBglImg4 : cstsNgBglImg4,
  // bngFtImg1 : bngFtImg1,
  // bngFtImg2 : bngFtImg2,
  // bngFtImg3 : bngFtImg3,
  // bngFtImg4 : bngFtImg4,
  // bngFtImg5 : bngFtImg5,
  // bngFtImg6 : bngFtImg6,
  // bngFtImg7 : bngFtImg7,
  // bngFtImg8 : bngFtImg8,
  // bngFtImg9 : bngFtImg9,
  // bngFtImg10 : bngFtImg10,
  // bngFtImg11 : bngFtImg11,
  // bngFtImg12 : bngFtImg12,
  // bngFtImg13 : bngFtImg13,
  // bngFtImg14 : bngFtImg14,
  // sltHackImg1 : sltHackImg1,
  // sltHackImg2 : sltHackImg2,
  // sltHackImg3 : sltHackImg3,
  // sltHackImg4 : sltHackImg4,
  // sltHackImg5 : sltHackImg5,
  // sltHackImg6 : sltHackImg6,
  // sltHackImg7 : sltHackImg7,
  // sltHackImg8 : sltHackImg8,
  // sltHackImg9 : sltHackImg9,
  // sltHackImg10 : sltHackImg10,
  // sltHackImg11 : sltHackImg11,
  // sltHackImg12 : sltHackImg12,
  // sltHackImg13 : sltHackImg13,
  // sltHackImg14 : sltHackImg14,
  // sltHackImg15 : sltHackImg15,
  // sltHackImg16 : sltHackImg16,
  // restImg1 :restImg1,
  // restImg2 : restImg2,
  // restImg3 : restImg3,
  // restImg4 : restImg4,
  // restImg5 : restImg5,
  // dectImg1 : dectImg1,
  // dectImg2 : dectImg2,
  // dectImg3 : dectImg3,
  // dectImg4 : dectImg4,
  // dectImg5 : dectImg5,
  // makImg1 : makImg1,
  // makImg2 : makImg2,
  // makImg3 : makImg3,
  // makImg4 : makImg4,
  // makImg5 : makImg5,
  // makImg6 : makImg6,
  // makImg7 : makImg7,
  // makImg8 : makImg8,
  // makImg9 : makImg9,
  // makImg10 : makImg10,
  // makImg11 : makImg11,
  // makImg12 : makImg12,
  // makImg13 : makImg13,
  // makImg14 : makImg14,
  // makImg15 : makImg15,
  spiremap : spiremap,




  LANDING: '/',
  LOGIN: '/login',
  ABOUTUS: '/aboutUs',
  PEOPLES: '/people-list',
  PARTNERLIST: '/partner-list',
  BANNERLIST: '/banner-list',
  OPENINGLIST: '/opening-list',
  CHALLENGESLIST: '/challenges-list',
  REASEARCHAREA : '/researcharea-list',
  DOCUMENTS : '/documents-list',
  MEDIA : '/Media-list',
  PUBLICATIONS : '/Publications-list',
  ACADEMICS : '/academics-list',
  INDUSTRY : '/industry-list',
  INDIVIDUAL : '/individual-list',
  DATASETS : '/datasets-list',
  TOOLS : '/tool-list',
  EXPERTS : '/expert-list',

  MOBILE_BREAKPOINT: 1023,
};


export default application_contants;
