import React from "react";
import { Col, Row } from "reactstrap";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
const PersonSlider = (props) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position ">
        <Row className="mx-0">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-4"
              >
                <div className="team-img-container">
                  {/* {props.item.link ? */}
                    <a href={`/team/${props.item.fullName.toLowerCase()}`} rel="noreferrer" className="team-img-container">
                      <img src={`${ApiConfig.url}${props.item.photoURL}`} alt="" />
                      <div
                        className="first-circle-container"
                        style={{ border: `15px solid ${props.item.firstClassName}` }}
                      ></div>
                      <div
                        className="second-circle-container"
                        style={{
                          border: `15px solid ${props.item.secondClassName}`,
                        }}
                      ></div>
                    </a>
                    {/* : <div className="team-img-container"> <img src={`${ApiConfig.url}${props.item.photoURL}`} alt="" />
                      <div
                        className="first-circle-container"
                        style={{ border: `15px solid ${props.item.firstClassName}` }}
                      ></div>
                      <div
                        className="second-circle-container"
                        style={{
                          border: `15px solid ${props.item.secondClassName}`,
                        }}
                      ></div></div>} */}
                </div>
                <div className="pt-5 team-img-container">
                <a href={`/team/${props.item.fullName.toLowerCase()}`} rel="noreferrer" className="team-img-container">

                  <div>
                    <h6 className="h6-medium section-title mb-0 line-height-20px font-size-20px">
                      {props.item.fullName}
                    </h6>
                    {props.isShow ?
                      <p className="section-title mb-0 line-height-20px pt-1">
                        {props.item.name}
                      </p> : null}   
                  </div>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default PersonSlider;