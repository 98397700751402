import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import OpeningCardData from "../../Utils/Json/OpeningCardData"
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SocialIcon from '../../customComponent/SocialIcon/SocialIcon';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import OpeningService from '../../../api/services/OpeningService';
import debugLogger from '../../Utils/debugLogger';

const OpeningDetails = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [openingTitle, setOpeningTitle] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();

    React.useEffect(() => {
        setData([]);
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList();
    }, []);
    const getList = async () => {
        let it = [];
        await OpeningService.getOpeningList()
            .then(async (res) => {
                console.log(res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.list.map((item) => {
                                        if (item.openingTitle.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                            it.push(item);
                                            setOpeningTitle(item.openingTitle)
                                            setTitle(item.title)
                                            setCode(item.code)
                                        }
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`/openings/${sectionTitle}`} />
                        <Row className="mx-0 pt-5">
                            <Col xs={12} ms={12} md={12} lg={11} xl={11} className="pt-3 pb-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/openings`} className="custom-link-item">{`More > Openings >`}</Link>
                                    <span className="fw-600 pl-2 ">{`${openingTitle}`} </span>
                                </p>
                                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
                                    {title ? title : ''}
                                </h5> :
                                    <h2 className="h2-small section-title align-left pb-2 line-height-48px">
                                        {title ? title : ''}
                                    </h2>
                                }
                                <h6 className="mb-0 section-title align-left nunitoSans-SemiBold fw-600 title-dark-light-grey"> {code ? `Position Code - ${code}` : ''}</h6>
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={8} xl={8}>
                                <Row>
                                    <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                        {data.length > 0 && data.map((item, index) => (
                                            <div key={index}>
                                                <Row className="pb-5">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">About the Project</h5></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="mb-0 section-title align-left">{item.description}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                        <RoleItem item={item} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                        <EssentialQualification item={item} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                        <PreferredQualification item={item} />
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                         <Duration item={item} /> 
                                                    </Col>
                                                </Row> */}
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={4} xl={4}>
                                {data.length > 0 && data.map((it, index) => (
                                    <Row className="opening-info-container mx-0" key={index}>
                                        {it.duration ?
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                            <p className="mb-0 section-title nunitoSans-SemiBold align-left fw-600">Duration</p>
                                            <p className="mb-0 section-title align-left">{it.duration ? it.duration : ''}</p>
                                        </Col>: null}
                                        {it.startDate ?
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                            <p className="mb-0 section-title nunitoSans-SemiBold align-left fw-600">Start Date</p>
                                            <p className="mb-0 section-title align-left">As early as possible({it.startDate ? it.startDate : ''})</p>
                                        </Col>: null}
                                        {it.netSalary ?
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                            <p className="mb-0 section-title nunitoSans-SemiBold align-left fw-600">Net Salary (per month)</p>
                                            <p className="mb-0 section-title align-left">{it.netSalary ? it.netSalary : ''}</p>
                                        </Col>: null}
                                        {it.applicationLink ?
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                            <p className="mb-0 section-title nunitoSans-SemiBold align-left fw-600">Application Procedure</p>
                                            <p className="mb-0 section-title align-left">Applications will be considered until the position is filled. <br /> Please Use the following link to apply</p>
                                            <a href={`${it.applicationLink}`} target="_blank" rel="noreferrer" className="custom-reference-links">{it.applicationLink}</a>
                                        </Col>: null}
                                        {it.contactName ? 
                                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                            <p className="mb-0 section-title nunitoSans-SemiBold align-left fw-600">Contact</p>
                                            <p className="mb-0 section-title align-left">If you have any questions, please contact :</p>
                                            <p className="mb-0 section-title  nunitoSans-SemiBold  align-left fw-600">{it.contactName ? it.contactName : ''}</p>
                                            <p className="mb-0 section-title align-left">{`(${it.contactEmail ? it.contactEmail : ''})`}</p>
                                        </Col>: null}
                                    </Row>
                                ))}
                            </Col>

                        </Row>
                    </Strip>
                    {/* <SocialIcon /> */}
                </div>
            </RenderPage>
        </ExternalLayout>
    )
}

export default OpeningDetails;


const RoleItem = (props) => {
    return (
        <div>
            {props.item && props.item.rolePoints ?
                <Row className="mx-0">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0"><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Role</h5></Col>
                    <Row>
                        <Col xs={12} ms={12} md={12} lg={12} xl={12}><p className="mb-0 section-title align-left pb-3">{props.item.roleDescription}{props.item.roleDescription ? ':' : ''}</p></Col>
                        {props.item.rolePoints.split(/[\n\r]/g).map((infoItem, index) => (
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                <div className="d-flex">
                                    <FiberManualRecord className="dot-icon" />
                                    <div>
                                        <p className="mb-0 section-title align-left">{infoItem}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row> : null}
        </div>

    )
}

const EssentialQualification = (props) => {
    return (
        <div>
            {props.item && props.item.essentialPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Essential Qualifications</h5></Col>
                    {props.item.essentialPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 section-title align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}

const PreferredQualification = (props) => {
    return (
        <div>
            {props.item && props.item.preferredPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Preferred Qualification</h5></Col>
                    {props.item.preferredPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 section-title align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                : null}
        </div>
    )
}

const Duration = (props) => {
    return (
        <div>
            {props.item.duration.length > 0 ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small  section-title nunitoSans-SemiBold fw-600 align-left pb-1">Duration</h5></Col>
                    {props.item.duration.map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                {/* <FiberManualRecord className="dot-icon" /> */}
                                <div>
                                    <p className="mb-0 section-title align-left">{it.param}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}