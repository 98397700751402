import application_contants from "../../constants/application_contant";

const Res_Data = [ 
    application_contants.restImg1,
    application_contants.restImg2,
    application_contants.restImg3,
    application_contants.restImg4,
    application_contants.restImg5,
];

export default Res_Data ; 