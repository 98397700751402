import React from 'react';
import { Media } from 'reactstrap';

import media_img from '../../../../../assets/Media/mediaimages/KawardhaBemetraDainikBhaskar.jpg'


console.log(media_img); 

function mediaimg() {
  // Import result is the URL of your image
  return <img src={media_img} className="mediaimg-card-br" />;
}
export default  mediaimg ;