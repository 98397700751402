import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import ContributorService  from "../../../api/services/ContributorService"
import debugLogger from '../../Utils/debugLogger';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import Academics from '../../Utils/Json/Academics';
import { desc } from 'react-dom-factories';
import Slider from "react-slick";
import AcademicsSlider from './AcademicsSlider/AcademicsSlider'
import StudentsSlider from './StudentsSlider/StudentsSlider'
import PropTypes from "prop-types";

const AcademicDetails = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [place,setPlace] = useState('');
    const [url,setUrl] = useState('');
    const [description,setDescription] = useState([]);
    const [professor,setProfessor] = useState([]);
    const [students,setStudents] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [academics,setAcademics] = useState([]);
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
   

    React.useEffect(() => {
        setData([]);
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[3]);
        getList();
    }, []);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow:
          width >= 1025
            ? 4 : width >= 1024 && width <= 1024 ? 3
              : width >= 768 && width <= 1023
                ? 2
                : 1,
        slidesToScroll: 1,
      };
    
    const getList = async () => {
        let it = [];
        await ContributorService.getContributorsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("result",result)
                            if (result.code === 0) {
                                if (result.data) {
                                    console.log("location",props.location.pathname)
                                    result.data.academicsList.list.map((item) => {
                                        if (item.instituteName.toLowerCase() === props.location.pathname.split('/')[3].toLowerCase()) {
                                            setTitle(item.instituteName)
                                            setPlace(item.place)
                                            setUrl(item.url)
                                            setImgUrl(item.imgUrl)
                                            console.log("length",imgUrl)
                                            setProfessor(item.professors ? JSON.parse(item.professors) : [])
                                            setStudents(item.students ? JSON.parse(item.students) : [])
                                            setDescription(item.description)
                                            console.log("academics professors",description)
                                        }                                       
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    console.log("data",data)
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }
    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        console.log("list",list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
   
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`//${sectionTitle}`} />
                        <Row className="mx-0 pt-5">
                            
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto">
                                {/* <Col className="pt-3"></Col> */}
                                <p className="mb-0 section-title align-left pt-5">
                                    <Link to={`/contributor`} className="custom-link-item">{` People > Contributors > Academics >`}</Link>
                                    <span className="fw-600 pl-2 text-capitalize">{`${title}`} </span>
                                </p>
                                {/* {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
                                    {title ? title : ''}
                                </h5> :
                                    <h2 className="h2-small section-title align-left pb-2 line-height-48px">
                                        {title ? title : ''}
                                    </h2>
                                } */}
                            </Col>
                                 <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 py-1 ">
                                    <Row className="mb-0 mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={11} className="pt-4">
                                            <div >
                                                <p className="p-large section-title nunitoSans-Bold align-left pt-1">{title}</p>
                                                <p className="p-small section-title nunitoSans-Regular align-left pt-1">{place}</p>
                                                {imgUrl !== '' && imgUrl !== null?
                                                <div className="pt-1 large-img-container align-left"> 
                                                <img className='large-img' src={`${ApiConfig.url}${imgUrl}`} alt="" />
                                                </div>
                                                 : null}

                                                 <p className="p-small section-title nunitoSans-Lignt align-left pt-2">{description}</p>
                                                {url ?
                                                <p className="mb-0 section-title align-left nunitoSans-Bold">For more details about the college, 
                                                    <a href={`${url}`} rel="noreferrer" target="_blank"  className="p-small section-title nunitoSans-Light custom-reference-links align-left">click here</a>
                                                    </p>
                                                : null }

                                            </div>                  
                                        </Col>
                                       
                                        <Col
                                            xs={12} sm={12} md={12} lg={12} xl={12}
                                            className="m-auto pt-4 pb-2"
                                            >
                                            <Row>
                                                {professor.length > 0 ?
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-5">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h6-medium align-left nunitoSans-Bold">Professors</h6> :
                                                    <h3 className="h3-small align-left nunitoSans-normal">Professors</h3>}
                                                </Col> : null}
                                            </Row>
                                            {width <= application_contants.MOBILE_BREAKPOINT ?
                                                <Slider {...settings}>
                                                 {professor.map((item, index) => (
                                                    <div xs={12} sm={12} md={12} lg={12} xl={12} className="academics-people">
                                                    <AcademicsSlider item={item}/>
                                                    </div>
                                                 ))} 
                                                </Slider> :
                                                <Row>
                                                {professor.map((item, index) => ( 
                                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className="academics-people">
                                                    <AcademicsSlider item={item} />
                                                    </Col>
                                                ))} 
                                                </Row>}
                                            </Col> 
                                        <Col
                                            xs={12} sm={12} md={12} lg={12} xl={12}
                                            className="m-auto pt-4 pb-2"
                                            >
                                            <Row>
                                                {students.length > 0 ?
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-3 pt-4">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h6-medium nunitoSans-norma align-left">Students</h6> :
                                                    <h3 className="h3-small nunitoSans-norma align-left">Students</h3>}
                                                </Col> : null}
                                            </Row>
                                            {width <= application_contants.MOBILE_BREAKPOINT ?
                                               <Slider {...settings}>
                                                {students.map((item, index) => (
                                                    <div xs={12} sm={12} md={12} lg={4} xl={4} className="academics-people">
                                                    <StudentsSlider item={item}/>
                                                    </div>
                                                ))}  
                                                 </Slider> 
                                                :
                                                <Row>
                                                {students.map((item, index) => (
                                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className="academics-people">
                                                    <StudentsSlider item={item} />
                                                    </Col>
                                                  ))} 
                                                </Row>}
                                            </Col>
                                            <Col className='pt-4'></Col>
                                    </Row>
                                </Col>
                        </Row>
                      </Strip>
                    </div>
                    {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
}
export default AcademicDetails;




