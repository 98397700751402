import React, { useState } from "react";
import { Col, Row } from 'reactstrap';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from '../../constants/application_contant';
import ChallengesData from '../../Utils/Json/ResourceChallenges';
import TitleComponent from '../TitleComponent/TitleComponent';
import moment from "moment";
import ChallengersService from "../../../api/services/ChallengersService";


const ChallengesComponent = (props) => {
    const [width] = useWindowSize();
    const [data, setData] = useState([]);
    const [preData, setPreData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const date = moment().format('YYYY-MM-DD');


    React.useEffect(() => {
        window.scrollTo(0, 0);
        getChallengersList();
    }, [])
    const getChallengersList = async () => {
        setIsLoading(true);
        await ChallengersService.getChallengersList()
          .then(async (res) => {
            if (res.status === 200) {
              try {
                res.text().then(async (res) => {
                  let result = JSON.parse(res);
                  if (result.code === 0) {
                    if (result.data) { 
                      const upCominglists = result.data.list.filter((it) => it.status === "1" && date < it.date);
                      const previouslists = result.data.list.filter((it) => it.status === "1" && date >=  it.date);
                      setData([...upCominglists])
                      setPreData([...previouslists])
                      setIsLoading(false);
                    } else {
                      setData([]);
                      setIsLoading(false);
                    }
                  } else {
                    setData([]);
                    setIsLoading(false);
                  }
                });
              } catch (err) {
                console.log(err);
                setIsLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      };
    const handleNavigationChallenges = async (challengesDetail) => {
        console.log('clicked')
        props.history.push('/ChallengesDetails',{challengesDetail});
    }
    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2 px-0">
                {data === null ?
                    <div className="pb-4">
                        <TitleComponent title={props.firstSectionTitle} pathName={'challenges'} isViewAll={true} />
                    </div>
                    : <h4 className="h4-small section-title align-left pb-3 pt-2">{props.firstSectionTitle}</h4>
                }
                {data === null ?
                <Row>
                    {data.map((challengesDetail, index) => {
                        return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} className="challenges-card-br" key={index}>
                            <Row className="mb-3 mx-0">
                                <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card challenges-card-br-position-top'>
                                    <div>
                                        <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">{challengesDetail.title}</p>
                                        <p className="p-small text-secondary section-title nunitoSans-Regular align-left opening-card-position-date">{challengesDetail.date}</p>
                                    </div>
                                    {/* <div className="align-right">
                                        <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                    </div> */}
                                </Col>
                            </Row>
                        </Col>
                    })}
                </Row>
                : 
                <p className="foo p-large section-title nunitoSans-Light align-left">Coming soon...</p>             
            }
            </Col>
            {props.isSecond ?
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-0 px-0">
                    <h4 className="h4-small section-title align-left pb-3 pt-2">{props.secondSectionTitle}</h4>
                    <Row>
                        {preData.map((challengesDetail, index) => {
                            return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4}  className="previous-challenges-card-br" key={index}>
                                <Row className="mb-3 mx-0">
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card previous-challenges-card-br-position-top'>
                                        <div>
                                            <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">{challengesDetail.name}</p>
                                            <p className="p-small text-secondary section-title nunitoSans-Regular align-left opening-card-position-date">{challengesDetail.date}</p>
                                        </div>
                                        {/* <div className="align-right">
                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                        </div> */}
                                    </Col>
                                </Row>
                            </Col>
                        })}
                    </Row>
                </Col> : null}
        </Row>
    )
};


export default ChallengesComponent;
