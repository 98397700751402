import React, {useState, useEffect} from 'react';

import ExternalLayout from '../../../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../../../customComponent/RenderPage/RenderPage';
import Strip from '../../../../customComponent/Strip/Strip';
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from '../../../../customComponent/SEO/SEOComponent';
import PropTypes from "prop-types";
import PdfReader from './pdfreader';

const PdfReaderCustomize = (props) => {
    const [windowSize] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleDatasets"
                    className="strip strip-no-padding px-20"
                    containerType="container"
                >
                    <SEOComponent
                        title="Speech Recognition, respin, SPIRE LAB, Navana"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/MediaPdf" />

                        <div>
                            <PdfReader />
                        </div>

</Strip>
</RenderPage>
</ExternalLayout>
);
};

export default PdfReaderCustomize ;
