import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../../../constants/application_contant";
import Strip from "../../../../../customComponent/Strip/Strip";
import RenderPage from "../../../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../../../customComponent/ExternalLayout/ExternalLayout";
import { Link } from 'react-router-dom';

import story_img1 from '../../../../../assets/Stories/Adjourning_working_Structure.jpeg'

const Storytitle2 = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Storytitle2"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Storytitle2"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Story"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/document/stories/Storytitle2" />
                    <Strip
                        id="tst-strip-story"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/Documents/Stories/Storytype1`} className="custom-link-item">{` Resources > Documents > Stories > Story Language >`}</Link>
                                </p>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h1 className="h1-small section-title align-left pb-2 line-height-48px">Adjourning Working Structure</h1> :
                                            <h2 className="h2-small section-title align-left pb-2 line-height-48px">Adjourning Working Structure</h2>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                            
                                            <p className="mb-0 section-title align-left">As the RESPIN and SYSPIN projects provide countless chances to individuals all around India. 
                                            Such as in the chore area of Sentence composition, Sentence Translation , Recording, 
                                            Transcription and so forth. Many individuals are actively participating in this. Our team shares 
                                            work sheets with on boarded individuals ; once the works which we assigned to participants gets 
                                            completed, then validation process starts and then after payment procedures begin. It's a 
                                            systematic process that clearly takes time. We have already disclosed all information in JD 
                                            letters and in communication with participants but still sometime individuals are often unable to 
                                            understand these things further which creates hiccup in subsequent activities. If the HR team 
                                            delays payments for whatever reason, some participants refuses to undertake more work and 
                                            even refuse to share the work previously given to them for the validation process until they 
                                            receive payment. These kinds of scenarios cause confusion for us as well. One example of this is 
                                            seen below; this participant refuses to share the worksheet with us until the payment procedure is 
                                            completed. We tried all we could to help them grasp the situation, but we didn't get far.
                                            </p>

                                                
                                            <img src={story_img1} alt="screenshot of chat" className="mediaimg-card-br"/> 
                                                {/*
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <p className="p-very-small section-title align-right">Hello World!</p> :
                                                    <p className="p-small section-title align-right">Hello world!</p>}
                                                </Col>
                                                */}
                                                

                                               </Col>

                                        {/*   */}                                               
                                        </Row>
                                           
                                            
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Storytitle2.defaultProps = {
    isClassName: true,
};

Storytitle2.propTypes = {
    isClassName: PropTypes.bool,
};

export default Storytitle2;
