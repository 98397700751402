import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/bhojpuri/male/bhojpuri_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/bhojpuri/female/bhojpuri_cf_2.mp3"; // Female audio


function Bhojpuri(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Northern Bhojpuri */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Northern Bhojpuri </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    देस के आर्थिक व्यवस्था के ठीक राखे खातिर आर्थिक बाजार के मजबूत बनल रहल बहुते जरुरी बाटे
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Northern Bhojpuri</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">लोन आवेदन में लोन से जुड़ल सब जानकरी के देहल गईल रहत हवे जेके तू कबो आराम से पढ़ सकेला</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Northern Bhojpuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">मोदी जी अपनी सरकार में डिजिटल इंडिया के शुरुआत कईने जेसे देस के तकनीकी विकास कईल जा सके</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Northern Bhojpuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">संपूर्ण ग्रामीण रोजगार योजना में सब गांव के लोगन के रोजगार देहल जात हवे</p></td>
            </tr>
            {/* Table Dialect 2 == Western Bhojpuri */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Western Bhojpuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">डेबिट कार्ड खो गइले क स्थिति में या खराब हो गइले पर एके निष्क्रिय करल जा सकल जाला</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Western Bhojpuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">इ सब सिंचाई के विधि अपनवला से पानी के परेशानी के कम कईल जा सकत बाटे</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Western Bhojpuri </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">प्रधान मंत्री जन धन योजना भारत सरकार द्वारा अठाईस अगस्त दुई हजार चौदह के लागू किहल गयल हौ</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Western Bhojpuri</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">आज कल एके एक ठे जहाज के जइसनो जानल जाला</p></td>
            </tr>
            {/* Table Dialect 3 == Southern Bhojpuri */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Bhojpuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">एकरा तहत कर्मचारी के मुअला के बाद ओकर मेहरारू के कुछ तय पइसा दीहल जाला</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Bhojpuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">तू आपन बच्चन के नाम चिट्ठी मे लिख के बैंक के बाता सकेलू कि एकरा के पइसा दे दिहल जाव</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Bhojpuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">शोध काम खातिर अलग अलग संस्था से कईल गईल फंडिंग के रिसर्च फंडिंग कहल जाला</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Bhojpuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">चरखे से डोरा काटे के काम शुरू भईल आउर ऊहे चरखा स्वेदेशी आन्दोलन के बेर लोग के औजार बन गईल</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleBhojpuri = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleBhojpuri"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleBhojpuri"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleBhojpuri"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleBhojpuri" />
                    <Strip
                        id="tst-strip-SampleBhojpuri"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Bhojpuri Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Bhojpuri />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleBhojpuri;