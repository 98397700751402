import application_contants from "../../constants/application_contant";

const TeamOutDec_Data = [ 
//    application_contants.dectImg1,
//    application_contants.dectImg2,
    application_contants.dectImg3,
    application_contants.dectImg4,
    application_contants.dectImg5,
];

export default TeamOutDec_Data ; 