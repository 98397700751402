import React, {useState} from 'react';
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import ExternalLayout from '../../../customComponent/ExternalLayout/ExternalLayout';
import application_contants from '../../../constants/application_contant';
import Strip from '../../../customComponent/Strip/Strip';
import ToolsService from '../../../../api/services/ToolsService';
import RenderPage from '../../../customComponent/RenderPage/RenderPage';
import DatasetsService from '../../../../api/services/DatasetsService';
import PropTypes from "prop-types";
import SEOComponent from '../../../customComponent/SEO/SEOComponent';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Importing Maps
import westernbengali from "../../../assets/DatasetsMaps/BengaliMaps/westernbengali.png";
import northernbengali from "../../../assets/DatasetsMaps/BengaliMaps/vrendrinorthernbengali.png";
import Roughbengalmap from "../../../assets/DatasetsMaps/BengaliMaps/Roughbengal-lang.png";

const Bengali_Maps = {
        westernbengali : westernbengali ,
        northernbengali : northernbengali ,
}
const BengaliTextData = (props) => {
    const [width] = useWindowSize();
        
    React.useEffect(() => {
        window.scrollTo(0, 0);
       
    }, []);

    const [currentActiveTab, setCurrentActiveTab] = useState('1'); 
  
    // Toggle active state for Tab 
    const toggle = tab => { 
        if (currentActiveTab !== tab) setCurrentActiveTab(tab); 
    } 
  

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-datasets"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
             <SEOComponent
                title="datasets"
                keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                siteUrl="/datasets" />

                <Strip
                    id="tst-strip-datasets"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >


               
                    <Row className="mx-0 pt-5">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-5">
                        <p className="mb-0 section-title align-left">
                                <Link to={`/TextDatasets`} className="custom-link-item">{` Resources > Datasets > Text Datasets >`}</Link>
                                <span className="h6-large pl-2 fw-600">Bengali Text Datasets </span>
                            </p>
                            <Row>
                                {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-4">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title jostRegular title-navy-blue pb-4 align-left">Bengali Text Datasets</h5> :
                                    <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Bengali Text Datasets</h3>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                    <p>
                                        Hello World!
                                    </p>
                                                              
                                </Col> */}
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pt-4">
                            <Row className="mb-0 mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                                    <div >
                                        <p className="h6-medium section-title jostRegular title-black  align-left">Bengali Text Datasets</p>
                                        <p className="p-large section-title jostLight align-left title-black pt-2 pb-2">The Bushmen are the indigenous peoples of southern Africa. Largely hunter-gatherers, their territory spans several nations and they have called the region home for tens of thousands of years.The tribes are well-known for the profound connection they have with their land, for their intimate knowledge of the natural world, and the delicate balance they have maintained for millennia with the environment.</p>
                                </div>
                                </Col>
                                 <div className="pt-4"> 
                                    <Nav tabs> 
                                        <NavItem> 
                                            <NavLink 
                                                className={classnames({ 
                                                    active: 
                                                        currentActiveTab === '1'
                                                })} 
                                                onClick={() => { toggle('1'); }} 
                                            > 
                                                Dailect 1 
                                            </NavLink> 
                                        </NavItem> 
                                        <NavItem> 
                                            <NavLink 
                                                className={classnames({ 
                                                    active: 
                                                        currentActiveTab === '2'
                                                })} 
                                                onClick={() => { toggle('2'); }} 
                                            > 
                                                Dailect 2 
                                            </NavLink> 
                                        </NavItem> 
                                        <NavItem> 
                                            <NavLink 
                                                className={classnames({ 
                                                    active: 
                                                        currentActiveTab === '3'
                                                })} 
                                                onClick={() => { toggle('3'); }} 
                                            > 
                                                Dailect 3 
                                            </NavLink> 
                                        </NavItem> 
                                        <NavItem> 
                                            <NavLink 
                                                className={classnames({ 
                                                    active: 
                                                        currentActiveTab === '4'
                                                })} 
                                                onClick={() => { toggle('4'); }} 
                                            > 
                                                Dailect 4 
                                            </NavLink> 
                                        </NavItem> 
                                        <NavItem> 
                                            <NavLink 
                                                className={classnames({ 
                                                    active: 
                                                        currentActiveTab === '5'
                                                })} 
                                                onClick={() => { toggle('5'); }} 
                                            > 
                                                Dailect 5 
                                            </NavLink> 
                                        </NavItem> 
                                    </Nav> 
                                    <TabContent activeTab={currentActiveTab}> 
                                        <TabPane tabId="1"> 
                                            <Row> 
                                            <Col xs={8} ms={8} md={8} lg={8} xl={8} className="pt-4"> 
                                                    {/* <h4 className="h4-small table-heading">Expert talk speaker</h4> */}
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Text Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Bengali</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Bushmen Tribal </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >SPIRE Lab</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >10:12:45</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >75489</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Male:1 ; Female:3</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Nope</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Saurabh</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Deekshitha</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sathvik</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >2025</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Taiwan Trip</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Amar Ujala NewsPaper</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Don't Click Here</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Server Down</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >We don't take any feedback</div>
                                                        </td>
                                                    </tr>
                                                </table>  
                                                </Col>
                                                {/* Right side image */}
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} className="pt-4">        
                                                <div className="imagemaps" >
                                                    <img src={westernbengali} alt="Your western bengali"  className="textimagemaps"/>     
                                                </div>
                                                
                                                
                                                </Col> 
                                            </Row> 
                                        </TabPane> 
                                        <TabPane tabId="2"> 
                                            <Row> 
                                            <Col xs={8} ms={8} md={8} lg={8} xl={8} className="pt-4"> 
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Text Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Bengali</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Bushmen Tribal </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >SPIRE Lab</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >10:12:45</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >75489</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Male:1 ; Female:3</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Nope</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Saurabh</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Deekshitha</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sathvik</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >2025</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Taiwan Trip</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Amar Ujala NewsPaper</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Don't Click Here</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Server Down</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >We don't take any feedback</div>
                                                        </td>
                                                    </tr>
                                                </table>  
                                                </Col> 
                                                {/* Right side image */}
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="pt-4">        
                                                    <div className="imagemaps" >
                                                        <img src={northernbengali} alt="Your northern bengali"  className="textimagemaps"/>     
                                                    </div>    
                                                </Col> 
                                            </Row> 
                                        </TabPane> 
                                        <TabPane tabId="3"> 
                                            <Row> 
                                            <Col xs={8} ms={8} md={8} lg={8} xl={8} className="pt-4"> 
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Text Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Bengali</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Bushmen Tribal </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >SPIRE Lab</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >10:12:45</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >75489</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Male:1 ; Female:3</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Nope</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Saurabh</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Deekshitha</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sathvik</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >2025</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Taiwan Trip</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Amar Ujala NewsPaper</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Don't Click Here</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Server Down</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >We don't take any feedback</div>
                                                        </td>
                                                    </tr>
                                                </table>  
                                                </Col> 
                                                {/* Right side image */}
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="pt-4">        
                                                    <div className="imagemaps" >
                                                        <img src={Roughbengalmap} alt="Your northern bengali"  className="textimagemaps"/>     
                                                    </div>    
                                                </Col> 
                                            </Row> 
                                        </TabPane> 
                                        <TabPane tabId="4"> 
                                            <Row> 
                                            <Col xs={8} ms={8} md={8} lg={8} xl={8} className="pt-4"> 
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Text Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Bengali</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Bushmen Tribal </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >SPIRE Lab</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >10:12:45</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >75489</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Male:1 ; Female:3</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Nope</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Saurabh</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Deekshitha</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sathvik</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >2025</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Taiwan Trip</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Amar Ujala NewsPaper</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Don't Click Here</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Server Down</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >We don't take any feedback</div>
                                                        </td>
                                                    </tr>
                                                </table>   
                                                </Col> 
                                            </Row> 
                                        </TabPane> 
                                        <TabPane tabId="5"> 
                                            <Row> 
                                            <Col xs={8} ms={8} md={8} lg={8} xl={8} className="pt-4"> 
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Text Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Bengali</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Bushmen Tribal </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >SPIRE Lab</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >10:12:45</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >75489</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Male:1 ; Female:3</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Nope</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Saurabh</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Deekshitha</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sathvik</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >2025</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Taiwan Trip</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >Amar Ujala NewsPaper</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Don't Click Here</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`/`} target="_blank" rel="noreferrer" className="custom-reference-links">Server Down</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >We don't take any feedback</div>
                                                        </td>
                                                    </tr>
                                                </table>  
                                                </Col> 
                                            </Row> 
                                        </TabPane> 
                                    </TabContent> 
                                </div > 

                                                
                                                
                                                
                                </Row>
                                </Col>
                               
                            </Row>


  
      
                            </Col>
                            </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};


export default BengaliTextData;