import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';

import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import { useWindowSize } from '@react-hook/window-size';
import application_contants from '../../constants/application_contant';
import ToolsService from '../../../api/services/ToolsService';
import { Link } from 'react-router-dom';


const ToolsDetail = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();


    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList();
    }, []);

    const getList = async () => {
        let it = [];
        await ToolsService.getToolsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("result", result)
                            if (result.code === 0) {
                                if (result.data) {
                                    console.log("location", props.location.pathname)
                                    result.data.list.map((item) => {
                                        if (item.title.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                            setTitle(item.title)
                                            setDetailTitle(item.detailTitle)
                                            setDescription(item.description)
                                            setUrl(item.url)
                                            
                                            
                                        }
                                    });
                                    // debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    console.log("data", data)
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-tools"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-strip-tools"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
               
                    <Row className="mx-0  pt-5">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                            <p className="mb-0 section-title align-left">
                                <Link to={`/tools`} className="custom-link-item">{` Resources > Tools >`}</Link>
                                <span className="h6-large pl-2 fw-600">{`${title}`} </span>
                            </p>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pt-4">
                            <Row className="mb-0 mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                                    <div >
                                        <p className="h6-medium section-title jostRegular title-black  align-left">{detailTitle}</p>
                                        <p className="p-large section-title jostLight align-left title-black pt-2 pb-2">{description}</p>
                                        <a href={`${url}`} target="_blank" rel="noreferrer" className="custom-reference-links">Click Here</a>
                                </div>
                                </Col>
                                </Row>
                                </Col>
                        
                    </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default ToolsDetail;