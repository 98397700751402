import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const DocumentsService = {
    getDocumentsList: async () => {
        const { baseUrl, getDocumentsList } = ApiConfig;

        const url = baseUrl + getDocumentsList;
        console.log('url');
        console.log(url)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const documentsServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_opening",
            true
        );
        console.log("documentsServicePromise",documentsServicePromise)
        return documentsServicePromise;
    },

    insertOrUpdate: async (data) => {
        const { baseUrl, insertORUpdateDocuments } = ApiConfig;
        console.log("insertORUpdateDocuments",insertORUpdateDocuments)
        const url = baseUrl + insertORUpdateDocuments;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        console.log("url",url)
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            title: data.title,
            docTypeTitle: data.docTypeTitle,
            date: data.date,
            docUrl: data.docUrl,
            imageUrl: data.imageUrl,
        });

        const documentsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_openingService",
            true
        );
        return documentsServicePromise;
    },
    enableDisableDocuments: async (data) => {
        const { baseUrl, enableDisableDocuments } = ApiConfig;
        const url = baseUrl + enableDisableDocuments;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableDocumentsServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableDocuments",
            true
        );
        return enableDisableDocumentsServicePromise;
    },
}

export default DocumentsService;