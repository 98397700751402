import React from 'react';
import {
    useWindowSize
  } from '@react-hook/window-size';
import { Link , withRouter} from 'react-router-dom';
import application_contants from '../../constants/application_contant';


const TitleComponent = (props) => {
    const [width] = useWindowSize();
    return (
        <div className="d-flex align-items-center justify-content-between">
            <div>
                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">{props.title}</h5> :
                    <h3 className="h3-large section-title align-left">{props.title}</h3>}
            </div>
            {props.isViewAll ?
            <div>
                <div className="d-flex align-items-center pr-4">
                    <div>
                        <Link to={`/${props.pathName}`}>
                            <p className="p-large section-title align-left">View all</p>
                        </Link>
                    </div>
                    <div className="arrow-img--container">
                        <img src={application_contants.rightImg} alt="" />
                    </div>
                </div>
            </div>: null}
        </div>
    )
}


export default withRouter(TitleComponent);