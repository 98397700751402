import application_contants from "../../constants/application_contant";

const BMGF_Data = [
    
         application_contants.bmgfimg1,
         application_contants.bmgfimg2,
         application_contants.bmgfimg3,
         application_contants.bmgfimg4,
         application_contants.bmgfimg5,
];


export default BMGF_Data;