import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import { useWindowSize } from '@react-hook/window-size';
import { Link } from 'react-router-dom';
import DatasetsService from '../../../api/services/DatasetsService';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import PropTypes from "prop-types";
import { div } from 'react-dom-factories';

/* const SampleDatasets = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Funder"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Funder"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/funder" />

                        <div>
                            <h4>Demo orSample Datasets</h4>
                            <p>
                            Lorem ipsum dolor sit amet. Et dignissimos tenetur et 
                            eius cumque et excepturi natus est corporis sunt 33 voluptatem 
                            possimus ut similique quia cum quia corporis! Est perspiciatis 
                            iusto nam internos nisi aut magnam voluptas et inventore voluptas 
                            sit exercitationem suscipit et cupiditate deleniti. Ut officiis 
                            quia et repellat soluta ut repudiandae accusamus cum possimus 
                            aliquid et molestiae maiores aut dolor rerum est placeat 
                            doloribus? Aut sint quod quo quia excepturi et voluptatem 
                            obcaecati! Id iste adipisci et laboriosam odio et laudantium 
                            placeat qui aliquam repellendus. 
                            </p>
                        </div>








                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};
*/
const SampleDatasets = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return(
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleDatasets"
                    className="strip strip-no-padding px-20"
                    containerType="container"
                >
                    <SEOComponent
                        title="Funder"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/funder" />

                        <div>
                            <h1>Demo orSample Datasets</h1>
                            <p>
                            Lorem ipsum dolor sit amet. Et dignissimos tenetur et 
                            eius cumque et excepturi natus est corporis sunt 33 voluptatem 
                            possimus ut similique quia cum quia corporis! Est perspiciatis 
                            iusto nam internos nisi aut magnam voluptas et inventore voluptas 
                            sit exercitationem suscipit et cupiditate deleniti. Ut officiis 
                            quia et repellat soluta ut repudiandae accusamus cum possimus 
                            aliquid et molestiae maiores aut dolor rerum est placeat 
                            doloribus? Aut sint quod quo quia excepturi et voluptatem 
                            obcaecati! Id iste adipisci et laboriosam odio et laudantium 
                            placeat qui aliquam repellendus. 
                            </p>
                        </div>


                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};


export default SampleDatasets; 
