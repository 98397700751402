import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import TermsAndConditionsData from "../../Utils/Json/TermsAndConditionData";

const TermsAndCondition = () => {
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-terms-and-conditions"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-terms-and-conditions"
                    className="strip strip-no-padding px-0"
                    containerType="termsandcondtion-container"
                >
                    <Row className="mx-0 pt-5">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4">
                            <h2 className="title-h2-small terms-condition-section-title terms-condition-align-left terms-condition-navy-blue-medium">TERMS AND CONDITIONS</h2>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {TermsAndConditionsData.length > 0 && TermsAndConditionsData.map((item, index) => (
                                <div key={index}>
                                    <p className={`p-small-terms-condtion terms-condition-section-title terms-condition-align-left title-navy-blue-medium jostLight lh-25px`}>{item.desc}</p>
                                    <ol className="num-order py-3">
                                        {item.terms.length > 0 && item.terms.map((it, ind) => (
                                            <li key={ind} className="num">
                                                <p className={`p-medium-terms-condition terms-condition-section-title terms-condition-align-left title-navy-blue-medium lh-25px`}>{it.title}</p>
                                                {it.desc !== '' ? <p className={`p-small-terms-condtion terms-condition-section-title terms-condition-align-left title-navy-blue-medium jostLight lh-25px`}>{it.desc}</p> : null}
                                                <ol className="num-order py-3">
                                                    {it.conditions.length > 0 && it.conditions.map((p, i) => (
                                                        <li key={i} className="num">
                                                            <p className={`p-small-terms-condtion terms-condition-section-title terms-condition-align-left title-navy-blue-medium jostLight lh-25px`}>{p.params}</p>
                                                            {p.isSubParams ?
                                                                <ol className="alpha-order py-2">
                                                                    {p.subParams.length > 0 && p.subParams.map((sub, k) => (
                                                                        <li className="alpha">
                                                                            <p className={`p-small-terms-condtion terms-condition-section-title terms-condition-align-left title-navy-blue-medium jostLight lh-25px`} key={k}>{sub.params}</p>
                                                                        </li>
                                                                    ))}
                                                                </ol> : null}
                                                        </li>
                                                    ))}
                                                </ol>
                                            </li>

                                        ))}
                                    </ol>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    )
};


export default TermsAndCondition;


