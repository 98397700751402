import React, { useState } from "react";
import { Col, Row } from "reactstrap";
// import {ApiConfig} from '../../../../api/apiConfig'
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import Slider from "react-slick";
import { useWindowSize } from '@react-hook/window-size';
import { Button, Modal } from 'react-bootstrap';

const AcademicsSlider = (props) => {
    const [width] = useWindowSize();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgurl, setImgurl] = useState([]);
    const [name, setName] = useState([]);
    const [bio, setBio] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [Url, setUrl] = useState([]);

    const handleNavigationPopup=() => {
      setShow(true);
      setName(props.item.name)
      setImgurl(props.item.imgUrl)
      setBio(props.item.bio)
      setDesignation(props.item.designation)
      setUrl(props.item.Url)
      console.log("item.firstClassName",props.item.firstClassName)
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 team-mobile-position ">
        <Row className="mx-0">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row className="mx-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="team-mobile-position px-0 pt-5"
              >
              
              <div className="container ">   
                   <div variant="primary" onClick={() => handleNavigationPopup()}>
                    <div className="popup-team-img-container ">
                    <div className="academics-img-container "> 
                      <img src={`${ApiConfig.url}${props.item.imgUrl}`} alt="" />
                      <div className="third-circle-container cursor-pointer" style={{border: `15px solid ${props.item.firstClassName}`,}}>
                      </div>
                </div>
                </div>
                    <h6 className="h6-small section-title cursor-pointer nunitoSans-Regular align-center pt-4">{props.item.name}</h6>
                    <p className="p-small section-title nunitoSans-Regular cursor-pointer align-center ">{props.item.designation}</p>
                    
                </div>
                </div>

                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Body>
                    <div className="container" >   
                  <div variant="primary" >
                      <div className="popup-team-img-container pt-4">
                        <div className="academics-img-container"> 
                          <img src={`${ApiConfig.url}${imgurl}`} alt="" />
                            <div className="third-circle-container" style={{border: `15px solid`,}}>
                          </div>
                        </div>
                      </div>
                      <h6 className="h6-medium mb-0 section-title align-center pt-4">{name}</h6>
                      <p className="mb-0 section-title align-center">{designation}</p>
                      <p className="mb-0 section-title align-center">{bio}</p>
                      <div classname='a-tag-container'>
                      <a href={`${props.item.url}`} rel="noreferrer" target="_blank"   className="p-small academics-img-container section-title custom-reference-links  nunitoSans-Regular align-center ">{props.item.url}</a>
                </div>

                    </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal> 
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AcademicsSlider;