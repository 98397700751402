import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Nav, } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";

const Footer = (props) => {

  const handleTerms = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    props.history.push(`/terms-and-conditions`);
  }

  return (
    <Strip
      id="tst-Footer"
      className="strip strip-no-padding"
      containerType="container-fluid"
    >
      <Row className="mx-0">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="bg-white-smoke-light pt-3 px-0"
        >
          <Row className="mx-0 align-items-end mobile--footer--logo-position">
            <Col xs={8}
              sm={8}
              md={8}
              lg={3}
              xl={3} className="px-0 mobile-footer--position">
              <div className="d-flex align-items-center mobile--footer--logo-position">
                <div className="pr-12px align-right">
                  <a href={'https://iisc.ac.in/'} target="_blank" rel="noreferrer">
                    <img src={application_contants.IISCImg} alt="" className="iisc-img--width" />
                  </a>
                </div>
                <div className="br-line"></div>
                <div className="pl-12px">
                  <a href={`https://spire.ee.iisc.ac.in/spire/`} target="_blank" rel="noreferrer">
                    <img src={application_contants.spireLab} alt="" className="spire-lab-img--width" />
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={8}
              sm={8}
              md={8}
              lg={9}
              xl={9} className="mobile-footer--position">
              <Row className="mx-0">
                <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                  <p className="p-large mb-0 section-title title-black footer-text">
                    © {new Date().getFullYear()} Speech Recognition in Agriculture and Finance for the Poor in INDIA
                  </p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3} xl={3}>

                  <p className="p-large mb-0 section-title title-blue text-underline footer-text txt-underline cursor-pointer" onClick={() => handleTerms()} offset={-70} smooth={true}>
                    Terms And Conditions
                  </p>
                </Col>
              </Row>

            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="bg-white-smoke-light px-0">
          <img src={application_contants.footerImg} alt="" />
        </Col>
      </Row>
    </Strip>
  );
};

export default withRouter(Footer);
