import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Kannada/male/Kannada_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Kannada/male/Kannada_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Kannada/female/Kannada_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Kannada/female/Kannada_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Kannada/male/Kannada_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Kannada/male/Kannada_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Kannada/female/Kannada_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Kannada/female/Kannada_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Kannada/male/Kannada_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Kannada/male/Kannada_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Kannada/female/Kannada_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Kannada/female/Kannada_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Kannada/male/Kannada_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Kannada/male/Kannada_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Kannada/female/Kannada_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Kannada/female/Kannada_df_2.mp3"; // Female audio

import audio_em1 from "../../../assets/datasetsample/Kannada/male/Kannada_em_1.mp3"; // Male audio
import audio_em2 from "../../../assets/datasetsample/Kannada/male/Kannada_em_2.mp3"; // Male audio
import audio_ef1 from "../../../assets/datasetsample/Kannada/female/Kannada_ef_1.mp3"; // Female audio
import audio_ef2 from "../../../assets/datasetsample/Kannada/female/Kannada_ef_2.mp3"; // Female audio


function Kannada(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Central Kannada */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Central Kannada </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    ಮಣ್ಣಿನ ಗುಣಮಟ್ಟ ಹೆಂಗೆ ತಿಳ್ಕೊಬೇಕು ?
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Central Kannada</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಒಂದು ಎಕರೆ ಭತ್ತ ಕಟಾವ್ ಮಾಡಲು ಕಾರ್ಮಿಕ ಶುಲ್ಕ ಎಷ್ಟು ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Central Kannada </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಬೇಜಗಳಲ್ಲಿ ಎಷ್ಟು ವರ್ಗಗಳಿವೆ ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Central Kannada</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಬ್ಯಾಂಕಿನಾಗ ಇಟ್ಟ ನನ್ನ ಡಿಪಾಸಿಟ್ ರೊಕ್ಕಕ್ಕೆ ಸಿಗೋ ಬಡ್ಡಿ ಹಣ ಎಷ್ಟು ?</p></td>
            </tr>
            {/* Table Dialect 2 == Coastal Kannada  */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Coastal Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಹಣ ಅರ್ಜೆಂಟ್ ಟ್ರಾನ್ಸ್ಫರ್ ಮಾಡ್ವಾಗ ಸರ್ವರ್ ಇಲ್ಲಾಂತ ಆದ್ರೆ ಮುಂದೆ ನಾವೆಂತ ಮಾಡ್ಬೇಕು ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Coastal Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಮಳೆಗಾಲದಲ್ಲಿ ಭತ್ತದ ಬೆಳೆಗೆ ಮಳೆ ಕಮ್ಮಿ ಆದ್ರೆ ಎಂತ ಪರಿಣಾಮ ಆಗಬಹುದು ?</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Coastal Kannada  </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">ಹೆಚ್ಚು ಬೆನಿಫಿಟ್ ಇರುವ ಪಾಲಿಸಿ ಯಾವುದು ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Coastal Kannada </p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಹಸುವಿನ ಹಾಲನ್ನು ಕರೆಯುವ ಯಂತ್ರ ಯಾವುದು ?</p></td>
            </tr>
            {/* Table Dialect 3 == Dharwad Kannada  */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Dharwad Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಸರಕಾರಿ ಗೊಬ್ಬರ ಅಥವಾ ಸಗಣಿ ಗೊಬ್ಬರ ಯಾವ್ದು ಹಾಕಿದ್ರ ಜಾಸ್ತಿ ಫಸಲು ಬರತೈತ್ರಿ ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Dharwad Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಸರ್ ನನಗೆ ಎ.ಟಿ.ಎಂ ಕಾರ್ಡ್ ನಲ್ಲಿ ರೊಕ್ಕ ಬರತಿಲ್ಲ ಯಾಕ್ರೇ ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Dharwad Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಬದ್ನಿಕಾಯಿ ಬೆಳಿಗೆ ಎಷ್ಟ ಗೊಬ್ಬರ ಹಾಕ್ಬೇಕು ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Dharwad Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಬೆಳಿ ವಿಮೆ ಮಾಡಿಸಿದ್ರ ಏನ್ ಛಲೋ ಆಕತ್ರಿ ?</p></td>
            </tr>
                {/* Table Dialect 4 == Northeastern Kannada  */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Northeastern Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ನಾವ್ ಹೊಲಕ್ಕ್ ಬೇಕಾಗಿದ್ದ್ ಮಷಿನ್ ತಗೋಬೇಕ್ ಅಂದ್ರ ಆದ್ರ ಬಗ್ಗೆ ತಿಳ್ಕೊಂಡಿರ್ಬೇಕ್</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Northeastern Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ತೆಂಗಿನಕಾಯಿ ಬಾದಾಮಿ ಓಟ್ಸ್ ಸೋಯಾ ಇವ್ದರಿಂದ್ ನಾವ್ ತಗ್ದ್ ಬಳಸ್ಬಹುದ್ ಮತ್ತ್ ಮಾರ್ಬಹುದ್</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Northeastern Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ನಮ್ ಭಾರತ ದೇಶದಾಗ್ ರೈತರ್ ತಮ್ಮ್ ಹೊಲ್ದಾಗ್ ಮಷಿನಗೋಳ್ ಬಳಸುಗತ್ತರ್</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Northeastern Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ರೇಷ್ಮೆ ಚಿಟ್ಟೆಗೊಳ್ದು ಮೊಟ್ಟೆ ಲಾರ್ವಾಗೊಳ್ ಹೊರಗ್ ಬರ್ಲುಕ್ ಹದಿನಾಲ್ಕು ದಿನ ತೋಗೋತಾವ್</p></td>
            </tr>
                {/* Table Dialect 5 == Mysore Kannada  */}
                <tr> {/* Table Row Sample 17 */}
                <td className="tableEvenDialect"><p className="p-large">Mysore Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಗಾಂಧೀಜಿಯವರು ಸ್ವತಹ ತಾವೇ ತಮ್ಮ ಬಟ್ಟೆಗಳಿಗಾಗಿ ನೂಲು ನೇಯುವ ಚಕ್ರವನ್ನು ಬಳಸುತ್ತಿದ್ದರು</p></td>
            </tr>
            <tr> {/* Table Row Sample 18 */}
                    <td className="tableOddDialect"><p className="p-large">Mysore Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಅಬ್ಸಲ್ಯೂಟ್ ರಿಟರ್ನ್ ಅದು ಕೆಲವು ಬಾರಿ ಸಕಾರಾತ್ಮಕವಾಗಿ ಅಥವಾ ನಕಾರಾತ್ಮಕವಾಗಿ ಬರಬಹುದು</p></td>
            </tr>
            <tr> {/* Table Row Sample 19 */}
                <td className="tableEvenDialect"><p className="p-large">Mysore Kannada  </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_em2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ಸಸ್ಯ ರೋಗಶಾಸ್ತ್ರ ಸಾಂಕ್ರಾಮಿಕ ಜೀವಿಗಳು ಮತ್ತು ಶಾರೀರಿಕ ಅಂಶದಿಂದ ಉಂಟಾಗೊ ಸಸ್ಯರೋಗದ್ ಅಧ್ಯಯನವಾಗಯ್ತೆ</p></td>
            </tr>
            <tr> {/* Table Row Sample 20 */}
                    <td className="tableOddDialect"><p className="p-large">Mysore Kannada </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_ef2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ಫೋನ್ ಪೆ ದೇಶದ ಮೊದಲ ಅಪ್ಲಿಕೇಶನ್ ಆಗಿದ್ದು ಯು.ಪಿ.ಐ ಆಧಾರಿತ ವಹಿವಾಟುಗಳಿಗೆ ದರ ವಿಧಿಸಲು ಆರಂಭಿಸಿದೆ</p></td>
            </tr>


            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleKannada = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleKannada"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleKannada"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleKannada"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleKannada" />
                    <Strip
                        id="tst-strip-SampleKannada"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Kannada Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Kannada />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleKannada;