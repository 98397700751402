import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../../../constants/application_contant";
import Strip from "../../../../../customComponent/Strip/Strip";
import RenderPage from "../../../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../../../customComponent/ExternalLayout/ExternalLayout";
import { Link } from 'react-router-dom';

const Storytype1 = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Storytype1"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Storytype1"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Storytype1"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/Storytype1" />
                    <Strip
                        id="tst-strip-Storytype1"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                           <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/Documents/Stories/`} className="custom-link-item">{` Resources > Documents > Stories > Story Language >`}</Link>
                                </p>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Stories in English</h5> :
                                            <h3 className="h3-large section-title align-left">Story in English Language</h3>}
                                    </Col>
                                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <GalleryComponent />
                                    </Col> */}
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                    {/* <p>this is an example to show the card's look inside the Stories</p> */}
                                        <Row>
                                            {/* <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="opening-card-br">
                                                
                                                <a href={`/documents/stories/storytype1/storytitle1`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="bg-solitude-blue p-3 opening-card opening-card-br-position-to">
                                                            <div>
                                                                <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">Cluster Forming... Knuckle Buckle Down</p>
                                                            </div>
                                                            
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>

                                            <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="opening-card-br">
                                                
                                                <a href={`/documents/stories/storytype1/storytitle2`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="bg-solitude-blue p-3 opening-card opening-card-br-position-to">
                                                            <div>
                                                                <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">Adjourning Working Structure</p>
                                                            </div>
                                                            
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col> */}
                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 12} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"   >
                                                <a href={`https://drive.google.com/file/d/1ROpl6KrZZrp2SzHR6CQGQYpxlV5jT3YM/view?usp=sharing`} target="_blank" without rel="noreferrer">
                                                <Row className="mb-0 mx-0">
                                                <Col xs={3} sm={3} md={3} lg={11} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                    <div>
                                                        <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">Cluster Forming... Knuckle Buckle Down</p>
                                                        <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">As a step forward in changing rural and common people's lives...</p>
                                                    </div>
                                                        <div className="align-right pb-2">
                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                        </div>
                                                </Col>
                                                </Row>
                                                </a>
                                            </Col>
                                            <Col xs={2} sm={2} md={3} lg={width === 1024 ? 5 : 12} xl={width >= 1900 ? 2 : 12} className="px-0 py-1 contributors-card-br card-reports"   >
                                                <a href={`https://drive.google.com/file/d/1RaEo7x9qBgzIVGwmqVmGRdza6Szh__u8/view?usp=sharing`} target="_blank" without rel="noreferrer">
                                                <Row className="mb-0 mx-0">
                                                <Col xs={3} sm={3} md={3} lg={11} xl={11} className={`bg-solitude-grey p-3 contributors-card-br`}>
                                                    <div>
                                                        <p className="p-large section-title nunitoSans-Bold align-left contributors-card-position">Adjourning Working Structure</p>
                                                        <p className="p-small section-title nunitoSans-Regular align-left contributors-card-position">As the RESPIN and SYSPIN projects provide countless chances to individuals...</p>
                                                    </div>
                                                        <div className="align-right pb-2">
                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                        </div>
                                                </Col>
                                                </Row>
                                                </a>
                                            </Col>
                                                            
                                          
                                            
                                          </Row>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Storytype1.defaultProps = {
    isClassName: true,
};

Storytype1.propTypes = {
    isClassName: PropTypes.bool,
};

export default Storytype1;
