import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import ReseachAreaService  from "../../../api/services/ReseachAreaService "
import debugLogger from '../../Utils/debugLogger';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';


const AreaDetails = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [galleryImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);


    React.useEffect(() => {
        setData([]);
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList();
    }, []);

    const getList = async () => {
        let it = [];
        await ReseachAreaService.getResearchAreaList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("resukt",result)
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.list.map((item) => {
                                        console.log(props.location.pathname.split('/')[2])
                                        if (item.reseachTitle.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
                                            it.push(item);
                                            setTitle(item.title)
                                            setCode(item.code)
                                            setGalleryImgList(item.galleryList ? JSON.parse(item.galleryList) : [])
                                            console.log("item",item.galleryList ? JSON.parse(item.galleryList) : [])
                                        }                                       
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }
    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...galleryImgList];
        console.log("list",list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`//${sectionTitle}`} />

                        <Row className="mx-0 pt-5">
                            <Col xs={12} ms={12} md={12} lg={11} xl={12} className="m-auto">
                                <p className="mb-0 section-title nunitoSans-Light  pt-3 align-left">
                                    <Link to={`/Areas`} className="custom-link-item">{`Research > Areas >`}</Link>
                                    <span className="fw-600 pl-2 text-capitalize">{`${sectionTitle}`} </span>
                                </p>
                                  
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                <Row>
                                    <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                        {data.length > 0 && data.map((item, index) => (
                                            <div key={index}>
                                                {/* {data.description === null && data.publications === null ? */}
                                                <div>
                                                {item.description ?
                                                <Row className="pt-3">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h2-small section-title fw-300 nunitoSans-SemiBold align-left ">{item.title}</h5></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="p-large section-title  nunitoSans-Light pb-2 pt-2 align-left">{item.description}</p>
                                                    </Col>
                                                </Row>
                                                : null}
                                                <Row>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3 pt-3">
                                                        <PointersItem item={item} />
                                                    </Col>
                                                </Row>
                                                {item.publications ?
                                                <Row className ="pb-4">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    <h5 className="h4-small section-title fw-600 NunitoSans-SemiBold align-left ">Publications</h5></Col>
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                        <p className="p-large section-title nunitoSans-Light pt-3 align-left">{item.publications}</p>
                                                    </Col>   
                                                </Row>
                                                : null}    
                                                <Row className ="pb-4">
                                                        {galleryImgList.length > 0 && galleryImgList.map((item, index) => (
                                                           <Col xs={12} ms={12} md={6} lg={6} xl={6}>
                                                            <Row key={index} className="mx-0">
                                                                 <div className="bg-zircon-grey areas-card-img-container cursor-pointer">
                                                                <img src={`${ApiConfig.url}${item.imgPath}`} alt="" className="img-item"/>
                                                                </div>
                                                                <p className="mb-0 section-title align-left">{item.imgTitle}</p>
                                                            </Row>
                                                            </Col>    
                                                        ))}
                                                    </Row>
                                                <Row className ="pb-5">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5">
                                                        <DownloadLinks item={item} />
                                                    </Col>
                                                </Row>
                                                </div>
                                                {/* : null} */}
                                            </div>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                           </Row>
                    </Strip>
                    {/* <SocialIcon /> */}
                    {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
}

export default AreaDetails;


const PointersItem = (props) => {
    return (
        <div>
            {props.item && props.item.pointersPoints ?
                <Row className="mx-0">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0">
                    {/* <h5 className="h4-small section-title fw-600 nunitoSans-SemiBold  align-left pb-1">Pointers</h5> */}
                    </Col>
                    <Row>
                        <Col xs={12} ms={12} md={12} lg={12} xl={12}><p className="h4-small section-title fw-600 nunitoSans-SemiBold  align-left pb-3">{props.item.pointersDescription}{props.item.pointersDescription ? '' : ''}</p></Col>
                        {props.item.pointersPoints.split(/[\n]/g).map((infoItem, index) => (
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                <div className="d-flex">
                                    <FiberManualRecord className="dot-icon" />
                                    <div>
                                        <p className="mb-0 section-title align-left">{infoItem}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row> : null}
        </div>

    )
}

const DownloadLinks = (props) => {
    return (
        <div>
            {props.item && props.item.downloadLinks ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h4-small section-title nunitoSans-SemiBold  fw-600 align-left pb-1">Download Links</h5></Col>
                    {props.item.downloadLinks.split(/[\n]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                            <a href={`${it}`} rel="noreferrer" target="_blank" className="p-large media-section-title nunitoSans-Regular align-left media-card-position text-underline">{it}</a>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}

const PreferredQualification = (props) => {
    return (
        <div>
            {props.item && props.item.preferredPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Preferred Qualification</h5></Col>
                    {props.item.preferredPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 section-title align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                : null}
        </div>
    )
}

const Duration = (props) => {
    return (
        <div>
            {props.item.duration.length > 0 ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small  section-title nunitoSans-SemiBold fw-600 align-left pb-1">Duration</h5></Col>
                    {props.item.duration.map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                {/* <FiberManualRecord className="dot-icon" /> */}
                                <div>
                                    <p className="mb-0 section-title align-left">{it.param}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}