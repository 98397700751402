import React, { useState } from 'react';
import {
    useWindowSize
  } from '@react-hook/window-size';
import application_contants from "../../constants/application_contant";
import { Row, Col } from 'reactstrap';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import BFT_Data from '../../Utils/Json/BFT_Data';

const BFT_Component = (props) => {
    const [width] = useWindowSize();
    const [isModal, setIsModal] = useState(false);
    const { imgList, playListId, } = props;
    const [data, setData] = useState(BFT_Data);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [imageData, setImageData] = useState([]);
    let imagePOpup = imgList;
    const handleToggle = () => {
        setImageData([...data])
        setIsModal(!data)
    }
    React.useEffect(() => {
        setImageData([...data])
    },data)
    const handleToggleIndex = (index) => {
        let templist = imageData;
        let list =  templist;
         list.splice(0, 0, list.splice(index, 1)[0]);
        // list = list.filter((val) => val != "text");
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
    return (
        
        <div > 
        {width >= application_contants.MOBILE_BREAKPOINT ?
        <Row className="mx-0">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
                {data.length> 0 && data.map((item, index) =>(
                  <Col xs={12} sm={12} md={6} lg={4} xl={4} key={index} className="pb-6 image">
                      <img src={item} alt="" className="photos-img object-fit_contain" onClick={() => handleToggleIndex(index,data)}/>
                  </Col>
                ))}
            </Row>
        </Col>
            {isModal ?
                <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} isServerPath={false} />
                : null}
        </Row>
        :
        <Row className="mx-0 pl-3 ">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
                {data.length> 0 && data.map((item, index) =>(
                  <Col xs={10} sm={6} md={10} lg={4} xl={4} key={index} className="pb-4 image">
                      <img src={item} alt="" className="mobile-gal-img object-fit_contain" onClick={() => handleToggleIndex(index,data)}/>
                  </Col>
                ))}
            </Row>
        </Col>
            {isModal ?
                <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} isServerPath={false} />
                : null}
        </Row>
        }
        </div>
    )
}
export default BFT_Component;
