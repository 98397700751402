import React from "react";
import memoizeOne from "memoize-one";
import { DeleteOutlineOutlined, CreateOutlined } from "@material-ui/icons";
import { ApiConfig } from "../../../../api/apiConfig/apiConfig";

const TablesColumns = memoizeOne((handleEdit, handleDelete) => [
    {
        name: "Title",
        selector: "openingTitle",
        grow: 0.4,
        sortable: false,
        cell: (row) => <div>{row.openingTitle}</div>,
    },
    // {
    //     name: "TypeId",
    //     selector: "TypeId",
    //     grow: 0.4,
    //     sortable: false,
    //     cell: (row) => <div>{row.typeId}</div>,
    // },
    {
        name: "Description",
        selector: "description",
        sortable: false,
        cell: (row) => <div>{`${row.description !== null && row.description.length > 50 ? `${row.description.substr(0, 50)}...` : row.description}`}</div>,
    },
    {
        name: "Code",
        selector: "code",
        sortable: false,
        grow: 0.3,
        cell: (row) => <div>{row.code}</div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        grow: 0.3,
        cell: (row) => <div>{`${row.status === 1 ? 'Active' : 'InActive'}`}</div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        grow: 0.3,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex">
                <div onClick={() => handleDelete(row)}>
                    <DeleteOutlineOutlined style={{ color: "#ec0707" }} />
                </div>
                <div onClick={() => handleEdit(row)}>
                    <CreateOutlined style={{ color: "#00BCD4" }} />
                </div>
            </div>
        ),
    },

]);




export default TablesColumns;
