export const ApiConfig = {

    login: "login",
    getDesignationList: 'getDesignationList',
    insertOrUpdatePeople: 'insertOrUpdatePeople',
    getPeopleList: 'getPeopleList',
    enableDisableUser: 'enableDisableUser',
    insertOrUpdateBanner: 'insertOrUpdateBanner',
    getBannerList: 'getBannerList',
    enableDisableBanner: 'enableDisableBanner',
    contactUs: 'contact-us',
    getAboutInformation: 'getAboutInformation',
    insertOrUpdateAbout: 'insertOrUpdateAbout',
    insertOrUpdatePartner: 'insertOrUpdatePartner',
    getPartnerList: 'getPartnerList',
    enableDisablePartner: 'enableDisablePartner',
    insertOrUpdateOpening: 'insertOrUpdateOpening',
    getOpeningList: 'getOpeningList',
    enableDisableOpening: 'enableDisableOpening',
    getOpeningsList: 'getOpeningsList',
    insertORUpdateChallengers : 'insertORUpdateChallengers',
    getChallengersList : 'getChallengersList',
    getChallengersById : 'getChallengersById',
    enableDisableChallenges : 'enableDisableChallenges',
    getResearchAreaList: 'getResearchAreaList',
    getResearchAreasList: 'getResearchAreasList',
    insertOrUpdateResearchAreas: 'insertOrUpdateResearchAreas',
    enableDisableResearchAreas: 'enableDisableResearchAreas',
    getDocumentsList: 'getDocumentsList',
    insertORUpdateDocuments:'insertORUpdateDocuments',
    enableDisableDocuments:'enableDisableDocuments',
    getMediaList: 'getMediaList',
    insertORUpdateMedia: 'insertORUpdateMedia',
    enableDisableMedia: 'enableDisableMedia',
    getContributorsList:'getContributorsList',
    enableDisableContributorsAcademics:'enableDisableContributorsAcademics',
    insertORUpdateContributorsAcademics :'insertORUpdateContributorsAcademics',
    insertORUpdateContributorsIndividual: 'insertORUpdateContributorsIndividual',
    insertORUpdateContributorsExperts: 'insertORUpdateContributorsExperts',
    insertORUpdateContributorsIndustry:'insertORUpdateContributorsIndustry',
    enableDisableContributorsIndustry:'enableDisableContributorsIndustry',
    enableDisableContributorsIndividual:'enableDisableContributorsIndividual',
    enableDisableContributorsExperts:'enableDisableContributorsExperts',
    getPublicationList: 'getPublicationList',
    insertORUpdatePublication: 'insertORUpdatePublication',
    enableDisablePublication: 'enableDisablePublication',
     getToolsList: 'getToolsList',
     insertORUpdateTools: 'insertORUpdateTools',
      enableDisableTools: 'enableDisableTools',
      getdatasetsList: 'getdatasetsList',
      insertORUpdatedatasets: 'insertORUpdatedatasets',
       enableDisabledatasets: 'enableDisabledatasets',
    
    // ============URLs================
    // these two are Production Server
      baseUrl: "https://respin.iisc.ac.in/api/v1/",
      url: 'https://respin.iisc.ac.in',

    //   url: 'https://respin.iisc.ac.in',
    // baseUrl: "http://localhost:5005/v1/",
    
    // Test Server
    //  baseUrl: 'http://13.127.250.165:5005/v1/',
    //  url: 'http://13.127.250.165',
    //baseUrl: 'http://65.1.97.233/:5005/v1/',
    // New IP Address: 65.1.28.170,
    };

    export default ApiConfig;
