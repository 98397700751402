import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap"
import OpeningService from '../../../api/services/OpeningService';
import TitleComponent from '../TitleComponent/TitleComponent';
import application_contants from '../../constants/application_contant';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';


const OpeningComponent = (props) => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [otherData, setOtherData] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])

    const getList = async () => {
        setIsLoading(true);
        await OpeningService.getOpeningsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);

                            if (result.code === 0) {
                                if (result.data) {
                                    const lists = result.data.list.filter((it) => it.status === 1 && it.typeId === 1);
                                    setData([...lists]);
                                    const otherLists = result.data.list.filter((it) => it.status === 1 && it.typeId === 2);
                                    setOtherData([...otherLists])
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setOtherData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handlePdf = (item) => {
        window.open(
            `${ApiConfig.url}${item.pdfURL}`,
            // 'https://drive.google.com/file/d/1xj-CX02DvmdBp58JXqbH6SfD_v3FCFmk/view?usp=sharing',
             "_blank"
        );
    }

    return (
        <Row className="mx-0">
             {props.isSecond ?
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-0 px-0">
                    {otherData.length > 0 ?
                    <h4 className="h4-small section-title align-left pb-3 pt-2">{props.secondSectionTitle}</h4>: null}
                    <Row>
                        {otherData.map((item, index) => {
                            return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} className="opening-card-br" key={index}>
                                <Row className="mb-3 mx-0">
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11} className={`bg-solitude-blue cursor-pointer p-3 opening-card opening-card-br-position-`} onClick={() => handlePdf(item)}>
                                        <div >
                                            <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">{item.openingTitle}</p>
                                        </div>
                                        {/* <div className="align-right">
                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                        </div> */}
                                    </Col>
                                </Row>

                            </Col>
                        })}
                    </Row>
                </Col> : null}
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2 px-0">
                {!props.isSecond ?
                    <div className="pb-4">
                        <TitleComponent title={props.firstSectionTitle} pathName={'openings'} isViewAll={true} />
                    </div>
                    : <h4 className="h4-small section-title align-left pb-3 pt-2">{props.firstSectionTitle}</h4>
                }
                <Row>
                    {data.map((item, index) => {
                        return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} className="opening-card-br" key={index}>
                            <a href={`/openings/${item.openingTitle.toLowerCase()}`}>
                                <Row className="mb-3 mx-0">
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11} className={`bg-solitude-blue p-3 opening-card opening-card-br-position-`}>
                                        <div>
                                            <p className="p-large section-title nunitoSans-Regular align-left opening-card-position">{item.openingTitle}</p>
                                        </div>
                                        {/* <div className="align-right">
                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                        </div> */}
                                    </Col>
                                </Row>
                            </a>
                        </Col>
                    })}
                </Row>
            </Col>
           
        </Row>
    )
}

export default OpeningComponent;