import application_contants from "../../constants/application_contant";

const BFT_Data = [
    
        application_contants.bngFtImg1,application_contants.bngFtImg2,application_contants.bngFtImg3,application_contants.bngFtImg4,application_contants.bngFtImg5,application_contants.bngFtImg6,application_contants.bngFtImg7,
        application_contants.bngFtImg8,application_contants.bngFtImg9,application_contants.bngFtImg10,application_contants.bngFtImg11,application_contants.bngFtImg12,application_contants.bngFtImg13,application_contants.bngFtImg14,


];


export default BFT_Data;