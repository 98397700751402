import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";

const Docs = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Documents"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Documents"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Documents"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/Documents" />
                    <Strip
                        id="tst-strip-Documents"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                        
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Documents</h5> :
                                            <h3 className="h3-large section-title align-left">Documents</h3>}
                                    </Col>
                                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <DocumentsComponent />
                                    </Col> */}
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="docs-card-br">
                                                {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                                <a href={`/documents/flyers`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="docsbox-bg docs-card docs-card-br-position-to">
                                                            <div>
                                                                <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 docs-card-position">Flyers</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>
                                            
                                            <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="docs-card-br">
                                                  {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}>  */}
                                                <a href={`/documents/stories`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="docsbox-bg docs-card docs-card-br-position-to">
                                                            <div>
                                                                <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 docs-card-position">Stories</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>
                                        
                                            <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="docs-card-br">
                                                { /*  <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */ } 
                                                <a href={`/documents/books`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="docsbox-bg docs-card docs-card-br-position-to">
                                                            <div>
                                                                <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 docs-card-position">Books</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>
                                            { /* 
                                            <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="docs-card-br">
                                                 <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> 
                                                <a href={`/document/audiovalidation`}>
                                                    <Row className="mb-4 mx-1">
                                                        <Col xs={12} sm={12} md={12} lg={6} xl={11} className="docsbox-bg docs-card docs-card-br-position-to">
                                                            <div>
                                                                <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 docs-card-position">Audio Text Validation</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>
                                            */ } 
                                        {/*   */}                                               
                                        </Row>
                                           
                                            
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Docs.defaultProps = {
    isClassName: true,
};

Docs.propTypes = {
    isClassName: PropTypes.bool,
};

export default Docs;
