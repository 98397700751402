import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import Strip from "../../customComponent/Strip/Strip";
import application_contants from "../../constants/application_contant";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import AboutService from "../../../api/services/AboutService";
import ImageList from './ImageList';
import Aboutus from "../../Utils/Json/Aboutus";


const AboutUs = (props) => {
  const [width] = useWindowSize();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let imgList = [application_contants.galleryImg1, application_contants.galleryImg2, application_contants.galleryImg3, application_contants.galleryImg4, application_contants.galleryImg5,application_contants.galleryImg6,application_contants.galleryImg7,application_contants.galleryImg8,application_contants.galleryImg9] //,application_contants.galleryImg10,application_contants.galleryImg11,application_contants.galleryImg12,application_contants.galleryImg13,application_contants.galleryImg14,application_contants.galleryImg15]

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getInformation();
  }, [])


  const getInformation = async () => {
    await AboutService.getInformation()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                if (result.data) {
                  const params = result.data.list[result.data.list.length - 1];
                  const listItem = params.description.split(/[\n\r]/g);
                  setData([...listItem])
                  setIsLoading(false);
                } else {

                  setIsLoading(false);
                }
              } else {

                setIsLoading(false);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }


  return (
    <ExternalLayout>
      <RenderPage
        id="tst-landing"
        className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
        containerType="container-fluid"
      >
        <Strip
          id="tst-AboutUs"
          className="strip strip-no-padding"
          containerType="container-fluid"
        >
          <SEOComponent
            title="About"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Speech recognition refers to a computer program that processes human speech (verbal) into a written format (text). It is also known as automatic speech recognition (ASR), speech-to-text or computer speech recognition."
            siteUrl="/About" />
          <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
              {imgList.length > 0 ?
                <ImageList data={imgList} />
                : null}
            </Col>
          </Row>
          <Row className="mx-0 mt-5">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
              <Row className="mx-0">
                {/* <Col xs={12} sm={12} md={12} lg={9} xl={11} className="m-auto">
                  {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title pt-3 pb-4 mobile-text-position">About</h5> :
                    <h1 className="h1-small section-title pt-3 pb-4 mobile-text-position">About</h1>}
                </Col> */}
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                  <Row className="mx-0">
                    {Aboutus.length > 0 && Aboutus.map((it, index) => (
                      <Col xs={12} sm={12} md={12} lg={10} xl={11} className="m-auto">
                        <p1 className="mb-2 section-title title-bold fw-600 mobile-text-position pt-4">
                          {it.title} 
                          <p1 className="mb-2 section-title title-bold mobile-text-position pl-1 pt-4">
                             {it.title9}
                            </p1>
                        </p1>
                        <p className="mb-0 section-title mobile-text-position pb -3 pt-4">
                          {it.desc1}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-3 pt-3">
                          {it.desc2}
                        </p>

                        <p1 className="mb-2 section-title title-bold fw-600 mobile-text-position pt-4">
                          {it.title} 
                          <p1 className="mb-2 section-title title-bold mobile-text-position pl-1 pt-4">
                          {it.title2}
                          </p1>
                        </p1>
                        <p className="mb-0 section-title mobile-text-position pb-3 ">
                          {it.desc3}
                        </p>
                        <p1 className="mb-2 section-title title-bold fw-600 mobile-text-position pt-4">
                          {it.title} 
                          <p1 className="mb-2 section-title title-bold mobile-text-position pl-1 pt-4">
                          {it.title3}
                          </p1>
                        </p1>
                        
                        <p className="mb-0 section-title mobile-text-position pb-3">
                          {it.desc4}
                        </p>
                        <p1 className="mb-0 section-title fw-600 mobile-text-position pb-3">
                          {it.title4}
                          <p1 className="mb-0 section-title mobile-text-position pb-3">
                          {it.title10}
                          </p1>
                        </p1>
                        <p className="mb-0 section-title mobile-text-position pb-3 pt-3">
                          {it.desc5}
                        </p>
                        <p className="section-title mobile-text-position  pl-5">
                          {it.title5}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pl-5">
                          {it.objectives1}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pl-5">
                          {it.objectives2}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pl-5">
                          {it.objectives3}
                        </p>
                        <p className="mb-0 section-title mobile-text-position fw-800 pt-3">
                          {it.title6}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pt-2">
                          {it.desc7}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint1}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint2}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint3}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint4}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint5}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint6}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint7}
                        </p>
                        <p className="mb-0 section-title mobile-text-position ">
                          {it.subpoint8}
                        </p>
                        <p className="mb-0 section-title mobile-text-position fw-800 pt-3">
                          {it.title7}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-4 ">
                          {it.desc8}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-4 ">
                          {it.desc9}
                        </p>
                        {/* <p className="mb-0 section-title mobile-text-position fw-800 pb-3">
                          {it.title8}
                        </p> */}
                        <p className="mb-0 section-title title-bold mobile-text-position pb-3 ">
                          {it.subtitle1}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-3 ">
                          {it.desc10}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-3 ">
                          {it.subtitle2}
                        </p>
                        <p className="mb-0 section-title mobile-text-position pb-3 ">
                          {it.desc11}
                        </p>
                      </Col>
                      
                    ))}

                    {/* <Col xs={12} sm={12} md={12} lg={9} xl={9} className="m-auto">
                      <p className="mb-0 section-title mobile-text-position pb-4">Speech-based technologies are a necessity for digital technologies to be adopted in India because a large majority of users are only able to communicate through spoken language and dialects due to language and literacy constraints. The output of this project will allow local innovation in emerging markets to develop products and services serving the rural poor populations in their own medium of engagement with technology use cases such as digital financial services, robo-advisory, informed consent, grievance redressal, product suitability, customer service delivery, strong authentication, information exchange, crowd-sourced digital work, agricultural advisory, farmer app support, support for agricultural finance and crop insurance services etc.</p>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};

AboutUs.defaultProps = {
  isClassName: true,
};

AboutUs.propTypes = {
  isClassName: PropTypes.bool,
};


export default AboutUs;
