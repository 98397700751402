import { Card, FormGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { Col, Form, Label, Row, Input, Button, CardBody, Collapse } from 'reactstrap';
import AboutService from '../../../../api/services/AboutService';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import DashboardLayout from '../../../customComponent/InternalLayout/DashboardLayout';
import Loader from '../../../customComponent/Loader/Loader';
import RenderPage from '../../../customComponent/RenderPage/RenderPage';
import Strip from '../../../customComponent/Strip/Strip';
import debugLogger from '../../../Utils/debugLogger';


const AboutUs = () => {
    const [data, setData] = useState([]);
    const [item, setItem] = useState('');
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        getInformation();
    }, []);

    const getInformation = async () => {
        await AboutService.getInformation()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    const params = result.data.list[result.data.list.length - 1];
                                    setItem(params);
                                    const listItem = params.description.split('<NewLine>');
                                    debugLogger('information', listItem);
                                    setData([...listItem])
                                    setIsLoading(false);
                                } else {

                                    setIsLoading(false);
                                }
                            } else {

                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleEdit = (item) => {
        setId(item.id);
        setDescription(item.description);
        setIsOpen(false)
    }

    const handleSubmit = async () => {
        debugLogger(description.replace(/[\n\r]/g, '<NewLine>'));
        const userId = await localStorage.getItem('userId')
        let data = {
            userId: userId,
            id: id,
            description: description
        }
        await AboutService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);
                                await getInformation();
                                setDescription('');
                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }



    const toggle = () => setIsOpen(!isOpen);

    return (
        <DashboardLayout>
            <RenderPage
                id="aboutus-page"
                className="mt-3"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-aboutus"
                    className="strip strip-no-padding"
                    containerType="container mt-5"
                >
                    <Row className="mx-0 br-grey br-4px px-3">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                            <h5 className="h5-small internal-section--title align-left py-3">Aboutus</h5>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-2">
                            <Row className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 br-grey br-4px">
                                    <div className="cursor-pointer d-flex align-items-center justify-content-between">
                                        <div onClick={() => toggle()} >
                                            <p className="internal-section--title align-left py-2 px-3">Content Showing</p>
                                        </div>
                                        <div onClick={() => handleEdit(item)}>
                                            <p className="internal-section--title align-left py-2 px-3">Edit</p>
                                        </div>
                                    </div>
                                    <Collapse isOpen={isOpen}>
                                        <Card>
                                            <CardBody className="pt-2">
                                                {data.length > 0 && data.map((it, index) => (
                                                    <p className="internal-section--title align-left pb-3" key={index}>{it}</p>
                                                ))}

                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>
                            </Row>


                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 px-0">
                            <Form>
                                <FormGroup row className="mx-0 align-items-center">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Label className="common-label text-left">
                                            Description*:
                                       </Label>
                                        <Input
                                            type="textarea"
                                            placeholder="description"
                                            id="description"
                                            name="description"
                                            className="form-control common-form-control textarea-form-control"
                                            value={description}
                                            onChange={(event) => handleDescription(event)}
                                            rows={40}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                            <Row className="no-margin mt-3">
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    className="align-right"
                                >
                                    <Row className="justify-content-end">
                                        <Col xs={6} sm={6} md={4} lg={2} xl={2}>
                                            <Button

                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                onClick={() => handleSubmit()}
                                                disabled={!description}
                                            >
                                                Submit
                                              </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <div className="loader-position">
                            <Loader containerClassName="" />
                        </div>
                    ) : null}
                </Strip>
            </RenderPage>
        </DashboardLayout>
    )
}

export default AboutUs;