import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import DataTable from 'react-data-table-component';
import {Modal, ModalBody, Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input,Form, Label, FormGroup  } from 'reactstrap';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import DashboardLayout from '../../../customComponent/InternalLayout/DashboardLayout';
import Loader from '../../../customComponent/Loader/Loader';
import MessageConfirmation from '../../../customComponent/MessageConfirmation/MessageConfirmation';
import RenderPage from '../../../customComponent/RenderPage/RenderPage';
import Strip from '../../../customComponent/Strip/Strip';
import debugLogger from '../../../Utils/debugLogger';
import { SearchOutlined } from '@material-ui/icons';
import application_contants from '../../../constants/application_contant';
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../Utils/dataTablesStyledTheme';
import TablesColumns from './TablesColumns';
import ContributorService from '../../../../api/services/ContributorService';
import AddExpert from './AddExpert';
import AddExpertPeople from './AddExpertPeople/AddExpertPeople';

const ExpertList = () => {
    const [width] = useWindowSize();
    const [filterText, setFilterText] = useState();
    const [data, setData] = useState([]);
    const [expertData, setExpertData] = useState([]);
    const [individualData, setIndividualData] = useState([]);
    const [isAdd, setIsAdd] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [item, setItem] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [typeId, setTypeId] = useState('');
    // const { toggle } = props;

    React.useEffect(() => {
        getList();
    }, [isAdd]);
    const handleTypeId = (item) => {
        setTypeId(item)
    }

    const getList = async () => {
        setIsLoading(true);
        await ContributorService.getContributorsList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    debugLogger(result.data);
                                    setData([...result.data.expertsList.list])
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setExpertData([]);
                                setIndividualData([])
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };




    const handleOnChange = (event) => {
        setFilterText(event.target.value)
    }

    const handleClear = () => {
        setFilterText("");
    };

    const handleToggle = () => {
        setIsModal(!isModal);
        setIsAdd(true)
    };
    const handleToggleForAcademics = () => {
        setIsModal(!isModal);
        setIsAdd(true)
    };

    const handleRefresh = async () => {
        setIsModal(!isModal);
        setIsAdd(true);
        await getList();
    }

    const handleCancelBtn = async () => {
        setIsConfirmModal(false)
        setItem('');
    }

    const handleConfirmBtn = async () => {
        setIsConfirmModal(false);
        const userId = await localStorage.getItem('userId')
        let data = {
            id: item.id,
            status: item.status === 1 ? 0 : 1,
            userId: userId,
        };
        setIsLoading(false);
        await ContributorService.enableDisableContributorsExperts(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                await getList();
                                alertSuccessMessage(`${result.message}`);
                            } else {
                                alertDangerConfirmMessage(`${result.message}`);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleEdit = (item) => {
        console.log(item)
        setIsModal(!isModal);
        setIsAdd(false);
        setItem(item);
    }

    const handleDelete = async (item) => {
        setIsConfirmModal(true)
        setItem(item);
    }


    return (
        <DashboardLayout>
            <RenderPage
                id="opening-page"
                className="mt-3"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-opening"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="">
                            <Row className="align-items-center mx-0">
                            <Col xs={7} sm={7} md={4} lg={3} xl={3}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <Button
                                                className="btn custom-theme-btn custom-btn--small fs-14px br-4px"
                                                onClick={() => handleToggle()}
                                            >
                                                Add Experts
                                            </Button>
                                        </div>

                                    </div>
                                </Col>
 
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}


                            </Row>

                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="pb-3 pt-3">
                            <h1 className="h1-small section-title align-left py-2">Experts </h1>
                                {!isLoading ? (
                                    <DataTable
                                        title=""
                                        columns={TablesColumns(handleEdit, handleDelete)}
                                        data={data}
                                        pagination
                                        className="data-table"
                                        persistTableHead={false}
                                        customStyles={
                                            width > application_contants.MOBILE_BREAKPOINT
                                                ? dataTablesStyledTheme
                                                : dataTablesMobileStyledTheme
                                        }
                                    // onRowClicked={(data) => this.getHospitalItem(data)}
                                    />
                                ) : (
                                    <div className="pt-32px pb-64px d-flex justify-content-center">
                                        <Loader containerClassName="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>

            {isModal ? <AddExpert isModal={isModal} toggle={handleToggleForAcademics} isAdd={isAdd} item={item} handleRefresh={handleRefresh} /> : null}


            {isConfirmModal ? (
                <MessageConfirmation
                    isModal={isConfirmModal}
                    handleCancelBtn={handleCancelBtn}
                    handleConfirmBtn={handleConfirmBtn}
                    message={`Are you sure you want to ${item.status === -1 ? 'disable' : 'enable'} this user?`}
                />
            ) : null}
        </DashboardLayout>
    )
}
export default ExpertList;