import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import moment from "moment";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import ChallengersService from "../../../api/services/ChallengersService";

const Challenges = (props) => {
    const [width] = useWindowSize();
    const [data, setData] = useState([]);
    const [preData, setPreData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const date = moment().format('MMMM , YYYY');
    // const [date, setDate] = useState(moment ().format("D MMM, YYY"))


    React.useEffect(() => {
        window.scrollTo(0, 0);
        getChallengersList();
    }, [])
    
    const getChallengersList = async () => {
        setIsLoading(true);
        await ChallengersService.getChallengersList()
          .then(async (res) => {
            if (res.status === 200) {
              try {
                res.text().then(async (res) => {
                  let result = JSON.parse(res);
                  if (result.code === 0) {
                    if (result.data) { 
                        console.log(result.data,'result.data')
                        const upCominglists = result.data.list.filter((it) => it.status === 1 && moment().isBefore(it.date));
                        const previouslists = result.data.list.filter((it) => it.status === 1 && moment().isAfter(it.date));
                      setData([...upCominglists])
                      setPreData([...previouslists])
                      setIsLoading(false);
                    } else {
                      setData([]);
                      setPreData([]);
                      setIsLoading(false);
                    }
                  } else {
                    setData([]);
                    setPreData([]);
                    setIsLoading(false);
                  }
                });
              } catch (err) {
                console.log(err);
                setIsLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      };
    const handleNavigationChallenges = async (challengesDetail) => {
        console.log('clicked')
        props.history.push('/ChallengesDetails',{challengesDetail});
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-challenges"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-challenges"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Challenges"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/challenges" />
                <Strip
                    id="tst-strip-challenges"
                    className="strip strip-no-padding"
                    containerType="container">
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title nunitoSans-Light align-left">Challenges</h5> :
                                        <h3 className="h3-small section-title align-left nunitoSans-Light">Challenges and Workshops</h3>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                <h5 className="h5-very-small section-title align-left nunitoSans-Light fontsize-14px pb-4 pt-3">Upcoming</h5>
                                {data.length > 0 ?
                                    <Row>
                                        {data.map((challengesDetail,index) =>{
                                                return <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="challenges-card-br">
                                                       {/* <a href={`/challengers/${challengesDetail.detailTitle.toLowerCase()}`}> */}
                                                        <Row className="mb-3 mx-0">
                                                            <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card challenges-card-br-position-top'>
                                                            <div>
                                                               <p onClick = {() => handleNavigationChallenges(challengesDetail)}>
                                                                <div>
                                                                    <p className="p-large section-title nunitoSans-Regular pt-1 align-center ">{challengesDetail.title}</p>
                                                                    {/* <p className="p-small text-secondary section-title nunitoSans-Regular align-left opening-card-position-date">Date : {moment(challengesDetail.date).format('MMMM yyyy')}</p> */}
                                                                </div>
                                                                </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* </a> */}
                                                </Col>  
                                        })}
                                    </Row>
                                   : 
                                   <p className="foo p-large section-title nunitoSans-Light align-left">Coming soon...</p> }
                                   
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-3">
                                <h5 className="h5-very-small section-title align-left  nunitoSans-Light pb-4 pt-3">Previous</h5>
                                    <Row>
                                    {preData.map((challengesDetail,index) =>{
                                          return  <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="previous-challenges-card-br">
                                                {/* <a href={`/challengers/${challengesDetail.title.toLowerCase()}`}> */}
                                                    <Row className="mb-3 mx-0">
                                                        <Col xs={12} sm={12} md={11} lg={11} xl={11} className='bg-solitude-blue p-3 opening-card previous-challenges-card-br-position-top'>
                                                            <div>
                                                            <p onClick = {() => handleNavigationChallenges(challengesDetail)} className="cursor-pointer">
                                                            <div>
                                                                <p className="p-large section-title nunitoSans-Regular pt-3 align-center ">{challengesDetail.title}</p>
                                                                {/* <p className="p-small text-secondary section-title nunitoSans-Regular align-left opening-card-position-date">Date : {moment(challengesDetail.date).format('MMMM yyyy')}</p> */}
                                                            </div>
                                                            </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {/* </a> */}
                                            </Col>
                                    })}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Strip>                                        
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Challenges.defaultProps = {
    isClassName: true,
};

Challenges.propTypes = {
    isClassName: PropTypes.bool,
};

export default Challenges;