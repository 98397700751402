import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import debugLogger from '../../Utils/debugLogger';
import PublicationService from "../../../api/services/PublicationService";

const PublicationsDetail = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [place, setPlace] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');


    React.useEffect(() => {
        setData([]);
        window.scrollTo(0, 0);
        setSectionTitle(props.location.pathname.split('/')[2]);
        getList([]);
    }, []);

    const getList = async () => {
        let it = [];
        setIsLoading(true);
        await PublicationService.getPublicationList()
            .then(async (res) => {
                console.log("publications results",res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                // if (result.data) {
                                //     debugLogger(result.data);
                                //     setData([...result.data.list])
                                //     console.log("data list",...result.data.list)
                                //     setIsLoading(false);
                                if (result.data) {
                                    result.data.list.map((item) => {
                                        console.log(props.location.pathname.split('/')[2])
                                        if (item.title.toLowerCase() === props.location.pathname.split('/')[3].toLowerCase()) {
                                            it.push(item);
                                            setTitle(item.title)
                                            setDetailTitle(item.detailTitle)
                                            setPlace(item.place)
                                            setUrl(item.url)
                                            setDescription(item.description)
                                            setDate(item.date)
                                        }
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`/Publications/${title}`} />

                        <Row className="mx-3 pt-5">
                        
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto">
                            <p className="mb-0 section-title align-left">
                                    <Link to={`/Publications`} className="custom-link-item">{` Research > Publications > `}</Link>
                                    <span className="fw-600 pl-2 ">{`${title}`} </span>
                                </p>
                                {/* {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
                                    {title ? title : ''}
                                </h5> :
                                    <h2 className="h2-small section-title align-left pb-2 line-height-48px">
                                        {title ? title : ''}
                                    </h2>
                                } */}
                            </Col>
                 <Row className="mx-0 mb-4">
                    {/* {data.map((it, index) => (
                               <div key={index}> */}
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                        <h6 className="h6-small section-title nunitoSans-Bold mobile-text-position pt-4">
                          {detailTitle} </h6>
                          <p className="section-title nunitoSans-Regular mobile-text-position ">
                          {place} </p>
                          
                          <a href={`${url}`} rel="noreferrer" target="_blank" className="p- very-small section-title nunitoSans-Light custom-reference-links pl-1 align-left">{url}</a>

                          {/* <p className="section-title nunitoSans-Regular custom-reference-links mobile-text-position ">
                          {url} </p> */}
                          <p className="section-title nunitoSans-light mobile-text-position mb-4">
                          {description} </p>
                      </Col>
                      {/* </div>
                    ))} */}

                  
                  </Row>
                           </Row>
                    </Strip>
                    </div>
            </RenderPage>
        </ExternalLayout>
    )
}

export default PublicationsDetail;


