import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Marathi/male/Marathi_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Marathi/male/Marathi_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Marathi/female/Marathi_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Marathi/female/Marathi_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Marathi/male/Marathi_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Marathi/male/Marathi_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Marathi/female/Marathi_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Marathi/female/Marathi_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Marathi/male/Marathi_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Marathi/male/Marathi_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Marathi/female/Marathi_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Marathi/female/Marathi_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Marathi/male/Marathi_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Marathi/male/Marathi_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Marathi/female/Marathi_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Marathi/female/Marathi_df_2.mp3"; // Female audio


function Marathi(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Southern Konkan */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Southern Konkan </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    ग्रीनहाऊस वनस्पतीं च्या वाढत्या वातावरणावर नियंत्रण ठेवुक मदत करतत
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Konkan</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">आर.डी खात्यात ठराविक मुदतीसाठी पैशे जमा करूचे असतंत अशा खात्यांवर ठराविक व्याजदर लागू असता</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Konkan </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">कर्जावर व्याजपण आकारला जाता</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Konkan</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">आपण दैनंदिन जीवनात पैशान खरेदी विक्री करत असतव</p></td>
            </tr>
            {/* Table Dialect 2 == Northern Konkan */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Northern Konkan </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">एरोपोनिक्स रोग पसरण्यास पासून थांबवू शकतो कारण , रोग मातीच्या माध्यमातून पसरतो</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Northern Konkan</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">जुन्या भाडेकरूंसाठी अनुने पेमेंट वॉरंट काढले</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Northern Konkan </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">डैफोडिल्स च्या रोपांना नेहमी दहा किंवा त्यापेक्षा अधिक या प्रमाणात एकसाथ लावले जाते</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Northern Konkan</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">प्राकृतिक सामग्रीमा कोणताबी उत्पादन होऊ शकस , जे माणूसले रोपे , जनावरं नैते जमीनमाईन भेटतस</p></td>
            </tr>
            {/* Table Dialect 3 == Standard Marathi */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Standard Marathi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">माझे उसाचे उत्पादन खराब होण्याआधी मी ते किती काळ गोदामात साठवू शकतो ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Marathi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">काळ्या मातीत बियाणे रुजतील का ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Standard Marathi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">डेबिट कार्ड हरवल्यावर काय खबरदारी घ्यायला पाहिजे ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Marathi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">वावर नांगरणीसाठी कोणता ट्रॅक्टर वापरला जातो ?</p></td>
            </tr>
                {/* Table Dialect 4 == Varhadi */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Varhadi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">आर.टी.जी.एस कराच्या टायमाले चेक भरा लागन का ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Varhadi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">माय बचत खातं कितीक दिवसात उघडता येईन ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Varhadi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">दुधाचं फॅट कायनं कमी होते ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Varhadi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">व्यवसाय कर्ज योजना भेटेन का ?</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleMarathi = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleMarathi"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleMarathi"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleMarathi"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleMarathi" />
                    <Strip
                        id="tst-strip-SampleMarathi"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Marathi Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Marathi />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleMarathi;