import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import CSTS_Visit from "../../customComponent/GalleryComponent/CSTS_Visit_Component";
import TitleComponent from '../../customComponent/TitleComponent/TitleComponent';

const CSTS_Prof = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-CSTS-Professor-Visit"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-CSTS-Prof-Visit"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="CSTS Professor Visit"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/csts_visit" />
                    <Strip
                        id="tst-strip-CSTS Professor-Visit"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Visit to CSTS</h5> :
                                            <h3 className="h3-large section-title align-left">Visit to CSTS</h3>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <CSTS_Visit />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

CSTS_Prof.defaultProps = {
    isClassName: true,
};

CSTS_Prof.propTypes = {
    isClassName: PropTypes.bool,
};

export default CSTS_Prof;
