import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../../../constants/application_contant";
import Strip from "../../../../../customComponent/Strip/Strip";
import RenderPage from "../../../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../../../customComponent/ExternalLayout/ExternalLayout";
import { Link } from 'react-router-dom';

const Storytitle1 = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Storytitle1"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Storytitle1"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Story"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/documents/stories/Storytitle1" />
                    <Strip
                        id="tst-strip-story"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/Documents/Stories/Storytype1`} className="custom-link-item">{` Resources > Documents > Stories > Story Language >`}</Link>
                                </p>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto ">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h1 className="h1-small section-title align-left pb-2 line-height-48px">Cluster Forming... Knuckle Buckle Down</h1> :
                                            <h2 className="h2-small section-title align-left pb-2 line-height-48px">Cluster Forming... Knuckle Buckle Down</h2>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                            {/* <h6 className="h6-small section-title align-left">Cluster Forming... Knuckle Buckle Down</h6> */}
                                    
                                            <p className="mb-0 section-title align-left"> 
                                                As a step forward in changing rural and common people's lives with smart phones and local dialect 
                                                enhancements in our country through the bond of regional languages compiling technology, IISc and 
                                                its team's initiative came on board. To take a step forward in maintaining balance between regional 
                                                languages with help from artificial intelligence, technological advancements, and local dialect 
                                                enhancements. Meanwhile, I also got the opportunity to fly on the same plane as the IISc Respin 
                                                and Syspin teams. At my end of responsibility, I got the cotton candy task of collecting contacts 
                                                and forming cluster groups of individuals on the basis of districts and statewide according to 
                                                their native languages. I assumed it was a cotton candy task, but my bubble candy vanished very 
                                                soon. I received a flood of contact lists the day I began searching for available contacts on 
                                                the internet, from number down websites to people creeping online platforms,  from college 
                                                universities to bank websites, and this was enough to add a breath to my bubble. But now the 
                                                real hunt starts. I had an ample number of wires in the form of email ids, postal addresses, 
                                                and phone numbers. And this was the moment where my candy bubble quacked with the very first 
                                                call when I tried to reach the very first contact number on my balloon. I'll tell you about 
                                                some of my experiences. To begin with, every second number on my so-called bucket list was 
                                                unreachable or had to be in some unknown location with no access to network companies. But, 
                                                as I have to prove that it is a cotton candy task, the numbers , the opportunity to contact 
                                                now to deal with them make me think it couldn't be a bigger task, but the very first caller 
                                                responded to me as if I were persuading him to commit fraud or as if I were asking him to make 
                                                me a nominee of his wealth and properties. Ahh, but as it was the first, there would be many 
                                                more to come. I noticed a lot of trust issues; they couldn't believe that these kinds of tasks 
                                                existed or that they could be done as work-from-home tasks using smart phones. The online mode 
                                                of dealing with people even got wretched at times; they disrespected us using ugly tones on 
                                                calls and emails. We got very few responses, but after overcoming months of struggles, our 
                                                contact collectors and I were able to collect some drops from the bucket list. The main task 
                                                now is to familiarize participants with tasks, project how to do them, and grasp things to 
                                                guide them, especially in regional languages. Some of the participants were enthusiastic, 
                                                but a few were technically challenged, lacked resources, including smart phones, and lacked 
                                                connectivity. This was a huge hurdle in the phase of completing tasks, and to overcome these 
                                                problems, we came up with solutions like manual mode. I mean, use a paper pen, send us written 
                                                statements, pictures, and so on. I guess at least everyone has access to a paper pen nowadays. 
                                                With this mode, we were at least able to get a bowl from the bucket, moving one step ahead to 
                                                complete the challenge ladder. </p>

                                                {/*
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <p className="p-very-small section-title align-right">Hello World!</p> :
                                                    <p className="p-small section-title align-right">Hello world!</p>}
                                                </Col>
                                                */}
                                                

                                               </Col>

                                        {/*   */}                                               
                                        </Row>
                                           
                                            
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Storytitle1.defaultProps = {
    isClassName: true,
};

Storytitle1.propTypes = {
    isClassName: PropTypes.bool,
};

export default Storytitle1;
