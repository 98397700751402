import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";


const Photos = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Gallery"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Gallery"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Gallery"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/Gallery" />
                    <Strip
                        id="tst-strip-Gallery"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Gallery</h5> :
                                            <h3 className="h3-large section-title align-left">Gallery</h3>}
                                    </Col>
                                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <GalleryComponent />
                                    </Col> */}
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                    
                                        
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/visit_by_gates_foundation`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/bmgfvisitgall.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Visit by Gates Foundation</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/visit_to_csts`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/visitcsts.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Visit to CSTS</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/field_trip_for_data_collection`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/nagaurtrip.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Nagaur Field Trip</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/slt_hackathon`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/slthack.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">SLT Hackathon</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/bengali_field_trip`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/bengalitrip.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Bengali Field Trip</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/respin_team`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/team.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">RESPIN Team</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/team_dec_outing`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/decemberout.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Team Outing in December </p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>

                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/makaut_pics`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/makautdatatrip.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">MAKAUT Data Collections</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/cstsmeetng.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">CSTS Meeting</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/makautmeet.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">MAKAUT Meeting</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/teamouting.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Team Get-together Event</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/bmgf23meeting.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">BMGF's Visit in 2023</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/asru23conf.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">ASRU'23 Conference</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>

	    <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/makaut_pics`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/surguja.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Data collection from Surguja</p>
                                                    </div>
                                                {/* <div className="align-right pb-2">
                                                <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>

	     <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/raigarhrecpics.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Raigarh Recording Photos</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={width === 1024 ? 6 : 4} xl={width === 1024 ? 6 : 4} key="abc" className="photos-card-br">
                                            {/* <a href={`/areas/${item.reseachTitle.toLowerCase()}`}> */}
                                            {/* <a href={`/gallery/miscellaneous`}> */}
                                            <a href={"https://ee.iisc.ac.in/~prasantg/resgall/miscellaneous.php"} rel="noreferrer" target="_blank">
                                            <Row className="mb-4 mx-1">
                                                <Col xs={12} sm={12} md={12} lg={6} xl={11} className="photosbox-bg opening-card photos-card-br-position-to">
                                                    <div>
                                                        <p className="p-large nunitoSans-Regular align-left pl-2 pt-3 opening-card-position">Miscellaneous</p>
                                                    </div>
                                                    {/* <div className="align-right pb-2">
                                                    <img src={application_contants.right2} alt="" className="right-icon--width" />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                            </a>
                                        </Col>

                                        </Row>
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Photos.defaultProps = {
    isClassName: true,
};

Photos.propTypes = {
    isClassName: PropTypes.bool,
};

export default Photos;
