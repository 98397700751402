import React from "react";
import ExternalHeader from "../ExternalHeader/ExternalHeader";
import Footer from "../Footer/Footer";
import BodyWrapper from "./BodyWrapper";

const ExternalLayout = ( props ) => {
  return (
    <BodyWrapper>
     <ExternalHeader/>
      <div>
        {props.children}
      </div>
      <Footer/>
    </BodyWrapper>
  );
};

export default ExternalLayout;
