import application_contants from "../../constants/application_contant";

const SLTH_Data = [
    
        application_contants.sltHackImg1,application_contants.sltHackImg2,application_contants.sltHackImg3,application_contants.sltHackImg4,application_contants.sltHackImg5,application_contants.sltHackImg6,application_contants.sltHackImg7,
        application_contants.sltHackImg8,application_contants.sltHackImg9,application_contants.sltHackImg10,application_contants.sltHackImg11,application_contants.sltHackImg12,application_contants.sltHackImg13,application_contants.sltHackImg14,
        application_contants.sltHackImg15,application_contants.sltHackImg16,

];


export default SLTH_Data;