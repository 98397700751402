import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import application_contants from "../../constants/application_contant";
import MegaMenuData from "../../Utils/Json/MegaMenuData";
import { HashLink } from 'react-router-hash-link';
const ExternalHeader = (props) => {
    const [width] = useWindowSize();
    const [isToggle, setIsToggle] = useState(false);
    const [isMegaMenu, setIsMegaMenu] = useState(true);
    const [isAnimate, setIsAnimate] = useState(false);

    const controlHeader = () => {
        if(window.scrollY > 100) {
            setIsAnimate(false)
        }
        else{
            setIsAnimate(true)
        }
    }

    const handleToggle = () => {
        setIsToggle(!isToggle);
    };
    const handleLink = async (link, name) => {
        props.history.push(`${link}`);
        setIsToggle(!isToggle);
    }
    const handleMegaMenuLink = async () => {
        setIsMegaMenu(!isMegaMenu)
        setIsAnimate(!isAnimate);
    }
    const handleOnMouseLeave = () => {
        setIsMegaMenu(!isMegaMenu)
        setIsAnimate(!isAnimate);
    }
    let menuref= useRef()
    React.useEffect (() => {
        let handler = (event) => {
            if(!menuref.current.contains(event.target)){
                setIsAnimate(isAnimate);

            }
        }
        // window.addEventListener('scroll',
        // controlHeader)

        document.addEventListener("mousedown",handler)
        return () => {
            document.removeEventListener('mousedown',handler)
            window.removeEventListener('scroll',
            controlHeader)
            setIsAnimate()
        }
 
        
    },[])
    return (
        <div className=''>
        <div className={`header-container ` }>
            {width >= application_contants.MOBILE_BREAKPOINT ?
                <div className="d-flex justify-content-between menu-container">
                    <div className="d-flex align-items-center mt-2 ml-2 position">
                        <div>
                            <Link to={'/'}>
                                <img src={application_contants.logoImg} alt="" className="cursor-pointer logo-icon" />
                            </Link>
                        </div>
                    </div>
                    <div onClick={() => handleMegaMenuLink()}>
                        <img src={application_contants.menuImg} alt="" className="cursor-pointer menu-icon " />
                    </div>
                    {/* <div className="d-flex align-items-end"> */}
                        {/* {MegaMenuData.map(({ id, selected, link, name, isLink }) => (
                            <div className="custom-link--position" key={`menu-${id}`}>
                                <div key={id} onMouseOver={() => handleOnMouseEnter()} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                    className={`custom-link ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                    {name}
                                </div>
                            </div>
                        ))} */}
                    {/* </div> */}
                    {/* {true ? */}
                    <div ref={menuref} className={`mega-menu-container d-flex ${isAnimate  ? `animate__animated animate__fadeInDown mega-menu--show` : 'animate__animated animate__fadeInUp mega-menu--hide'} ` } >
                        <div>
                            <div>
                                <Link to={'/'}>
                                    <img src={application_contants.whiteLogoImg} alt="" className="cursor-pointer mega-menu-logo" />
                                </Link>
                            </div>
                            <div className="pt-3 pr-5">
                            <Link to={'/'}>
                                <p className="cursor-pointer p-large section-title nunitoSans-black title-white align-left ">REcognizing <br /> SPeech in <br /> INdian languages</p>
                            </Link>
                            </div>
                        </div>
                        <div  className="d-flex pl-5 pt-2">
                            {MegaMenuData.map(({ id, selected, link, name, isLink, menu,isHashlink }) => (
                                <div className="mega-menu-link--position" key={`menu-${id}`}>
                                    {isLink ?
                                        <div>
                                            <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                {name}
                                            </div>
                                            {menu.length > 0 && menu.map((it, index) => (
                                                <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                    className={`mega-menu-link  ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                                    {it.name}
                                                </div>
                                            ))}
                                        </div> : 
                                            <div>
                                            <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                {name}
                                            </div>
          {/* ============================= <div key={`menu-13`} onClick={() => window.location.href = link} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                {name}
                                            </div> ============================= */}
                                            {menu.length > 0 && menu.map((it, index) => (
                                                // <div onClick={() => handleLink(it.link, it.name)} key={index}>
                                                //     {it.name}
                                                // </div>
                                                <HashLink smooth to={it.link}   scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                className={`mega-menu-link  ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>{it.name}</HashLink>
                                            ))}
                                        </div> 
                                        
                                        // <HashLink smooth to="/team#teamMember"   scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
                                        // >Link to Hash Fragment</HashLink>
                                        // <div>
                                        //     <div key={id} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                        //         className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                        //         {name}
                                        //     </div>
                                        //     {menu.length > 0 && menu.map((it, index) => (
                                        //         <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                        //             className={`mega-menu-link ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                        //             {it.name}
                                        //         </div>
                                        //     ))}
                                        // </div>
                                    }
                                
                                </div>
                            ))}
                        </div>
                        <div className="close-icon--container pt-1">
                            <div onClick={() => handleOnMouseLeave()}>
                            <img src={application_contants.timesImg} alt="" className="cursor-pointer close-icon" />

                           </div>
    
                        </div>
                    </div>
                        {/* : null} */}
                </div>
                :
                <div ref={menuref} className="pt-3 d-flex justify-content-between full-width align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <div>
                                <Link to={'/'}>
                                    <img src={application_contants.logoImg} alt="" className=" cursor-pointer iisc-logo--width" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => handleToggle()}>
                        <img src={application_contants.menuImg} alt="" className="cursor-pointer menu-icon" />
                    </div>
                    {isToggle ?
                        // <div className="mobile-header">
                        //     <div onClick={() => handleToggle()} className="d-flex align-items-center justify-content-end pb-4">
                        //         <img src={application_contants.closeImg} alt="" className="close-icon" />
                        //     </div>
                        //     {MegaMenuData.map(({ id, selected, link, name }) => (
                        //         <div className="custom-link--mobile-position" key={`menu-${id}`}>
                        //             <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                        //                 className={`custom-link ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `custom-link-active` : ''}`}>
                        //                 {name}
                        //             </div>
                        //         </div>
                        //     ))}
                        // </div>
                        <div className="mega-menu-container">
                            <div>
                                <div>
                                    <Link to={'/'}>
                                        <img src={application_contants.whiteLogoImg} alt="" className="cursor-pointer mega-menu-logo" />
                                    </Link>
                                </div>
                                <Link to={'/'}>
                                <div className="pt-3">
                                    <p className="p-large section-title title-white nunitoSans-Bold align-left fw-800">REcognizing SPeech in INdian languages</p>
                                </div>
                                </Link>
                            </div>
                            <div className="pt-3 pb-3">
                                {MegaMenuData.map(({ id, selected, link, name, isLink, menu,isHashlink }) => (
                                    <div className="mega-menu-link--position" key={`menu-${id}`}>
                                        {isLink ?
                                            <div className="pb-2">
                                                <div key={id} onClick={() => handleLink(link, name)} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                    className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                    {name}
                                                </div>
                                                {menu.length > 0 && menu.map((it, index) => (
                                                    <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link pl-5 ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                                        {it.name}
                                                    </div>
                                                ))}
                                            </div> 
                                            :
                                            // null
                                            <div className="pb-2">
                                                <div key={id} selected={props.location.pathname.split('/')[1] === link.split('/')[1] ? true : false}
                                                    className={`mega-menu-link mega-menu-title ${props.location.pathname.split('/')[1] === link.split('/')[1] ? `mega-menu-link-active` : ''}`}>
                                                    {name}
                                                </div>
                                                {/* {menu.length > 0 && menu.map((it, index) => (
                                                    <div onClick={() => handleLink(it.link, it.name)} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                        className={`mega-menu-link pl-5 ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>
                                                        {it.name}
                                                    </div>
                                                ))} */}
                                                {menu.length > 0 && menu.map((it, index) => (
                                                // <div onClick={() => handleLink(it.link, it.name)} key={index}>
                                                //     {it.name}
                                                // </div>
                                                <HashLink smooth to={it.link}   scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} selected={props.location.pathname.split('/')[1] === it.link.split('/')[1] ? true : false}
                                                className={`mega-menu-link pl-5 ${props.location.pathname.split('/')[1] === it.link.split('/')[1] ? `mega-menu-link-active` : ''}`} key={index}>{it.name}</HashLink>
                                            ))}
                                            </div>
                                        }
                                      
                                    </div>
                                ))}
                               
                            </div>
                            <div className="close-icon--container">
                                <div onClick={() => handleToggle()}>
                                    <img src={application_contants.timesImg} alt="" className="cursor-pointer close-icon" />
                                </div>
                            </div>
                        </div>
                        : null}
                </div>}
        </div>
        </div>
    )
}
export default withRouter(ExternalHeader);
