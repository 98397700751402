import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../constants/application_contant";
import Strip from "../../../customComponent/Strip/Strip";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import { Link } from 'react-router-dom';


const Books = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Books"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Books"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Books"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/Books" />
                    <Strip
                        id="tst-strip-Books"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                           <Col xs={11} sm={11} md={11} lg={11} xl={12} className="m-auto">
                               {width <= application_contants.MOBILE_BREAKPOINT ? <h6 className="h6-small nunitoSans-SemiBold pt-5 section-title align-left ">
                                <Link to={`/Documents/`} className="pl-0 nunitoSans-Light custom-link-item">{`Resources > Documents > Books -`}</Link>
                                    <span className="foo pl-1  section-title line-height-48px nunitoSans-Light ">Coming Soon</span>
                                </h6> :
                       
                                <h6 className="h6-medium nunitoSans-SemiBold pt-5 section-title align-left ">
                                <Link to={`/Documents/`} className="pl-0 nunitoSans-Light custom-link-item">{`Resources > Documents > Books -`}</Link>
                                    <span className="foo pl-1  section-title line-height-48px nunitoSans-Light ">Coming Soon</span>
                                </h6>
                                }
                            </Col>
                                 

                                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                            {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Books</h5> :
                                            <h3 className="h3-large section-title align-left">Books</h3>}
                                        </Col>
                                            <h5 className="h5-small nunitoSans-SemiBold mb-0 pl-1 pr-2 section-title align-left">
                                                <span className="pl-1 line-height-48px section-title nunitoSans-Light color-nero-grey text-capitalize"> Coming Soon .....</span>
                                            </h5 >
                                    </Row>
                                </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Books.defaultProps = {
    isClassName: true,
};

Books.propTypes = {
    isClassName: PropTypes.bool,
};

export default Books;
