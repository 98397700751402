import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import application_contants from '../../constants/application_contant';
import Strip from '../../customComponent/Strip/Strip';
import ToolsService from '../../../api/services/ToolsService';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import DatasetsService from '../../../api/services/DatasetsService';
import PropTypes from "prop-types";
import SEOComponent from '../../customComponent/SEO/SEOComponent';

const DemoDatasets = (props) => {
        const [width] = useWindowSize();
    
        React.useEffect(() => {
            window.scrollTo(0, 0);
        }, [])
    
        return (
            <ExternalLayout>
                <RenderPage
                    id="tst-Datasets"
                    className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                    containerType="container-fluid"
                >
                    <Strip
                        id="tst-Datasets"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title="Datasets"
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                            siteUrl="/Datasets" />
                        <Strip
                            id="tst-strip-Datasets"
                            className="strip strip-no-padding"
                            containerType="container">
                            <Row className="mx-0">
                                <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                            {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title jostRegular title-navy-blue pb-4 align-left">Datasets</h5> :
                                        <h3 className="h5-medium section-title title-navy-blue jostRegular pb-4 align-left">Datasets</h3>}
                                        </Col>
                                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                            <DatasetsComponent />
                                        </Col> */}
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        
                                            
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={width === 1024 ? 5 : 4} xl={6} key="abc" className="datasets-card-br pb-4">
                                                <a href={`/AudioDatasets/`} rel="noreferrer" >
                                                <Row className="mb-3 mx-0">
                                                    <Col xs={12} sm={12} md={11} lg={8} xl={8} className='bg-solitude-blue p-3 datasets-card datasets-info-container '>
                                                        <div>
                                                            <p className="p-large section-title jostRegular pl-3 title-black align-left  datasets-card-position">Transcribed Audio Data</p>
                                                        <div className="align-right pb-2">
                                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                        </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                </a>
                                            </Col><Col xs={12} sm={12} md={12} lg={width === 1024 ? 5 : 4} xl={6}key="abc" className="datasets-card-br pb-4">
                                                <a href={`/TextDatasets/`} rel="noreferrer" >
                                                <Row className="mb-3 mx-0">
                                                    <Col xs={12} sm={12} md={11} lg={8} xl={8} className='bg-solitude-blue p-3 datasets-card datasets-info-container '>
                                                        <div>
                                                            <p className="p-large section-title jostRegular pl-3 title-black align-left  datasets-card-position">Text Data</p>
                                                        <div className="align-right pb-2">
                                                                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                                                                        </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                </a>
                                            </Col>
    
                                            </Row>
                                            
                                        </Col>
    
                                    </Row>
                                </Col>
                            </Row>
                        </Strip>
                    </Strip>
                </RenderPage>
            </ExternalLayout>
        );
    };

DemoDatasets.defaultProps = {
    isClassName: true,
};

DemoDatasets.propTypes = {
    isClassName: PropTypes.bool,
};

export default DemoDatasets;