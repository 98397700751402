import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
    FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import ChallengersService  from "../../../api/services/ChallengersService"
import debugLogger from '../../Utils/debugLogger';
import ImageViewer from '../../customComponent/ImageViewer/ImageViewer';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from "moment";


const ChallengesDetails = (props) => {
    const {challengesDetail} = props.location.state;
    console.log('ChallengesDetail : ',challengesDetail);

    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const [gallerImgList, setGalleryImgList] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [imageViewerList, setImageViewerList] = useState([]);
    const [substacks,setSubstacks] = useState([]);
    const [description,setDescription] = useState('');
    const [url,setUrl] = useState('');
    const [fromDate,setFromDate] = useState('');
    const [toDate,setToDate] = useState('');
    const [tableInfo,setTableInfo] = useState('');
    const [isLoading, setIsLoading] = useState(false); 
    // const date = moment().format('DD MMMM , YYYY');
   

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setSubstacks(challengesDetail.keyTakeAways ? JSON.parse(challengesDetail.keyTakeAways) : [])
        setTableInfo(challengesDetail.websiteSource ? JSON.parse(challengesDetail.websiteSource) : [])
        setGalleryImgList(challengesDetail.imagesUrl ? JSON.parse(challengesDetail.imagesUrl) : [])
    }, []);


    const handleToggle = () => {
        setIsModal(!isModal);
    }

    const handleToggleIndex = (index) => {
        const list = [...gallerImgList];
        console.log("list",list)
        list.splice(0, 0, list.splice(index, 1)[0]);
        setImageViewerList([...list]);
        setIsModal(!isModal);
    }
    const rowData = [
        {make: "Toyota", model: "Celica", price: 35000},
        {make: "Ford", model: "Mondeo", price: 32000},
        {make: "Porsche", model: "Boxter", price: 72000}
    ];
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-opening"
                className={`render px-0 render-page`}
                containerType="container-fluid"
            >
                <div>
                    <Strip
                        id="tst-opening"
                        className="strip strip-no-padding"
                        containerType="container"
                    >
                        <SEOComponent
                            title={sectionTitle}
                            description={sectionTitle}
                            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                            siteUrl={`//${sectionTitle}`} />
                        <Row className="mx-0 pt-5">
                            
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto">
                            <p className="mb-0 section-title align-left">
                                    <Link to={`/challenges`} className="custom-link-item">{` Resources > Challenges >`}</Link>
                                    <span className="fw-600 pl-2 ">{`${challengesDetail.title}`} </span>
                                </p>
                                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px pt-4 align-left">
                                    {challengesDetail.detailTitle ? challengesDetail.detailTitle : ''}
                                </h5> :
                                    <h2 className="h2-small section-title align-left pb-2 line-height-48px">
                                        {challengesDetail.detailTitle ? challengesDetail.detailTitle : ''}
                                    </h2>
                                }
                            </Col>
                            <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                <Row>
                                    <Col xs={12} ms={12} md={12} lg={11} xl={11}>
                                            <div >
                                                {/* {data.description === null && data.publications === null ? */}
                                                <div>
                                                <Row className="pb-4">
                                                    <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                    {challengesDetail.fromDate && challengesDetail.toDate ?
                                                    <p className="mb-0 section-title align-left">Date : {moment(challengesDetail.fromDate).format('DD')} - {moment(challengesDetail.toDate).format('DD MMMM yyyy')}</p>     
                                                    : null }

                                                        <p className="mb-0 section-title align-left">{challengesDetail.description}</p>
                                                        {challengesDetail.url ?
                                                            <p>For more details, <a href={`${challengesDetail.url}`} rel="noreferrer" target="_blank" className="p-small section-title nunitoSans-Light custom-reference-links align-left">
                                                        Click here</a>
                                                        </p>
                                                        : null }
                                                    </Col>
                                                </Row>
                                                <Row className ="pb-4">
                                                        {substacks.length > 0 && substacks.map((item, index) => (
                                                           <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                            <Row key={index} className="mx-0">
                                                            <h6 className="h6-small mb-0  align-left ">{`${item.substackName}:   `}  </h6>
                                                                <p className="mb-0 section-title align-left ml-2">{item.substackTitle}</p>
                                                            </Row>
                                                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                                                <div className="d-flex">
                                                                    <FiberManualRecord className="dot-icon" />
                                                                    <div>
                                                                        <p className="mb-0 section-title align-left pb-4">{item.substackDescription}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            </Col>    
                                                        ))}
                                                 </Row>
                                                 <Row className ="pb-4">
                                                        {gallerImgList.length > 0 && gallerImgList.map((item, index) => (
                                                           <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                           <Row key={index} className="mx-0">
                                                                 <div className="bg-zircon-grey challenges-card-img-container cursor-pointer">
                                                                <img src={`${ApiConfig.url}${item.imgPath}`} alt="" className="img-item"/>
                                                                </div>
                                                                <p className="mb-0 section-title align-left">{item.imgTitle}</p>
                                                            </Row>
                                                            
                                                            </Col>    
                                                        ))}
                                                 </Row> 
                                                 <Col xs={12} ms={12} md={12} lg={12} xl={12}>
                                                 
                                            {/*    <h4 className="h4-small table-heading">Expert talk speaker</h4>  */} 
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center row-header-width">S.No</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width">Speaker</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width" >Organization</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width">Topic</div>
                                                        </td>
                                                        </tr> */ }
                                                    {tableInfo.length >0 && tableInfo.map((item,index) => (
                                                        
                                                    <tr className="column_design bg-white ">
                                                   
                                                        <td className="align-center single_column_design">
                                                        <div >{item.slno}</div>
                                                        </td>
                                                         
                                                        <td className="single_column_design">
                                                        <div >{item.speaker}</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div >{item.organization}</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div >{item.topic}</div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </table>  
                                                
                                                
                                                </Col>  
                                               
                                                                                         
                                                </div>
                                                {/* : null} */}
                                            </div>

                                    </Col>
                                </Row>
                            </Col>
                           </Row>
                    </Strip>
                    </div>
                    {/* <SocialIcon /> */}
                    {isModal ?
                    <ImageViewer list={imageViewerList} handleToggle={handleToggle} isModal={isModal} />
                    : null}
            </RenderPage>
        </ExternalLayout>
    )
}

export default ChallengesDetails;


const PointersItem = (props) => {
    return (
        <div>
            {props.item && props.item.pointersPoints ?
                <Row className="mx-0">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="px-0"><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Pointers</h5></Col>
                    <Row>
                        <Col xs={12} ms={12} md={12} lg={12} xl={12}><p className="mb-0 section-title align-left pb-3">{props.item.pointersDescription}{props.item.pointersDescription ? ':' : ''}</p></Col>
                        {props.item.pointersPoints.split(/[\n]/g).map((infoItem, index) => (
                            <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                                <div className="d-flex">
                                    <FiberManualRecord className="dot-icon" />
                                    <div>
                                        <p className="mb-0 section-title align-left">{infoItem}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Row> : null}
        </div>

    )
}

const DownloadLinks = (props) => {
    return (
        <div>
            {props.item && props.item.downloadLinks ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Download Links</h5></Col>
                    {props.item.downloadLinks.split(/[\n]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                            <a href="#" rel="noreferrer" target="_blank" className="p-large media-section-title nunitoSans-Regular align-left media-card-position text-underline">{it}</a>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}

const PreferredQualification = (props) => {
    return (
        <div>
            {props.item && props.item.preferredPoints ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small section-title nunitoSans-SemiBold fw-600 align-left pb-1">Preferred Qualification</h5></Col>
                    {props.item.preferredPoints.split(/[\n\r]/g).map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                <FiberManualRecord className="dot-icon" />
                                <div>
                                    <p className="mb-0 section-title align-left">{it}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                : null}
        </div>
    )
}

const Duration = (props) => {
    return (
        <div>
            {props.item.duration.length > 0 ?
                <Row>
                    <Col xs={12} ms={12} md={12} lg={12} xl={12}><h5 className="h5-small  section-title nunitoSans-SemiBold fw-600 align-left pb-1">Duration</h5></Col>
                    {props.item.duration.map((it, index) => (
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} key={index}>
                            <div className="d-flex">
                                {/* <FiberManualRecord className="dot-icon" /> */}
                                <div>
                                    <p className="mb-0 section-title align-left">{it.param}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row> : null}
        </div>
    )
}