import React from "react";
import PropTypes from "prop-types";
import {
  useWindowSize
} from '@react-hook/window-size'
import { Col, Input, Row } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import ContactUsMap from "../../customComponent/ContactUsMap/ContactUsMap";
import ContactUsForm from "../../customComponent/ContactUsForm/ContactUsForm";
import SocialIcon from "../../customComponent/SocialIcon/SocialIcon";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import ContactComponent from "../../customComponent/ContactComponent/ContactComponent";

const ContactUs = (props) => {
  const [width] = useWindowSize();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <ExternalLayout>
      <RenderPage
        id="tst-landing"
        className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
        containerType="container-fluid"
      >
        <Strip
          id="tst-ContactUs"
          className="strip strip-no-padding"
          containerType="container"
        >
          <SEOComponent
            title="ContactUs"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
            siteUrl="/contact-us" />
          <Row className="pt-5 mx-0">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5">
                  {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Contact Us</h5> :
                    <h1 className="h1-small section-title align-left">Contact Us</h1>}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ContactComponent/>
                </Col>
                {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5">
                  <SocialIcon />
                </Col> */}
              </Row>
            </Col>

          </Row>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};

ContactUs.defaultProps = {
  isClassName: true,
};

ContactUs.propTypes = {
  isClassName: PropTypes.bool,
};


export default ContactUs;
