import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Magadhi/male/Magadhi_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Magadhi/male/Magadhi_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Magadhi/female/Magadhi_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Magadhi/female/Magadhi_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Magadhi/male/Magadhi_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Magadhi/male/Magadhi_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Magadhi/female/Magadhi_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Magadhi/female/Magadhi_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Magadhi/male/Magadhi_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Magadhi/male/Magadhi_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Magadhi/female/Magadhi_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Magadhi/female/Magadhi_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Magadhi/male/Magadhi_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Magadhi/male/Magadhi_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Magadhi/female/Magadhi_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Magadhi/female/Magadhi_df_2.mp3"; // Female audio


function Magadhi(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Standard Magadhi */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Standard Magadhi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    का डिमांड लोन सुरक्षित लोन हई ?
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Magadhi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">लेम्बो सालो भर होवे वाला फसल हइ</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Standard Magadhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">मेक इन इंडिया के योजना के आवे से भारतीय उत्पाद के बिक्री बढ़लई हई</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Standard Magadhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">राष्ट्र के आवश्यकता के पूर्ति लगी वित्त सबसे महत्वपूर्ण साधन हइ</p></td>
            </tr>
            {/* Table Dialect 2 == Southern Magadhi */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Magadhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">फल वृक्ष परागण के अंतर्गत सेब , चेरी आर बेर आवो हय</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Magadhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">पौधा लगी पोषक वृहत तत्व जैसे कैल्सियम , मैग्नीशियम औरो गंधक जरुरी होबो हइ</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Southern Magadhi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">दूध वला मशीन दूध के बाहरी प्रदूषण से सुरक्षित रखो हइ</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Magadhi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">टैक्स के भुगतान करके टैक्स के चोरी से बचल जा सको हय</p></td>
            </tr>
            {/* Table Dialect 3 == Western Magadhi */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Western Magadhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">राम यू.पी.आई से पइसा भेजे के लेल ओ.टी.पी डाललई</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Western Magadhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">राष्ट्रीय पेंशन प्रणाली में खाता शुरू करे के प्रक्रिया आसान हई</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Western Magadhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">प्रधानमंत्री किसान जोजना में सभ किसान परिवार के सहायता राशि आऽ बीमा प्रदान कएल गेलई ह</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Western Magadhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">सुखार माहिन्ना हफ्ता चाहे सालों भर रहके हम्मर स के जीवन के बर्बाद कर देई छई</p></td>
            </tr>
                {/* Table Dialect 4 == Surjapuri */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Surjapuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">भीम एपोक भारतोत मुख्य रूप से चलाल जाहा</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Surjapuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">किसानी लोन लम्बा अवधिर तने बहुत कम ब्याजेर दर पर दीयाल जा छे</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Surjapuri </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">मोर मामु कपरार खुदरा बिक्री कर छेक</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Surjapuri</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">पटवार फसल गर्मी आर नमी वाला माटीत बहुत बढ़िया हछेक</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleMagadhi = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleMagadhi"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleMagadhi"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleMagadhi"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleMagadhi" />
                    <Strip
                        id="tst-strip-SampleMagadhi"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Magadhi Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Magadhi />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleMagadhi;