import React,{useState} from 'react';
import { Col, Row } from 'reactstrap';
import debugLogger from '../../Utils/debugLogger';
import MediaService from '../../../api/services/MediaService';


const MediaComponent = (props) => {
    const [media, setMedia] = useState([]);
    const [data, setData] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, []);

    const getList = async () => {
        let it = [];
        await MediaService.getMediaList()
            .then(async (res) => {
                console.log(res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    result.data.list.map((item) => {
                                        if (item.title === props.data) {
                                            console.log("utem",item)
                                            let mediaDetail = JSON.parse(item.mediaList);
                                            console.log("mediaDetail",mediaDetail)
                                            setMedia(mediaDetail)
                                            
                                        }
                                    });
                                    debugLogger(it);
                                    //debugLogger(result.data);
                                    setData([...it])
                                    //setIsLoading(false);
                                } else {
                                    setData([]);
                                    // setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                // setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        //setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false);
            });
    }

    return (
        <Row className="mx-0 ">
            {media.length > 0 && media.map((item, index) => {
                return <Col xs={12} sm={12} md={12} lg={12} xl={12}  className="px-0">
                    <Row key={index} className=" mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                            <a href={`${item.mediaUrl}`} rel="noreferrer" target="_blank" className="p-large media-section-title nunitoSans-Regular align-left media-card-position text-underline">
                                {item.mediaName}</a>
                            <p className="p-lesserthan-small nunitoSans-Regular align-left media-card-position pt-1">{item.date}</p>
                        </Col>
                    </Row>
                </Col>
            })}
        </Row>
    )
};


export default MediaComponent;
