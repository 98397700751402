import React, { useState } from "react";
var script;
class ContactUsMap extends React.Component {  
    getGoogleMaps() {
        if (!this.googleMapsPromise) {
            this.googleMapsPromise = new Promise((resolve) => {
                // Add a global handler for when the
                //API finishes loading
                window.resolveGoogleMapsPromise = () => {
                    // Resolve the promise
                    resolve(window.google);
                    // Tidy up
                    delete window.resolveGoogleMapsPromise;
                };
                // Load the Google Maps API
                script = document.createElement("script");
                const API = 'AIzaSyDoAXngmYoYJDzPyzW13GBJEDx6BSQ4O88';
                script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
                script.async = true;
                document.body.appendChild(script);
            });
        }
        // Return a promise for the Google Maps API
        return this.googleMapsPromise;
    }
    componentWillMount() {
        // Start Google Maps API loading since we know we'll soon need it
        this.getGoogleMaps();
        document.body.removeChild(script);
    }
    componentDidMount() {
        // Once the Google Maps API has finished loading, initialize the map
        this.getGoogleMaps().then((google) => {
            const location = { lat: 13.0149413, lng: 77.5613545 };
            const map = new google.maps.Map(document.getElementById('map'), {
                zoom: 9,
                center: location
            });
            const marker = new google.maps.Marker({
                position: location,
                map: map
            });
        });
    }
    render() {
        return (
            <div>
                <div id="map" style={{ width: '100%', height: '52vh' }}></div>
            </div>
        )
    }
}
export default ContactUsMap