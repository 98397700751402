import application_contants from "../../constants/application_contant";

const GalleryData = [
    
         application_contants.galleryImg1,
         application_contants.galleryImg2,
         application_contants.galleryImg3,
         application_contants.galleryImg4,
         application_contants.galleryImg5,
         application_contants.galleryImg6,
         application_contants.galleryImg7,
         application_contants.galleryImg8,
         application_contants.galleryImg9,
        //  application_contants.galleryImg10,
        //  application_contants.galleryImg11,
        //  application_contants.galleryImg12,
        //  application_contants.galleryImg13,
        //  application_contants.galleryImg14,
        //  application_contants.galleryImg15,
        //  application_contants.galleryImg16,
        //  application_contants.galleryImg17,
        //  application_contants.galleryImg18,
        //  application_contants.galleryImg19,
        //  application_contants.galleryImg20,
        //  application_contants.galleryImg21,
        //  application_contants.galleryImg22,
        //  application_contants.galleryImg23,
        //  application_contants.galleryImg24,
        //  application_contants.galleryImg25,
        //  application_contants.galleryImg26,
        //  application_contants.galleryImg27,
        //  application_contants.galleryImg28,
        //  application_contants.galleryImg29,
        //  application_contants.galleryImg30,
        //  application_contants.galleryImg31,
        //  application_contants.galleryImg32,
        //  application_contants.galleryImg33,
        //  application_contants.galleryImg34,
        //  application_contants.galleryImg35,
        //  application_contants.galleryImg36
];


export default GalleryData;