import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";

const Resources = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-research"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Resources"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Resources"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/resources" />
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={9} xl={9} className="m-auto px-0">
                            <p>Resources</p>
                        </Col>

                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Resources.defaultProps = {
    isClassName: true,
};

Resources.propTypes = {
    isClassName: PropTypes.bool,
};

export default Resources;
