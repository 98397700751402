import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from "@react-hook/window-size";
import application_contants from '../../constants/application_contant';
import HomeVideoData from '../../Utils/Json/HomeVideoData';
import ReactPlayer from 'react-player'

const SingleGridVideo = () => {
    const [width] = useWindowSize();
    const [data, setData] = useState(HomeVideoData)
    
    return (
        <Row className="mx-0 ">
            {data.length > 0 && data.map((item, index) => (
                <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} >
                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left nunitoSans-Regular ">{item.sectionTitle}</h5> :
                     <h3 className="h5-medium nunitoSans-Regular section-title align-left pb-3">{item.sectionTitle}</h3>}
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={5} xl={5} className="pt-2">
                        <div className="position-relative pb-4 container_video">
                    {/*   <ReactPlayer 
                        controls="true"
                        width="100%"
                        height="340px"
                        playIcon={application_contants.PlayIcon}
                         url={application_contants.video}/>
                              <img src={item.img} alt=""/>
                            <div className="play-icon-container">
                                <img src={application_contants.PlayIcon} alt=""/>
                            </div> */}

                            {/* */}
                        <iframe width="100%" height="100%" 
                        src="https://www.youtube.com/embed/oy3yHBerRTU" 
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen></iframe>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={7} xl={7} className="pt-2">
                      {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left pb-3">{item.videoTitle}</h5> :
                       <h3 className="h3-large section-title align-left pb-3">{item.videoTitle}</h3>}
                            <p className="section-title mobile-text-position">{item.desc}</p>
                        </Col>
                    </Row>
                </Col>       
            ))}
        </Row>
    )
};

export default SingleGridVideo;

