import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../../../constants/application_contant";
import Strip from "../../../../../customComponent/Strip/Strip";
import RenderPage from "../../../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../../../customComponent/ExternalLayout/ExternalLayout";
import { Link } from 'react-router-dom';

import story_img1 from '../../../../../assets/Stories/Adjourning_working_Structure.jpeg'
const Storytitle1 = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Storytitle1"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Storytitle1"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Story"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/documents/stories/Storytitle1" />
                    <Strip
                        id="tst-strip-story"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                        <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                                <p className="mb-0 section-title align-left">
                                    <Link to={`/Documents/Stories/Storytype2`} className="custom-link-item">{` Resources > Documents > Stories > Story Language >`}</Link>
                                </p>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto ">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h1 className="h1-small section-title align-left pb-2 line-height-48px">भुगतान होने तक कार्य लंबित रखना</h1> :
                                            <h2 className="h2-small section-title align-left pb-2 line-height-48px">भुगतान होने तक कार्य लंबित रखना</h2>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                                                               
                                            <p className="mb-0 section-title align-left">रेस्पीन परियोजना मे भाग लेने हेतु प्रति भागी प्रतिदिन आवेदन भेजते है, परंतुप्रति भागी का कार्य देखकर ही 
                                            प्रति भागी का चयन इस परि योजना मे सेवा प्रदान करने हेतु कि या जाता है। यह प्रति भागी अलग अलग पृष्ठभूमि से आते है, तथा अलग अलग कार्य मे अपनी सेवा प्रदान करते
                                            है जसै की वाक्य रचना,अनुवाद, रिकॉर्डिंगं, लेख आदि की रचना मे।</p>

                                                <p  className="mb-0 section-title align-left">इस परियोजना को पर्णू करने के दौरान हमे कई समस्याओं का समन भी करना पड़ रहा 
                                                है जो कि प्रति भागी  द्वारा उत्पन्न की जा रही है, उन्ही मे से एक का ब्योरा हम इस लेख के माध्यम से पहुंचा रहे है।</p>

                                                <p  className="mb-0 section-title align-left">प्रतिभागी के कार्य की पर्णू जांच होनेके उपरांत ही प्रति भागी को 
                                                भगुतना किया जाता है इसमें उसके किए कार्य की गणु वत्ता की जांच शामिल है।</p>

                                                <p  className="mb-0 section-title align-left">परंतु कुछ प्रति भागी इसे स्वीकार करने मे समर्थ विहीन 
                                                होते है तथा वह अलग प्रकार का व्यवहार दिखाते है। जिससे की कार्य मे त्रुटियां आदि उत्पन्न होती है।</p>

                                                <p  className="mb-0 section-title align-left">कई बार तो प्रति भागी भगुतना ना मिलने पर अगले 
                                                कदम के कार्य को करने से कतराने लगते है या साफ मना करके भगु तना होनेतक का इंतजार करते है तथा बार बार एक ही सवाल उठाते 
                                                है, जिससे की परियोजना मे होने वाले की पर्णू होने पर बाधाएं आने लगती है।</p>

                                                <p  className="mb-0 section-title align-left">आप नीचे बातचित के वि वरण मे देख ही सकते है की यह कितना चुनौतीपर्णू 
                                                कार्य है इस प्रति भागी को यह समझना की उसे उसके किए गए कार्य हेतु भगुतान राशि जल्द ही प्राप्त करवा दी जाएगी।</p>

                                                <img src={story_img1} alt="screenshot of chat" className="mediaimg-card-br"/> 

                                                <p  className="mb-0 section-title align-left">प्रति भागी पहले भगुतना राशि की बात कर रहा है तथा वह तभी आगे का कार्य पर्णू करेगा 
                                                जब उसे पिछली कार्य की भगुतना राशि दी जाएगी। प्रतिभागी के इस व्यवहार सेन सिर्फ परियोजना के बाकी कार्यों मेवि लबं हुआ तथा इस कार्य से जड़ुी और भी समस्या उत्पन्न हुई।</p>

                                               


                                                {/*
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <p className="p-very-small section-title align-right">Hello World!</p> :
                                                    <p className="p-small section-title align-right">Hello world!</p>}
                                                </Col>
                                                */}
                                                

                                               </Col>

                                        {/*   */}                                               
                                        </Row>
                                           
                                            
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Storytitle1.defaultProps = {
    isClassName: true,
};

Storytitle1.propTypes = {
    isClassName: PropTypes.bool,
};

export default Storytitle1;
