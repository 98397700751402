import application_contants from "../../constants/application_contant";

const Makaut_pics_data = [
    
         application_contants.makImg1,
         application_contants.makImg2,
         application_contants.makImg3,
         application_contants.makImg4,
         application_contants.makImg5,
         application_contants.makImg6,
         application_contants.makImg7,
         application_contants.makImg8,
         application_contants.makImg9,
         application_contants.makImg10,
         application_contants.makImg11,
         application_contants.makImg12,
         application_contants.makImg13,
         application_contants.makImg14,
         application_contants.makImg15,
];


export default Makaut_pics_data;