const Aboutus = [
    {
                title: `WHY`,
              
                title9: `RESPIN?/ SCOPE OF RESPIN`,

                desc1: `Speech is the primary medium of human to human interaction. However, with the digital revolution, it is now becoming the primary medium of human to machine interaction. While historically, keyboard and tactile touch screens have been used as interfaces to computers and digital devices. Speech is a natural way of communication, particularly for low income populations who are likely to be financially, digitally and educationally under-skilled in their interactions with digital technologies. With the increase of digital services, development of digital technology such as speech recognition in the languages of the poor is a need of the hour. A number of digital initiatives are being taken to make insurance schemes, financial services and healthcare schemes available to the poor segment of the country. Speech-based technologies are a necessity for digital technologies to be adopted in India because a large majority of users are only able to communicate through spoken language and dialects due to language and literacy constraints.  `,
               
                desc2: `The development of speech-based technologies are constrained by the availability of data (speech samples) in many Indian languages. In order to make this data available, we, in this project RESPIN, will identify, collect and make speech and language data accessible to the Indian technology ecosystem to enable the creation of affordable and accurate speech to text, and natural language processing technologies for the financial and agriculture domains. The output of this project will allow local innovation in emerging markets to develop products and services serving the rural poor populations in their own medium of engagement with technology use cases such as digital financial services, robo-advisory, informed consent, grievance redressal, product suitability, customer service delivery, strong authentication, information exchange, crowd-sourced digital work, agricultural advisory, farmer app support, support for agricultural finance and crop insurance services etc.`,
               
                title2: ` THESE DOMAINS ? (About domain selection) `,
                
                desc3: `Nearly 57.8% of the rural households in India are agricultural households, where these households primarily depend on some form of agriculture for sustenance. With technology bringing benefits to agriculture and farmers in India, the impact of speech technology solutions in Indian languages will be widespread across these large numbers of household members in India. Speech recognition in Indian languages can potentially help illiterate Indians to use digital financial services by simply asking aloud for a service they require including money transfer, making payments and reporting stolen or lost ATM. `,
               
                title3: ` THESE LANGUAGES? (About language selection)`,
               
                desc4: `Voice-based technologies (speech to text, text to speech and natural language processing) are a necessity for digital technologies to be adopted in India because a large majority of users are only able to communicate through spoken language and dialects due to language and literacy constraints. The development of voice-based technologies by organizations are constrained by the availability of data (voice samples) in these languages. In order to make this data available, we will identify, collect and make voice and language data accessible to the Indian technology ecosystem to enable the creation of affordable and accurate speech to text, and natural language processing technologies for the financial and agriculture domains. 
                Speech recognition for Indian languages has been less explored compared to that for English. So we intend to collect data for the following 9 Indian languages (and their dialects)  spoken in states with the highest poverty rates - Hindi, Bengali, Marathi, Telugu, Bhojpuri, Kannada, Maghadi, Chhattisgarhi,  Maithili. These languages have been chosen based on factors related to poverty rates of their corresponding states, number of native speakers, the availability of speech technologies and the opportunities for high impact use cases. `,
               
               
                title4:`WHAT`,
               
                title10:` IS RESPIN?`,
                
                desc5:`The existing approach by large technology companies toward voice technologies is to create general-purpose voice recognition technologies for an audience that is already using their services, largely educated users. This limits the reach to the next wave of internet users who do not fit the demographic of existing users. Additionally, the technology cannot function accurately in more complex domains like finance and agriculture where accuracy is a necessity to instill confidence and encourage use for first-time users. Additionally, the current services are unaffordable to be implemented by product developers in India. This is unsustainable and is serving as a major roadblock for the widespread adoption of speech technologies in user interfaces in India. `,
               
                desc6: `In this project RESPIN, we plan to focus on creating speech data as a digital public good to improve usefulness and usage of digital technologies for the poor. By focusing on these 9 Indian languages and targeting major dialects per language it means enabling the technology ecosystem to solve this problem for a large and so far neglected userbase who has recently gained access to smartphones and mobile data. Additionally, mixed-language speech is a reality of the way people speak in India, adding further complexity to the process of recognition and sense-making which is not currently solved for in the majority of languages spoken in India. `,
                
                title5:`Objectives: `,
                
                
                objectives1:`--Collect  ~1100 hours of speech recordings from multiple dialects in each of the languages`,
               
                objectives2:`-- Make the speech recordings from all languages publicly available.`,
               
                objectives3:`-- Development of speech recognition models`,

                title6: `HOW?`,
                
                desc7:`Process/ Flow of work:`,
                
                subpoint1:`-- Design of text in the domain of agriculture and finance`,
                
                subpoint2:`-- Selection of subjects/speakers with dialect balance `,
                
                subpoint3:`-- Data collection`,
                
                subpoint4:`-- Data validation `,
                
                subpoint5:`-- ASR model development- Acoustic and language modeling`,
                
                subpoint6:`-- Dialect classification `,
                
                subpoint7:`-- Implementation of low-resource ASR`,
                
                subpoint8:`-- Open sourcing`,

                
                title7: `THEN!`,
                
                desc8: `
                Our approach is to generate and collect domain-specific voice data for these nine Indian languages. This will enable technology companies to create domain-specific and language-specific voice enabled interfaces that are affordable and sufficiently accurate. By first identifying and then collecting audio data for sentences that reflect the nuances of Indian regional languages, the technology ecosystem will be able to build models capable of making sense of a user’s informal request thus building confidence in voice as a primary form of digital interaction and encourage increased use of digital services in quality of life domains.`,
                
                desc9:`If we are successful, we will play an important role in enabling a technology ecosystem in India (especially the poor) to make life-changing digital services accessible to the next 500 million users in India by democratizing the creation and use of voice technologies in interfaces in India. Other barriers are all being actively solved - hardware prices are dropping, mobile data prices have become affordable, national software infrastructure is being built and evangelized by iSPIRT. One of the few barriers left is the interface barrier that can be dramatically improved through voice technology.`,

                
                title8: `Read More…`,
                
                subtitle1: `Details of ASR`,
                
                desc10: `Speech recognition refers to a computer program that processes human speech (verbal) into a written format (text). It is also known as Automatic Speech Recognition (ASR), speech-to-text or computer speech recognition. Speech recognition has a large number of applications in sales, security, automotive, healthcare as well as virtual assistants in our daily lives including agriculture and banking. Research and development of speech recognition algorithms is interdisciplinary in nature involving linguistics, signal processing, mathematics and statistics.`,

                subtitle2: `ASR research  in India`,
                
                desc11: `There is an active speech research community in India spreading over IISc, IITs and NITs. The speech research communities in India as well as abroad are keen on using Indian languages both for academic research as well as developing large scale technology solutions. There are several compelling reasons for working with Indian languages including its acoustic and linguistic richness and market value. Having a large and rich set of speech recordings in Indian languages is a key ingredient for carrying out such academic research and technology development. This will, in turn, benefit a large number of PhD students in their research work toward answering a large number of Indian language specific questions. 
                `,
            }, 

];

export default Aboutus;