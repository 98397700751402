import React, { useContext, useState } from "react";
import { Route } from "react-router-dom";
import application_contants from "../constants/application_contant";
import AboutUs from "../customPages/AboutUs/AboutUs";
import About from '../customPages/Admin/AboutUs/AboutUs';
import OpeningDetails from "../customPages/OpeningDetails/OpeningDetails";
import Partners from "../customPages/Partners/Partners";
import Funder from "../customPages/Funder/Funder";
import Openings from "../customPages/Openings/Openings";
import ContactUs from "../customPages/ContactUs/ContactUs";
import Home from "../customPages/Home/Home";
import People from "../customPages/People/People";
import Advisors from "../customPages/People/Advisors";
import TeamMembers from "../customPages/People/TeamMembers";
import Login from "../customPages/Login/Login";
import PeopleList from "../customPages/Admin/People/PeopleList";
import PartnersList from "../customPages/Admin/Partners/PartnerList";
import PublicRoute from "../customComponent/PublicRoute/PublicRoute";
import Authenticated from "../customComponent/Authenticated/Authenticated";
import { AuthContext } from "../context/AuthProvider";
import BannerList from "../customPages/Admin/Banner/BannerList";
import OpeningList from "../customPages/Admin/Opening/OpeningList";
import ChallengesList from "../customPages/Admin/Challenges/ChallengesList";
import ReseachAreaList from "../customPages/Admin/ResearchArea/ResearchAreaList"
//import Research from "../customPages/Research/Research";
import Resources from "../customPages/Resources/Resources";
import Media from "../customPages/Media/Media";
import Contributors from "../customPages/Contributors/Contributors";
import Datasets from "../customPages/Datasets/Datasets"; /* Audio Datasets Page */

import DemoDatasets from "../customPages/Datasets/DemoDatasets"; /* Main Datasets Page */
import TextDatasets from "../customPages/Datasets/TextDatasets"; /* Text Datasets Page */

import Documents from "../customPages/Documents/Documents";
import Flyers from "../customPages/Documents/Documents";


import Publications from "../customPages/Publications/Publications";
import Tools from "../customPages/Tools/Tools";
import Workshops from "../customPages/Workshops/Workshops";
import TermsAndCondition from "../customPages/TermsAndCondition/TermsAndCondition";
import Gallery from "../customPages/Gallery/Gallery";
import Photos from "../customPages/Photos/Photos";
import BMGF_Gallery from "../customPages/Photos/BMGF_Gallery";
import Makaut_pics_Gallery from "../customPages/Photos/Makaut_pics_Gallery";
import AreaDetails from "../customPages/AreaDetails/AreaDetails";
import ChallengesDetails from "../customPages/Challenges/ChallengesDetails"; 
//import Areas from "../customPages/Areas/Areas";
import Challenges from "../customPages/Challenges/Challenges";
import DocumentsList from "../customPages/Admin/Documents/DocumentsList"
import MediaList from "../customPages/Admin/Media/MediaList";
import PublicationList from "../customPages/Admin/Publications/PublicationList";
import ContributorsList from "../customPages/Admin/Contributors/ContributorsList";
import PeopleDetails from "../customPages/PeopleDetails/PeopleDetails";
import AcademicDetails from '../customPages/Contributors/AcademicDetails';
import Bio from '../customPages/Contributors/Bio'
import StudentBio from '../customPages/Contributors/StudentBio'
import PublicationsDetail from "../customPages/Publications/PublicationsDetail";
import IndustryDetail from "../customPages/Contributors/IndustryDetail";
import AcademicsList from "../customPages/Admin/Academics/AcademicsList";
import IndustryList from "../customPages/Admin/Industry/IndustryList";
import IndividualList from "../customPages/Admin/Individual/IndividualList";
import ToolList from "../customPages/Admin/Tools/ToolList";
import DatasetsList from '../customPages/Admin/Datasets/DatasetsList';
import ToolsDetail from '../customPages/Tools/ToolsDetail';
import DatasetsDetail from '../customPages/Datasets/datasetsDetail';
import SampleDatasets from '../customPages/Datasets/SampleDatasets';
import ExpertList from "../customPages/Admin/Experts/ExpertList";

import Docs from "../customPages/Documents/Docs";
import Stories from "../customPages/Documents/Stories/Stories";
//                          import Tabscard from "../customPages/Documents/Tabscard";
import Storytype1 from "../customPages/Documents/Stories/Storytypes/StoryType1/StoryType1";
import StoryTitle11 from "../customPages/Documents/Stories/Storytypes/StoryType1/StoryTitle1";
import StoryTitle12 from "../customPages/Documents/Stories/Storytypes/StoryType1/StoryTitle2";
import StoryTitle3 from "../customPages/Documents/Stories/Storytypes/StoryType1/StoryTitle3";
import Storytype2 from "../customPages/Documents/Stories/Storytypes/StoryType2/StoryType2";
import StoryTitle21 from "../customPages/Documents/Stories/Storytypes/StoryType2/StoryTitle1";
import StoryTitle22 from "../customPages/Documents/Stories/Storytypes/StoryType2/StoryTitle2";

import Books from "../customPages/Documents/Books/Books";
// import BookPdfReaderCustomize from "../customPages/Documents/Books/Pdfviewer/BookReader";

import CSTS_Ng_Bhgl_Gallery from "../customPages/Photos/CSTS_Ng_Bhgl_Gallery";
import CSTS_Prof from "../customPages/Photos/CSTS_Prof_Visit";
import SLTH_Gallery from "../customPages/Photos/SLT_Hackathon_Gallery";
import BFT_Gallery from "../customPages/Photos/BFT_Gallery";
import Res_Gallery from "../customPages/Photos/Res_Gallery";
import TeamOutDec_Gallery from "../customPages/Photos/TeamDec_Gallery";
import SampleBhojpuri from "../customPages/Datasets/temporarysample/SampleBhojpuri";
import SampleBengali from "../customPages/Datasets/temporarysample/SampleBengali";
import SampleChhattisgarhi from "../customPages/Datasets/temporarysample/SampleChhatt";
import SampleKannada from "../customPages/Datasets/temporarysample/SampleKannada";  
import SampleHindi from "../customPages/Datasets/temporarysample/SampleHindi";
import SampleMagadhi from "../customPages/Datasets/temporarysample/SampleMagadhi";
import SampleMarathi from "../customPages/Datasets/temporarysample/SampleMarathi";
import SampleMaithili from "../customPages/Datasets/temporarysample/SampleMaithili";
import SampleTelugu from "../customPages/Datasets/temporarysample/SampleTelugu";    

import BengaliTextData from "../customPages/Datasets/TextLangDatasets/BengaliTextData";

import Mediapdf from "../customPages/Admin/Media/temporary/pdfReaderCustom";
import Mediaimg_1 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg1";
import Mediaimg_2 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg2";
import Mediaimg_3 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg3";
import Mediaimg_4 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg4";
import Mediaimg_5 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg5";
import Mediaimg_6 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg6";
import Mediaimg_7 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg7";
import Mediaimg_8 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg8";
import Mediaimg_9 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg9";
import Mediaimg_10 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg10";
import Mediaimg_11 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg11";
import Mediaimg_12 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg12";
import Mediaimg_13 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg13";
import Mediaimg_14 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg14";
import Mediaimg_15 from "../customPages/Admin/Media/temporary/mediaimages/mediaimg15";
import MediaAudio1 from "../customPages/Admin/Media/temporary/mediaaudio/mediaaudio1";
import { SmartphoneOutlined } from "@material-ui/icons";

const RoutesComponent = (props) => {
    const appContext = useContext(AuthContext);
    const [authenticated, setAuthenticated] = useState(AuthContext.isLogin);

    React.useEffect(() => {
        const handleAuthenticated = async () => {
            await setAuthenticated(appContext ? appContext[0].isLoggedIn : false)
        }
        handleAuthenticated();
    }, [appContext]);

    return (
        <div>
            <Route exact path={application_contants.LANDING} component={Home} {...props} />
            <Route exact path="/about" component={AboutUs} {...props} />
        {/*    <Route exact path="/research" component={Research} {...props} /> */}
            <Route exact path="/resources" component={Resources} {...props} />
            <Route exact path="/media" component={Media} {...props} />
        {/*    <Route exact path="/areas" component={Areas} {...props} />        */} 
            <Route exact path="/contributor" component={Contributors} {...props} />
            <Route exact path="/audiodatasets" component={Datasets} {...props} />
            <Route exact path="/publications" component={Publications} {...props} />
           {/* <Route exact path="/documents" component={Documents} {...props} />  ==================  */}
            <Route exact path="/tools" component={Tools} {...props} /> 
            <Route exact path="/challenges" component={Challenges} {...props} />
            <Route exact path="/workshops" component={Workshops} {...props} />
            <Route exact path="/partner" component={Partners} {...props} />
            <Route exact path="/team" component={People} {...props} />
            <Route exact path="/advisors" component={Advisors} {...props} />
            <Route exact path="/teamMembers" component={TeamMembers} {...props} />
            <Route exact path="/funding" component={Funder} {...props} />
            <Route exact path="/openings" component={Openings} {...props} />
            <Route exact path="/contact-us" component={ContactUs} {...props} />
            <Route exact path="/team/:name" component={PeopleDetails} {...props} />
            <Route exact path="/professor/:name" component={Bio} {...props} />
            <Route exact path="/students/:name" component={StudentBio} {...props} />
            <Route exact path="/openings/:name" component={OpeningDetails}  {...props} />
            <Route exact path="/areas/:name" component={AreaDetails}  {...props} />
            <Route exact path="/ChallengesDetails" component={ChallengesDetails}  {...props} />
            <Route exact path="/terms-and-conditions" component={TermsAndCondition} {...props} />

	        <Route exact path="/gallery/miscellaneous" component={Gallery} {...props} />
            <Route exact path="/gallery" component={Photos} {...props}/>       
            
            <Route exact path = '/contributors/academics/:name' component= {AcademicDetails} {...props} />
            <Route exact path = '/Publications/PublicationsDetail/:name' component= {PublicationsDetail} {...props} />
            <Route exact path='/contributors/Industry/:name' component={IndustryDetail}  {...props} />
            <Route exact path='/tools/:name' component={ToolsDetail}  {...props} />
            <Route exact path='/datasetsDetails' component={DatasetsDetail}  {...props} />
            <Route exact path='/datasets' component={DemoDatasets}/> 
            <Route exact path='/TextDatasets' component={TextDatasets}/> 

            <Route exact path={'/TextDatasets/BengaliTextData'} component={BengaliTextData} />
                    
            <Route exact path='/gallery/field_trip_for_data_collection' component={CSTS_Ng_Bhgl_Gallery} />
            <Route exact path='/gallery/visit_to_csts' component={CSTS_Prof} />
            <Route exact path='/gallery/visit_by_gates_foundation' component={BMGF_Gallery} />
            <Route exact path='/gallery/makaut_pics' component={Makaut_pics_Gallery} />
            <Route exact path='/gallery/slt_hackathon' component={SLTH_Gallery} />
            <Route exact path='/gallery/bengali_field_trip' component={BFT_Gallery} />
            <Route exact path='/gallery/respin_team' component={Res_Gallery} />
            <Route exact path='/gallery/team_dec_outing' component={TeamOutDec_Gallery} />
            
            <Route exact path={'/SampleDatasets'} component={SampleDatasets} />
            <Route exact path={'/Mediapdf'} component={Mediapdf} />
            <Route exact path={'/Mediaimg1'} component={Mediaimg_1} />
            <Route exact path={'/Mediaimg2'} component={Mediaimg_2} />
            <Route exact path={'/Mediaimg3'} component={Mediaimg_3} />
            <Route exact path={'/Mediaimg4'} component={Mediaimg_4} />
            <Route exact path={'/Mediaimg5'} component={Mediaimg_5} />
            <Route exact path={'/Mediaimg6'} component={Mediaimg_6} />
            <Route exact path={'/Mediaimg7'} component={Mediaimg_7} />
            <Route exact path={'/Mediaimg8'} component={Mediaimg_8} />
            <Route exact path={'/Mediaimg9'} component={Mediaimg_9} />
            <Route exact path={'/Mediaimg10'} component={Mediaimg_10} />
            <Route exact path={'/Mediaimg11'} component={Mediaimg_11} />
            <Route exact path={'/Mediaimg12'} component={Mediaimg_12} />
            <Route exact path={'/Mediaimg13'} component={Mediaimg_13} />
            <Route exact path={'/Mediaimg14'} component={Mediaimg_14} />
            <Route exact path={'/Mediaimg15'} component={Mediaimg_15} />
            <Route exact path={'/MediaAudio1'} component={MediaAudio1} />
            <Route exact path={'/SampleBhojpuri'} component={SampleBhojpuri} />
            <Route exact path={'/SampleBengali'} component={SampleBengali} />
            <Route exact path={'/SampleChhattisgarhi'} component={SampleChhattisgarhi} />
            <Route exact path={'/SampleHindi'} component={SampleHindi} />
            <Route exact path={'/SampleKannada'} component={SampleKannada} />
            <Route exact path={'/SampleMagadhi'} component={SampleMagadhi} />
            <Route exact path={'/SampleMarathi'} component={SampleMarathi} />
            <Route exact path={'/SampleMaithili'} component={SampleMaithili} />
            <Route exact path={'/SampleTelugu'} component={SampleTelugu} />
            
           <Route exact path={'/documents'} component={Docs} {...props} /> 
            <Route exact path={'/documents/books'} component={Books} />
     {/*        <Route exact path={'/documents/books/rabindra_suman'} component={BookPdfReaderCustomize} />*/}
             <Route exact path={'/documents/Stories'} component={Stories} />
            <Route exact path={'/documents/stories/storytype1'} component={Storytype1} /> 
            <Route exact path={'/documents/stories/storytype1/storytitle1'} component={StoryTitle11} /> 
            <Route exact path={'/documents/stories/storytype1/storytitle2'} component={StoryTitle12} /> 
            <Route exact path={'/documents/stories/storytype2/storytitle1'} component={StoryTitle21} /> 
            <Route exact path={'/documents/stories/storytype2/storytitle2'} component={StoryTitle22} /> 
            <Route exact path={'/documents/stories/storytype1/storytitle3'} component={StoryTitle3} /> 
            <Route exact path={'/documents/stories/storytype2'} component={Storytype2} /> 
           <Route exact path={"/documents/flyers"} component={Flyers} {...props} />
            {/* <Route exact path={'/tabs'} component={Tabscard} /> */}
    
    
  
            <PublicRoute
                exact
                path={'/login'}
                component={Login}
                {...props}
                authenticated={authenticated}
            />
            <Authenticated
                exact
                path={'/people-list'}
                component={PeopleList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.ABOUTUS}
                component={About}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.PARTNERLIST}
                component={PartnersList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.BANNERLIST}
                component={BannerList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.OPENINGLIST}
                component={OpeningList}
                authenticated={authenticated}
                {...props}
            />
              <Authenticated
                exact
                path={application_contants.REASEARCHAREA}
                component={ReseachAreaList}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={application_contants.CHALLENGESLIST}
                component={ChallengesList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.DOCUMENTS}
                component={DocumentsList}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={application_contants.MEDIA}
                component={MediaList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.PUBLICATIONS}
                component={PublicationList}
                authenticated={authenticated}
                {...props}
            /> 
            <Authenticated
                exact
                path={application_contants.ACADEMICS}
                component={AcademicsList}
                authenticated={authenticated}
                {...props}
            /> 
            <Authenticated
                exact
                path={application_contants.INDUSTRY}
                component={IndustryList}
                authenticated={authenticated}
                {...props}
            /> 
            <Authenticated
                exact
                path={application_contants.INDIVIDUAL}
                component={IndividualList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/tool-list'}
                component={ToolList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/datasets-list'}
                component={DatasetsList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={application_contants.EXPERTS}
                component={ExpertList}
                authenticated={authenticated}
                {...props}
            />

        </div>
    );
};

export default RoutesComponent;
