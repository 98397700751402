import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const OpeningService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateOpening } = ApiConfig;

        const url = baseUrl + insertOrUpdateOpening;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
  
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            pdfURL : data.pdfURL,
            typeId : data.typeId,
            openingTitle: data.openingTitle,
            title: data.title,
            code: data.code,
            description: data.description,
            roleDescription: data.roleDescription,
            rolePoints: data.rolePoints,
            essentialDescription: data.essentialDescription,
            essentialPoints: data.essentailPoints,
            preferredDescription: data.preferredDescription,
            preferredPoints: data.preferredPoints,
            duration: data.duration,
            startDate: data.startDate,
            netSalary: data.netSalary,
            applicationLink: data.applicationLink,
            contactName: data.contactName,
            contactEmail: data.contactEmail,
        });

        const openingServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_openingService",
            true
        );
        return openingServicePromise;
    },

    getOpeningList: async () => {
        const { baseUrl, getOpeningList } = ApiConfig;

        const url = baseUrl + getOpeningList;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const openingServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_opening",
            true
        );
        console.log("openingServicePromise",openingServicePromise)
        return openingServicePromise;
    },

    getOpeningsList: async () => {
        const { baseUrl, getOpeningsList } = ApiConfig;
        const url = baseUrl + getOpeningsList;
        console.log(getOpeningsList)
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const openingsServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_openings",
            true
        );
        return openingsServicePromise;
    },

    enableDisableOpening: async (data) => {
        const { baseUrl, enableDisableOpening } = ApiConfig;
        const url = baseUrl + enableDisableOpening;
        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            status: `${data.status}`,
        });

        const enableDisableOpeningServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_enableDisableOpening",
            true
        );
        return enableDisableOpeningServicePromise;
    },

};

export default OpeningService;
