
import React, { useState } from 'react';
import PropTypes from "prop-types";
import Slider from "react-slick";
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Row, Col } from 'reactstrap';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import { ApiConfig } from '../../../api/apiConfig/apiConfig';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: 8,
        border: 0,
        '&:focus': {
            outline: 'none',
        },
    },
}));

const ImageViewer = (props) => {
    const classes = useStyles();
    const [list, setList] = useState([]);
    const { isModal, handleToggle, isServerPath } = props;
    React.useEffect(() => {
        setList([...props.list])
    }, [props.list])

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-next-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined style={{ color: '#FFFFFF', fontSize: '2rem' }} />
            </div>
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={`${className} next-prev-container`}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined style={{ color: '#FFFFFF', fontSize: '2rem' }} />
            </div>
        );
    }

    const settings = {
        className: "slider",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isModal}
            onClose={handleToggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isModal}>
                <div className={`${classes.paper} image-modal-container`}>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pr-4">
                            <Slider {...settings}>
                                {list.length > 0 && list.map((item, index) => (
                                    <Row key={index} className="mx-0">
                                        {isServerPath ?
                                        <Col xs={1} sm={12} md={12} lg={12} xl={12} className="mx-0  ">
                                            <img src={`${ApiConfig.URL}${item.imgPath}`} alt="" className="img-viewer-container cursor-pointer " />
                                        </Col>: 
                                         <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0"> 
                                         <img src={item} alt="" className="img-viewer-container cursor-pointer  " />

                                       </Col>}
                                    </Row>
                                ))}
                            </Slider>
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Modal>
    )
}


ImageViewer.propTypes = {
    isServerPath: PropTypes.bool,
};

ImageViewer.defaultProps = {
    isServerPath: true,
};


export default ImageViewer;