import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import OpeningComponent from "../../customComponent/OpeningComponent/OpeningComponent";

const Openings = (props) => {
    const [width] = useWindowSize();

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-openings"
                className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <SEOComponent
                    title="Openings"
                    keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/openings" />
                <Strip
                    id="tst-strip-openings"
                    className="strip strip-no-padding"
                    containerType="container">
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left pb-3">Openings</h5> :
                                        <h1 className="h1-small section-title align-left pb-3">Openings</h1>}
					                 
                                        <div className="opening-warn-slide"> 
                                            <div className="opening-warn-notice "> 
                                                <p className=" p-large mb-0 section-title align-left opening-warn-font">
                                                    <span className=" h1-small opening-warn">&#9888;</span>  
                                                    &nbsp;&nbsp;We do not charge anything for the application process. BEWARE OF FRAUD!&nbsp;&nbsp;
                                                    <span className=" h1-small opening-warn">&#9888;</span> 
                                                </p> 
                                            </div> 
                                        </div>
                                     
                                     
                                     
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-5 pt-5">
                                    <OpeningComponent firstSectionTitle={`Research Openings`} secondSectionTitle={`General Openings`} isSecond={true}/>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout >
    );
};

Openings.defaultProps = {
    isClassName: true,
};

Openings.propTypes = {
    isClassName: PropTypes.bool,
};
export default Openings;
