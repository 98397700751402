import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import PartnerService from "../../../api/services/PartnerService";
import debugLogger from "../../Utils/debugLogger";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

const Partners = (props) => {
  const [width] = useWindowSize();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPartnerList();
  }, [])

  const getPartnerList = async () => {
    setIsLoading(true);
    await PartnerService.getPartnerList()
      .then(async (res) => {
        if (res.status === 200) {
          try {
            res.text().then(async (res) => {
              let result = JSON.parse(res);
              if (result.code === 0) {
                if (result.data) {
                  const lists = result.data.list.filter((it) => it.status === 1);
                  debugLogger("partner", lists);
                  setData([...lists])
                  setIsLoading(false);
                } else {
                  setData([]);
                  setIsLoading(false);
                }
              } else {
                setData([]);
                setIsLoading(false);
              }
            });
          } catch (err) {
            console.log(err);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <ExternalLayout>
      <RenderPage
        id="tst-landing"
        className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
        containerType="container-fluid"
      >
        <Strip
          id="tst-Partners"
          className="strip strip-no-padding"
          containerType="container"
        >
          <SEOComponent
            title="Partner"
            keywords="Speech Recognition, respin, SPIRE LAB, Navana"
            description="Navana Tech is an award winning conversational AI startup on a mission to empower the next billion users to use digital services with independence and confidence"
            siteUrl="/partner" />
          <Row className="mx-0">
            <Col xs={11} sm={11} md={11} lg={9} xl={9} className="m-auto px-0">
              <Row>
                <Col xs={12} sm={12} md={11} lg={12} xl={12} className="pt-5 pb-4">
                  {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left pb-3">Partner</h5> :
                    <h1 className="h1-small section-title  align-left pb-3">Partner</h1>}
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {data.length > 0 && data.map((item, index) => (
                    <Row key={index}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <Col xs={8} sm={8} md={6} lg={4} xl={4} className="">
                            <a href={`${item.link}`} target="_blank" rel="noreferrer" className="custom-reference-links">
                              <img src={`${ApiConfig.url}${item.imgPath}`} alt="" />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-4 pb-4">
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            {item.description.split(/[\n\r]/g).map((params, i) => (
                              <p className="mb-0 section-title pb-3 align-left" key={i}>{params}</p>
                            ))}
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className="mb-0 section-title pb-3 align-left">
                              <a href={`${item.link}`} target="_blank" rel="noreferrer" className="custom-reference-links title-blue">
                                {item.linkText}</a>
                            </p>

                          </Col>
                        </Row>
                      </Col>

                    </Row>
                  ))}
                </Col>

              </Row>
            </Col>
          </Row>
        </Strip>
      </RenderPage>
    </ExternalLayout>
  );
};

Partners.defaultProps = {
  isClassName: true,
};

Partners.propTypes = {
  isClassName: PropTypes.bool,
};

export default Partners;
