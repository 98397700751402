import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";

const Funder = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-landing"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Funder"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Funder"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/funder" />
                    <Row className="mx-0">
                        <Col xs={11} sm={11} md={11} lg={9} xl={9} className="m-auto px-0">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left pb-5">Funding</h5> :
                                        <h1 className="h1-small section-title align-left pb-5">Funding</h1>}
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-5">
                                    <a href="https://www.gatesfoundation.org/" target="_blank" rel="noreferrer">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left line-height-48px">Bill & Melinda Gates Foundation</h5> :
                                            <h1 className="h1-small section-title align-left line-height-48px">Bill & Melinda Gates Foundation</h1>}
                                    </a>

                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Funder.defaultProps = {
    isClassName: true,
};

Funder.propTypes = {
    isClassName: PropTypes.bool,
};

export default Funder;
