import React, { useState } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, FormGroup, Label, Input, yy } from "reactstrap";
import classnames from 'classnames';
import PublicationsType from "../../Utils/Json/PublicationsType";
import PublicationService from "../../../api/services/PublicationService";
import debugLogger from '../../Utils/debugLogger';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import Slider from "react-slick";
import moment from "moment";



const Publications = (props) => {
    const [width] = useWindowSize();
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState([]);
    const [publication, setPublication] = useState([]);
    const [yearArrTitle, setYearArrTitle] = useState([])
    const date = moment().format('yyyy');


    function SampleNextArrow(props) {
        const { className, style, onClick, arrowColor } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowForwardIosOutlined style={{ color: '#303030', fontSize: '1.4rem' }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick, arrowColor } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <ArrowBackIosOutlined style={{ color: '#303030', fontSize: '1.4rem' }} />
            </div>
        );
    }

    const settings = {
        loop: false,
        infinite: false,
        initialslide: 0,
        slidesToShow: width >= 1200 ? 10 : (width >= 768 && width <= 1023) ? 3 : 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow  />,
    };

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    React.useEffect(() => {
        setData([]);
        window.scrollTo(0, 0);
        getList();
    }, [])

    const getList = async () => {
        setIsLoading(true);
        await PublicationService.getPublicationList()
            .then(async (res) => {
                console.log("publications results", res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    let yearArr = []
                                    result.data.list.map((item, index) => {
                                        yearArr.push(moment(item.date).format('yyyy'));
                                        debugLogger(result.data);
                                        const lists = result.data.list.filter((it) => it.status === 1);
                                        setData([...lists])
                                        console.log("data list", ...result.data.list)

                                    })
                                    const yearArrFilter = yearArr.filter(function (item, pos) {
                                        return yearArr.indexOf(item) == pos;
                                    })
                                    yearArr.sort(function (a, b) {
                                        return a - b;
                                    });
                                    yearArr = yearArrFilter.sort((a, b) => b - a);
                                    setYearArrTitle(yearArrFilter)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const renderDesktopItem = (item, index) => {
        return <Col xs={12} sm={12} md={3} lg={12} xl={12} className="px-0 py-1 publications-card-br" key={index} >
            <a href={`/Publications/PublicationsDetail/${item.title.toLowerCase()}`}>
                <Row className="mb-0 mx-0">
                    <Col xs={12} sm={12} md={12} lg={11} xl={12} className={`bg-solitude-grey p-3 publications-card-br`}>
                        <div>
                            <p className="p-large section-title nunitoSans-Bold align-left publications-card-position">{item.title}</p>
                        </div>
                        <div>
                            <p className="p-small section-title nunitoSans-Regular align-left publications-card-position">{item.detailTitle}</p>
                        </div>
                        <a href={`${item.url}`} rel="noreferrer" target="_blank" className="p- very-small section-title nunitoSans-Light custom-reference-links pl-1 align-left">{item.url}</a>

                        <div className="align-right pb-2">
                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                        </div>
                    </Col>
                </Row>
            </a>
        </Col>
    }

    const renderMobileItem = (item, index) => {
        return <Col xs={2} sm={2} md={3} lg={12} xl={12} className="px-0 py-1 publications-card-br " key={index} >
            <a href={`/Publications/PublicationsDetail/${item.title.toLowerCase()}`}>
                <Row className="mb-0 mx-0">
                    <Col xs={3} sm={3} md={3} lg={11} xl={12} className={`bg-solitude-grey p-3 publications-card-br`}>
                        <div>
                            <p className="p-large section-title nunitoSans-Bold align-left publications-card-position">{item.title}</p>
                        </div>
                        <div>
                            <p className="p-small section-title nunitoSans-Regular align-left publications-card-position">{item.detailTitle}</p>
                        </div>
                        {item.url ? (
                            <a href={`${item.url}`} rel="noreferrer" target="_blank" className="p- very-small section-title nunitoSans-Light custom-reference-links pl-1 align-left">Link</a>
                        ) : null}
                        <div className="align-right pb-2">
                            <img src={application_contants.rightImg} alt="" className="right-icon--width" />
                        </div>
                    </Col>
                </Row>
            </a>
        </Col>
    }
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-publications"
                className={`render px-0 ${props.isClassName ? ' render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <SEOComponent
                    title="Publications"
                    keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                    description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                    siteUrl="/publications" />
                <Strip
                    id="tst-strip-publications"
                    className="strip strip-no-padding"
                    containerType="container">
                    <Row className="mx-2 pt-5">
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                <Row>
                    

                        <Col xs={12} sm={12} md={12} lg={12} xl={4} className="py-3 pt-5" >
                            {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-small nunitoSans-Light section-title align-left ">Publications</h5> :
                                <h3 className="h3-small nunitoSans-Light section-title align-left">Publications</h3>}
                        </Col>
                        {/* <Col xs={8} sm={6} md={6} lg={4} xl={5} className="px-2 py-3 align-end  card-reports" >
                            <Input
                                type="text"
                                placeholder="Search"
                                id="title"
                                name="title"
                                className="form-control common-form-control search-input-field"
                            />
                        </Col>
                        <Col xs={2} sm={2} md={3} lg={1} xl={1} className="px-0 py-3 card-reports" >
                            <button
                                className="search-btn"
                            >
                                Search
                            </button>
                        </Col>
                        <Col xs={6} sm={6} md={12} lg={1} xl={1} className="px-0 py-3 pl-2 card-reports" >
                            <p className='contribute-sort'>Latest to oldest</p>
                        </Col> */}

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                            <div>
                                <Col xs={10} sm={12} md={12} lg={11} xl={11} className="mx-0">
                                    <Slider {...settings}>
                                        {yearArrTitle.map((it, index) => (
                                            // <Row key={index} className="mx-0">
                                            <Nav tabs className="tab-container align-left mx-0">
                                                <NavItem className="tab-nav-item" key={index}>
                                                    <NavLink
                                                        className={`tab-nav-link ${classnames({ active: activeTab === index })}`}
                                                        onClick={() => { toggle(index); }}
                                                    >
                                                        {it}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            // </Row>
                                        ))}
                                    </Slider>
                                </Col>
                            </div>
                        </Col>
                            <Row>
                                {yearArrTitle.length > 0 && yearArrTitle.map((it, index) => (
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={index} className="">
                                            <Row className="pt-5 mx-0">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                                    {width >= application_contants.MOBILE_BREAKPOINT ?
                                                        <div className="pb-5">
                                                            <Row className="mx-0 pt-2 pb-2">
                                                                {data.map((item, index) =>
                                                                    moment(item.date).format('yyyy') === it ? renderMobileItem(item, index) : null
                                                                )}
                                                            </Row>
                                                        </div>
                                                        :
                                                        <div className="pb-5">
                                                            <Row className="mx-0 pt-2 pb-2">
                                                                {data.map((item, index) =>
                                                                    moment(item.date).format('yyyy') === it ? renderDesktopItem(item, index) : null
                                                                )}
                                                            </Row>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>

                                ))}
                            </Row>
                            
                            

                          
                        </Row>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </ExternalLayout >
    );
};
Publications.defaultProps = {
    isClassName: true,
};
Publications.propTypes = {
    isClassName: PropTypes.bool,
};
export default Publications;