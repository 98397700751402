import React, {useState} from 'react';
import { Col, Row } from 'reactstrap';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import Strip from '../../customComponent/Strip/Strip';
import { useWindowSize } from '@react-hook/window-size';
import application_contants from '../../constants/application_contant';
import { Link } from 'react-router-dom';
import DatasetsService from '../../../api/services/DatasetsService';


const DatasetsDetail = (props) => {
    const [sectionTitle, setSectionTitle] = useState('');
    const [title, setTitle] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [description, setDescription] = useState('')
    const [linguality, setLinguality] = useState('');
    const [language, setLanguage] = useState('');
    const [catalogue, setCatalogue] = useState('');
    const [datasize1, setDatasize1] = useState('');
    const [datasize2, setDatasize2] = useState('');
    const [datasize3, setDatasize3] = useState('');
    const [annotation, setAnnotation] = useState('');
    const [sampling, setSampling] = useState('');
    const [validation, setValidation] = useState('');
    const [datacreator, setDatacreator] = useState('');
    const [publishingyear, setPublishingyear] = useState('');
    const [researchpurpose, setResearchpurpose] = useState('');
    const [publications, setPublications] = useState('');
    const [download, setDownload] = useState('');
    const [samplefiles, setSamplefiles] = useState('');
    const [feedback, setFeedback] = useState('');
    const [data, setData] = useState([]);
    const [width] = useWindowSize();
    const {dataset} = props.location.state;


    const handleNavigationDatasets = async (samplefiles) => {
        console.log('clicked')
        props.history.push('/sampleDatasets',{samplefiles});
    }
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        // setSectionTitle(props.location.pathname.split('/')[2]);
        // getList();
    }, []);

    // const getList = async () => {
    //     let it = [];
    //     await DatasetsService.getdatasetsList()
    //         .then(async (res) => {
    //             if (res.status === 200) {
    //                 try {
    //                     res.text().then(async (res) => {
    //                         let result = JSON.parse(res);
    //                         console.log("result", result)
    //                         if (result.code === 0) {
    //                             if (result.data) {
    //                                 console.log("location", props.location.pathname)
    //                                 result.data.list.map((item) => {
    //                                     if (item.title.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
    //                                         setTitle(item.title)
    //                                         setDetailTitle(item.detailTitle)
    //                                         setDescription(item.description)
    //                                         setLinguality(item.linguality)
    //                                         setLanguage(item.language)
    //                                         setCatalogue(item.catalogue)
    //                                         setDatasize1(item.datasize1)
    //                                         setDatasize2(item.datasize2)
    //                                         setDatasize3(item.datasize3)
    //                                         setAnnotation(item.annotation)
    //                                         setSampling(item.sampling)
    //                                         setValidation(item.validation)
    //                                         setDatacreator(item.datacreator)
    //                                         setPublishingyear(item.publishingyear)
    //                                         setResearchpurpose(item.researchpurpose)
    //                                         setPublications(item.publications)
    //                                         setDownload(item.download)
    //                                         setSamplefiles(item.samplefiles)
    //                                         setFeedback(item.feedback)
    //                                     }
    //                                 });
    //                                 // debugLogger(it);
    //                                 //debugLogger(result.data);
    //                                 setData([...it])
    //                                 console.log("data", data)
    //                                 //setIsLoading(false);
    //                             } else {
    //                                 setData([]);
    //                                 // setIsLoading(false);
    //                             }
    //                         } else {
    //                             setData([]);
    //                             // setIsLoading(false);
    //                         }
    //                     });
    //                 } catch (err) {
    //                     console.log(err);
    //                     //setIsLoading(false);
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             //setIsLoading(false);
    //         });
    // }
    
    return (
        <ExternalLayout>
            <RenderPage
                id="tst-datasets"
                className="strip render-page px-0"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-strip-datasets"
                    className="strip strip-no-padding px-0"
                    containerType="container"
                >
               
                    <Row className="mx-0  pt-5">
                    <Col xs={12} ms={12} md={12} lg={12} xl={12} className="m-auto pt-5">
                            <p className="mb-0 section-title align-left">
                                <Link to={`/AudioDatasets`} className="custom-link-item">{` Resources > Datasets > Transcribed Audio Datasets >`}</Link>
                                <span className="h6-large pl-2 fw-600">{`${dataset.title}`} </span>
                            </p>

                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pt-4">
                            <Row className="mb-0 mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-2">
                                    <div >
                                        <p className="h6-medium section-title jostRegular title-black  align-left">{dataset.detailTitle}</p>
                                        <p className="p-large section-title jostLight align-left title-black pt-2 pb-2">{dataset.description}</p>
                                </div>
                                </Col>
                                 <Col xs={11} ms={11} md={11} lg={11} xl={11} className="pr-5 pt-4">
                                                {/* <h4 className="h4-small table-heading">Expert talk speaker</h4> */}
                                                <table className="column_design ">
                                                    {/* <tr className=" bg-white ">
                                                        <td className="single_column_design">
                                                        <div className="align-center p-large column_design1 row-header-width">Type</div>
                                                        </td>
                                                        <td className="single_column_design">
                                                        <div className="row-header-width column_design1 p-large">speech</div>
                                                        </td>
                                                    </tr> */}
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Type</div>
                                                        </td>
                                                        <td className="align-left column_design1 single_column_design">
                                                        <div >Read Speech</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design p-large bg-white ">
                                                        <td className="align-left p-large single_column_design">
                                                        <div >Language(s)</div>
                                                        </td>
                                                        <td className="align-left p-large single_column_design">
                                                        <div >{dataset.language}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Linguality</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.linguality}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Catalogue ID</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.catalogue}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (HH:MM:SS)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.datasize1}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Data Size (#Sentences)</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.datasize2}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Size (#Speakers)</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.datasize3}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Annotation file availability</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.annotation}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Recording Specifications</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.sampling}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Validation Status</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.validation}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Data Creator</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.datacreator}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Year of Publishing</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.publishingyear}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Suggested research purposes / areas</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.researchpurpose}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Publications</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div >{dataset.publications}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Sample files</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                         <div ><a href={`/${dataset.samplefiles}`} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</a></div>
                                                        
                                                        {/*<div onClick = {() => handleNavigationDatasets(dataset.samplefiles)} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.samplefiles}</div>
                                                        */} 
                                                         </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left single_column_design">
                                                        <div >Download</div>
                                                        </td>
                                                        <td className="align-left single_column_design">
                                                        <div ><a href={`${dataset.download}`} target="_blank" rel="noreferrer" className="custom-reference-links">{dataset.download}</a></div>
                                                        </td>
                                                    </tr>
                                                    <tr className="column_design bg-white ">
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >Feedback</div>
                                                        </td>
                                                        <td className="align-left bg-blue-medium single_column_design">
                                                        <div >{dataset.feedback}</div>
                                                        </td>
                                                    </tr>
                                                </table>  
                                                </Col>
                                                
                                                
                                </Row>
                                </Col>
                        
                    </Row>
               
                </Strip>
            </RenderPage>
         </ExternalLayout>
    )
};

export default DatasetsDetail;