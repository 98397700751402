import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/Telugu/male/Telugu_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/Telugu/male/Telugu_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/Telugu/female/Telugu_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/Telugu/female/Telugu_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/Telugu/male/Telugu_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/Telugu/male/Telugu_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/Telugu/female/Telugu_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/Telugu/female/Telugu_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/Telugu/male/Telugu_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/Telugu/male/Telugu_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/Telugu/female/Telugu_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/Telugu/female/Telugu_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/Telugu/male/Telugu_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/Telugu/male/Telugu_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/Telugu/female/Telugu_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/Telugu/female/Telugu_df_2.mp3"; // Female audio


function Telugu(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Central Telugu */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Central Telugu </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    ఒక వారం నుంచి భోజనం తర్వాత బొప్పాస్కాయ తింటన్నాను , తిన్న తిండి చక్కగా అరుగుతంది
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Central Telugu</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">సముద్రపు పాచి కార్బన్ డయాక్సైడ్ను పీల్చుకుంటది , నానబెట్టే స్పాంజిలా కూడా పనిచేత్తదని చెబుతున్నారు</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Central Telugu </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">లోక్ సభలో కేంద్ర బడ్జెట్ను ఎప్పుడూ ముందుగానే సమర్పిస్తారు</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Central Telugu</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">యీ స్పింకర్లను ఒక్క నీళ్ళ కోసమే కాకుండా ఎరువుల్ని పిచికారీ చెయ్యడానికి కూడా వాడొచ్చు</p></td>
            </tr>
            {/* Table Dialect 2 == Southern Telugu */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Telugu </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">బ్యాంకులు నుండి అప్పులు కూడా తీసుకోవచ్చు</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Telugu</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">బీన్స్ లో కూడా చానా రకాలు ఉన్నాయని మార్కెట్ కి వెళ్తే తెలిసింది</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Southern Telugu </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">భారతదేశంలో ఇది ఒక ప్రత్యేకమైన బ్యాంకుగా చెప్పొచ్చు</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Telugu</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ఇండ్ల ఇన్సూరెన్స్ కి ఓనర్ వయసును బట్టి ప్రీమియం ఇంత అని చెప్తారు</p></td>
            </tr>
            {/* Table Dialect 3 == Telangana */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Telangana </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">మార్కెటింగ్ లో కొత్త పద్దతులు ఎట్లుంటై ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Telangana</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ఎలాంటి పూచీకత్తు లేకుండా లోన్స్ ఇస్తారా వాటికి ఎలా చేయాలి ఎంత చేయాలి ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Telangana </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">సామాజిక రంగం పథకానికి ఎక్కడ ఎలా దరఖాస్తు చేసుకోవాలి ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Telangana </p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ఎన్.బి.ఎఫ్.సి ఎటువంటి రుణాలను ఇస్తుంది ?</p></td>
            </tr>
                {/* Table Dialect 4 == Utterandhra */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Utterandhra </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">నెలకి ఒక కోడి ఎన్ని గుడ్లను పెడుతుంది ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Utterandhra</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">లోన్ తిరిగి ఎన్ని రోజుల్లో కట్టాలి ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Utterandhra </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">ఎన్ని రకములు పంటలకు కోల్డ్ స్టోరేజ్ వాడుకోవచ్చు ?</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Utterandhra</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">ఒక వేల అందునా భీమా కట్టాలి అంటే ఎలా కట్టాలి ?</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleTelugu = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleTelugu"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleTelugu"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleTelugu"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleTelugu" />
                    <Strip
                        id="tst-strip-SampleTelugu"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Telugu Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Telugu />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleTelugu;