import { Fetch } from "../apiConfig/Fetch";
import { ApiConfig } from "../apiConfig/apiConfig";

const AboutService = {
    insertOrUpdate: async (data) => {
        const { baseUrl, insertOrUpdateAbout } = ApiConfig;

        const url = baseUrl + insertOrUpdateAbout;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            description: `${data.description}`,
        });

        const aboutServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_aboutService",
            true
        );
        return aboutServicePromise;
    },

    getInformation: async () => {
        const { baseUrl, getAboutInformation } = ApiConfig;

        const url = baseUrl + getAboutInformation;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });


        const aboutServicePromise = await Fetch(
            url,
            "GET",
            headers,
            '',
            "services_about",
            true
        );
        return aboutServicePromise;
    },

};

export default AboutService;
