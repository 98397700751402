import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { Col, Row, Button, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import DataTable from "react-data-table-component";
import DashboardLayout from '../../../customComponent/InternalLayout/DashboardLayout';
import RenderPage from '../../../customComponent/RenderPage/RenderPage';
import Strip from '../../../customComponent/Strip/Strip';
import application_contants from '../../../constants/application_contant';
import Loader from '../../../customComponent/Loader/Loader';
import TablesColumns from './TablesColumns';
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../Utils/dataTablesStyledTheme';
import { SearchOutlined } from '@material-ui/icons';
import AddPeople from './AddPeople';
import DesignationService from '../../../../api/services/DesignationService';
import PeopleService from '../../../../api/services/PeopleService';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import MessageConfirmation from '../../../customComponent/MessageConfirmation/MessageConfirmation';
import { usePeople } from '../../../context/PeopleProvider';

const PeopleList = (props) => {
    const [peopleState, { getAllPeopleList }] = usePeople();
    const [data, setData] = useState([]);
    const [designationData, setDesignationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterText, setFilterText] = useState();
    const [isAdd, setIsAdd] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [peopleItem, setPeopleItem] = useState('');
    const [width] = useWindowSize();

    React.useEffect(() => {
        getDesignationList();
        getPeopleList();
    }, []);

    const getDesignationList = async () => {
        await DesignationService.getList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                if (result.data) {
                                    let itemList = [];
                                    let obj = {};
                                    result.data.list && result.data.list.map((item) => {
                                        obj = {
                                            label: item.name,
                                            value: item.designationId
                                        }
                                        itemList.push(obj)
                                    });

                                    setDesignationData([...itemList])
                                    setIsLoading(false);
                                } else {
                                    setDesignationData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setDesignationData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const getPeopleList = async () => {
        setIsLoading(true);
        try {
          await getAllPeopleList();
          setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            alertDangerConfirmMessage('Error', error);
        }
    }

    const handleOnChange = (event) => {
        setFilterText(event.target.value)
    }

    const handleToggle = () => {
        setIsModal(!isModal);
        setIsAdd(true)
    };

    const handleClear = () => {
        setFilterText("");
    };

    const handleEdit = (item) => {
        console.log(item)
        setIsModal(!isModal);
        setIsAdd(false);
        setPeopleItem(item);
    }

    const handleDelete = async (item) => {
        setIsConfirmModal(true)
        setPeopleItem(item);
    }

    const handleRefresh = async () => {
        setIsModal(!isModal);
        await getPeopleList();
        setIsAdd(true)
    }

    const handleCancelBtn = async () => {
        setIsConfirmModal(false)
        setPeopleItem('');
    }

    const handleConfirmBtn = async () => {
        setIsConfirmModal(false)
        const id = await localStorage.getItem('userId')
        console.log("id",id)
        let data = {
            peopleId: peopleItem.peopleId,
            status: peopleItem.status === 1 ? 0 : 1,
            userId: id,
        };
        console.log("data",data)
        setIsLoading(false);
        await PeopleService.enableDisableUser(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                await getPeopleList();
                                alertSuccessMessage(`${result.message}`);
                            } else {
                                alertDangerConfirmMessage(`${result.message}`);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <DashboardLayout>
            <RenderPage
                id="login-page"
                className="mt-3"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-login"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="py-3 px-0">
                            <Row className="align-items-center mx-0">
                                <Col xs={7} sm={7} md={4} lg={3} xl={3}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <Button
                                                className="btn custom-theme-btn custom-btn--small fs-14px br-4px"
                                                onClick={() => handleToggle()}
                                            >
                                                Add People
                                            </Button>
                                        </div>

                                    </div>
                                </Col>
                                <Col xs={5} sm={5} md={8} lg={9} xl={9}>
                                    <Row className="justify-content-end">
                                        <Col xs={10} sm={10} md={4} lg={4} xl={4}>
                                            <InputGroup className="search__icon-container">
                                                <InputGroupAddon
                                                    addonType="append"
                                                    className="search__icon_append"
                                                >
                                                    <InputGroupText className="cursor-pointer search__icon-position">
                                                        <SearchOutlined
                                                            style={{
                                                                color: `${filterText ? "#fd4b9a" : "#dfdfdf"}`,
                                                                fontSize: "1.5rem",
                                                            }}
                                                        />
                                                    </InputGroupText>
                                                </InputGroupAddon>

                                                <Input
                                                    placeholder="Search"
                                                    name="filter"
                                                    className="form-control search__form-container"
                                                    value={filterText}
                                                    onChange={(event) => handleOnChange(event)}
                                                />
                                                {filterText ? (
                                                    <p
                                                        className="common-label common-label-small mb-0 reset_label"
                                                        onClick={() => handleClear()}
                                                    >
                                                        Clear
                                                    </p>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="pb-3 pt-3">
                                {!isLoading ? (
                                    <DataTable
                                        title=""
                                        columns={TablesColumns(handleEdit, handleDelete)}
                                        data={peopleState}
                                        pagination
                                        className="data-table"
                                        persistTableHead={false}
                                        customStyles={
                                            width > application_contants.MOBILE_BREAKPOINT
                                                ? dataTablesStyledTheme
                                                : dataTablesMobileStyledTheme
                                        }
                                    // onRowClicked={(data) => this.getHospitalItem(data)}
                                    />
                                ) : (
                                    <div className="pt-32px pb-64px d-flex justify-content-center">
                                        <Loader containerClassName="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
            {isModal ? <AddPeople isModal={isModal} toggle={handleToggle} isAdd={isAdd} list={designationData} peopleItem={peopleItem} handleRefresh={handleRefresh} /> : null}
            {isConfirmModal ? (
                <MessageConfirmation
                    isModal={isConfirmModal}
                    handleCancelBtn={handleCancelBtn}
                    handleConfirmBtn={handleConfirmBtn}
                    message={`Are you sure you want to ${peopleItem.status === 1 ? 'disable' : 'enable'} this user?`}
                />
            ) : null}
        </DashboardLayout>
    )
}

export default PeopleList;