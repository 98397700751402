import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useWindowSize } from '@react-hook/window-size';
import {
  FiberManualRecord
} from "@material-ui/icons";
import { Link } from 'react-router-dom';
import Strip from '../../customComponent/Strip/Strip';
import OpeningCardData from "../../Utils/Json/OpeningCardData"
import application_contants from '../../constants/application_contant';
import RenderPage from '../../customComponent/RenderPage/RenderPage';
import SocialIcon from '../../customComponent/SocialIcon/SocialIcon';
import SEOComponent from '../../customComponent/SEO/SEOComponent';
import ExternalLayout from '../../customComponent/ExternalLayout/ExternalLayout';
import PeopleService from '../../../api/services/PeopleService';
import debugLogger from '../../Utils/debugLogger';
import { usePeople } from "../../context/PeopleProvider";
import { alertDangerConfirmMessage } from "../../customComponent/confirmationMessage/ConfirmationMessage";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";

const PeopleDetails = (props) => {
  const [sectionTitle, setSectionTitle] = useState('');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [data, setData] = useState([]);
  const [peopleState, { getAllPeopleList }] = usePeople();
  const [width] = useWindowSize();
  const [advisorTeam, setAdvisorTeam] = useState([]);
  const [teamMember, setTeamMember] = useState([]);
  const [postdoctoralTeam, setPostDoctoralTeam] = useState([]);
  const [researchTeam, setReSearchTeam] = useState([]);
  const [managerTeam, setManagerTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
const [fullName, setFullName] =  useState('');

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPeopleList();
    setSectionTitle(props.location.pathname.split('/')[2]);
  }, [])

  React.useEffect(() => {
    if (peopleState) {
      let advisorData = [];
      let teamMemberData = [];
      peopleState.map((item) => {
        if (item.typeId === 1 && item.status === 1) {
          advisorData.push(item)
        } else if (item.typeId === 2 && item.status === 1 && item.fullName.toLowerCase() === props.location.pathname.split('/')[2].toLowerCase()) {
          setTitle(item.title)
          setCode(item.code)
          setFullName(item.fullName)
          teamMemberData.push(item)
        }
      });
      setAdvisorTeam([...advisorData]);
      setTeamMember([...teamMemberData]);
    }
  }, [peopleState]);

  const getPeopleList = async () => {
    setIsLoading(true);
    try {
      await getAllPeopleList();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alertDangerConfirmMessage('Error', error);
    }
  }
  return (
    <ExternalLayout>
      <RenderPage
        id="tst-opening"
        className={`render px-0 render-page`}
        containerType="container-fluid"
      >
        <div>
          <Strip
            id="tst-opening"
            className="strip strip-no-padding"
            containerType="container"
          >
            <SEOComponent
              title={sectionTitle}
              description={sectionTitle}
              keywords="Speech Recognition, respin, SPIRE LAB, Navana"
              siteUrl={`/team/${sectionTitle}`} />

            <Row className="mx-0 p-3">
              <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-5 pt-5">
              <p className="mb-0 section-title align-left">
                                    <Link to={`/teammembers`} className="custom-link-item">{`People > Team Members >`}</Link>
                                    <span className="fw-600 pl-2 ">{`${fullName}`} </span>
                                </p>

                {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title line-height-35px  align-left">
                  {title ? title : ''}
                </h5> :
                  <h2 className="h2-small section-title align-left line-height-48px">
                    {title ? title : ''}
                  </h2>
                }
              </Col>
              {teamMember.length > 0 && teamMember.map((item, index) => (
                <Row className="mb-5">
                  <Col
                    xs={12}
                    ms={12}
                    md={12}
                    lg={12}
                    xl={3}
                    className="team-mobile-position pb-5 pl-5 pr-5 "
                  >
                    <div className="team-img-container pt-3 align-left team">
                      <div className="team-img-container team"> <img src={`${ApiConfig.url}${item.photoURL}`} alt="" />
                        <div
                          className="first-circle-container"
                          style={{ border: `15px solid ${item.firstClassName}` }}
                        ></div>
                        <div
                          className="second-circle-container"
                          style={{
                            border: `15px solid ${item.secondClassName}`,
                          }}
                        ></div>
                        </div>
                    </div>
                  </Col>

                  <Col xs={12} ms={12} md={12} lg={12} xl={9} className="pr-1 pb-5">
                    <div className={item.description === '' ? 'pl-5 pr-5 pb-5 mb-5' : ''}>
                      <h6 className="h6-medium mb-0 section-title nunitoSans-Regular align-left">{item.fullName}</h6>
                      <p className="mb-1 section-title nunitoSans-Regular align-left">{item.name}</p>
                      <p className="mb-0 section-title align-left">{item.description}</p>
                      {item.link ?
                      <a href={`${item.link}`} rel="noreferrer" target="_blank" className="p-very-small section-title custom-reference-links text-underline ">
                        View Profile </a>
                        // Rabindra Sabdabhidhan</a>
                        : null }
                    </div>

                  </Col>
                </Row>
              ))}
            </Row>
          </Strip>
          {/* <SocialIcon /> */}
        </div>
      </RenderPage>
    </ExternalLayout>
  )
}
export default PeopleDetails;
