import React from 'react';
import { useWindowSize } from "@react-hook/window-size";
import Slider from "react-slick";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import application_contants from '../../constants/application_contant';
import { Col, Row } from 'reactstrap';
import GalleryData from '../../Utils/Json/GalleryData';
import CardDetails from '../CardDetails/CardDetails';


function SampleNextArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowForwardIosOutlined style={{ color: '#C5C5C5', fontSize: '1.4rem' }} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick, arrowColor } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <ArrowBackIosOutlined style={{ color: '#C5C5C5', fontSize: '1.4rem' }} />
        </div>
    );
}

const ImgGrid = () => {

    const [width] = useWindowSize();

    const settings = {
        dots: false,
        infinite: true,
        loop: true,
        speed: 500,
        slidesToShow: width >= application_contants.MOBILE_BREAKPOINT ? 2 : (width >= 768 && width <= 1023) ? 2 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow  />,
        prevArrow: <SamplePrevArrow  />,
    };

    return (
        <Row className="mx-0 pt-3 pr-4">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <Slider {...settings}>
                     {GalleryData.length > 0 && GalleryData.map((item, index) => (
                        <Row key={index} className="mx-3">
                            {/* <Col xs={12} sm={12} md={12} lg={12} xl={11} className="px-0"> */}
                                {/* <CardDetails item={item}  /> */}
                                <Row className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-1 gallery-img-container">
                                <div className="bg-zircon-grey gallery-card-img-container cursor-pointer pr-3">
                                <img src={item} alt="" className="img-item" />
                                </div>
                                </Col>
                                </Row>
                            {/* </Col> */}
                        </Row>
                    ))} 
                </Slider>
            </Col>
        </Row>
    )
};


export default ImgGrid;