import React from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../../../../constants/application_contant";
import Strip from "../../../../../customComponent/Strip/Strip";
import RenderPage from "../../../../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../../../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../../../../customComponent/ExternalLayout/ExternalLayout";


const Storytitle2 = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-Storytitle2"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-Storytitle2"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Story"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/document/stories/Storytitle2" />
                    <Strip
                        id="tst-strip-story"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h1 className="h1-small section-title align-left pb-2 line-height-48px">Story Title2</h1> :
                                            <h2 className="h2-small section-title align-left pb-2 line-height-48px">Story Title2</h2>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-2">
                                            <h6 className="h6-small section-title align-left">Story Sub-Title2</h6>
                                    
                                            <p className="mb-0 section-title align-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis.</p>

                                                <p  className="mb-0 section-title align-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis.</p>

                                                <p  className="mb-0 section-title align-left">Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis. Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                                                Quod, neque earum eos aliquam delectus fugit obcaecati quam, 
                                                libero perspiciatis similique tenetur unde aperiam consectetur! 
                                                Quidem fuga fugiat in deserunt blanditiis.</p>

                                                <cite className="p-very-small" >Source Title / Author</cite>


                                                {/*
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-3">
                                                    {width <= application_contants.MOBILE_BREAKPOINT ? <p className="p-very-small section-title align-right">Hello World!</p> :
                                                    <p className="p-small section-title align-right">Hello world!</p>}
                                                </Col>
                                                */}
                                                

                                               </Col>

                                        {/*   */}                                               
                                        </Row>
                                           
                                            
                                        
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Storytitle2.defaultProps = {
    isClassName: true,
};

Storytitle2.propTypes = {
    isClassName: PropTypes.bool,
};

export default Storytitle2;
