import React, { useState } from 'react'
import { Row, Col, FormGroup, Label, Button, Input, Table } from 'reactstrap';


const MediaLinkDetails = (props) => {
    const { mediaDetails } = props;
    const [mediaName, setMediaName] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [date,setDate] = useState("");
    const [countDetails, setCountDetails] = useState([]);
    const [countId, setCountId] = useState(0);
    const [isCountEdit, setIsCountEdit] = useState(false);
    const [countEditIndex, setCountEditIndex] = useState(0);
    const [isPositionModal, setIsPositionModal] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);

    React.useEffect(() => {
        setCountDetails([...mediaDetails])
    }, [mediaDetails]);

    const handleMediaName = event => {
        setMediaName(event.target.value);
    };

    const handleMediaUrl = event => {
        setMediaUrl(event.target.value);
    };

    const handleDate = event => {
        setDate(event.target.value)
    }

    const handleMediaCancel = () => {
        setMediaName("");
        setMediaUrl("");
        setDate("");
    };

    const handleMediaSave = () => {
        let data;
        if (isCountEdit) {
            const item = countDetails[countEditIndex];
            item.mediaName = mediaName;
            item.mediaUrl = mediaUrl;
            item.date = date;
            setCountDetails([...countDetails]);
            props.handleMediaDetails(countDetails);
        } else {
            data = [
                {
                    mediaName: mediaName,
                    mediaUrl: mediaUrl,
                    date: date,
                }
            ];
            setCountDetails([...data, ...countDetails]);
            let array = [...data, ...countDetails]
            props.handleMediaDetails(array);
        }
        handleMediaCancel();
        setIsCountEdit(false);
    };


    const handleMediaDelete = index => {
        countDetails.splice(index, 1);
        setCountDetails([...countDetails]);
        props.handleMediaDetails(countDetails);
    };

    const handleMediaEdit = index => {
        const item = countDetails[index];
        setMediaUrl(item.mediaUrl);
        setMediaName(item.mediaName);
        setDate(item.date)
        setIsCountEdit(true);
        setCountEditIndex(parseInt(index));
    };



    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h6 className="h6-small external-title align-center title-bright-grey bg-zircon-grey p-2">
                    Media Link details :
                </h6>
            </Col>
            <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className="m-auto mt-4"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Media Name :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Media Name"
                                    id="Count"
                                    name="Count"
                                    className="form-control common-form-control"
                                    value={mediaName}
                                    onChange={event => handleMediaName(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Media Url :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Media Url"
                                    id="mediaUrl"
                                    name="mediaUrl"
                                    className="form-control common-form-control"
                                    value={mediaUrl}
                                    onChange={event =>
                                        handleMediaUrl(event)
                                    }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    date :
                                </Label>
                                <Input
                                    type="date"
                                    placeholder="date"
                                    id="date"
                                    name="date"
                                    className="form-control common-form-control"
                                    value={date}
                                    onChange={(event) => handleDate(event)}
                                    dateFormat="MM/DD/YYYY"
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col
                        xs={10}
                        sm={10}
                        md={8}
                        lg={6}
                        xl={6}
                        className="ml-auto mb-3"
                    >
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                    onClick={handleMediaCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button
                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                    onClick={handleMediaSave}
                                    disabled={mediaName && mediaUrl ? false : true}
                                >
                                    Save
                                </Button>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Table bordered responsive>
                                    <thead className="custom-table-thead">
                                        <tr>
                                            <th>Media Name</th>
                                            <th>Media Link</th>
                                            <th>Date</th>
                                            <th>Action</th>


                                        </tr>
                                    </thead>
                                    <tbody className="custom-table-tbody">
                                        {mediaDetails.map((it, index) => (
                                            <tr key={index}>
                                                <td>{it.mediaName}</td>
                                                <td>{it.mediaUrl}</td>
                                                <td>{it.date}</td>

                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                            <Button 
                                                             onClick={() =>
                                                                handleMediaDelete(index)
                                                            }
                                                            className="btn btn-small custom-danger-btn custom-btn-small fs-10px">
                                                                Delete
                                                            </Button>
                                                      
                                                            <Button 
                                                             onClick={() =>
                                                                handleMediaEdit(index)
                                                            }
                                                            className="btn btn-small custom-edit-btn custom-btn-small fs-10px">
                                                                Edit
                                                            </Button>
                                                       
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
         </Row>
    )
}


export default MediaLinkDetails;