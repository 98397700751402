import React, {useState} from 'react';
import { Row, Col } from "reactstrap";
import CustomNavigation, {
    CustomPrevButton,
    CustomNextButton,
    CustomPages,
  } from './Navigation';
  
import PDFViewer from 'mgr-pdf-viewer-react'
import pdfile1 from "../../../../assets/Media/media_vividlipi.pdf";

const exampleWrapperStyle = {
    width: '100%',
  };


class PdfReader extends React.Component {
    
    render() {
      return (
        <div style={exampleWrapperStyle}>
            <PDFViewer
                        document={{
                        url: pdfile1
                        }}
                        css="customPdfViewer"
                    //    style = {{
                    //        backgroundColor: "#292b2c"
                    //   }}
                        navigation ={{
                          css: {
                            previousPageBtn: 'customPrevBtn',
                            nextPageBtn: 'customNextBtn',
                            pages: 'customPages',
                            wrapper: 'customWrapper'
                          }
                        }}
                        scale={0.5} 
                        
                    />
                    </div>
       
        
      );
    }
  }



export default PdfReader ;
