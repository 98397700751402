import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import ContactUsService from '../../../api/services/ContactUsService';
import { isNumeric } from '../../Utils/customValidators';
import FormValidator from '../../Utils/FormValidator';
import IsInputInError from '../../Utils/IsInputInError';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../confirmationMessage/ConfirmationMessage';
import Loader from '../Loader/Loader';
import Strip from '../Strip/Strip';

let validator = new FormValidator([
    {
        field: "firstName",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter firstName",
            },
        ],
        touched: false,
    },
    {
        field: "lastName",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter lastName",
            },
        ],
        touched: false,
    },
    {
        field: "emailId",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter emailId",
            },
            {
                method: "isEmail",
                validWhen: true,
                message: "Please enter valid email Id.",
            },
        ],
        touched: false,
    },
    {
        field: "comment",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter comment",
            },
            {
                method: "isLength",
                validWhen: true,
                message: "comment should be  min 10 characters",
                args: [{ min: 15 }],
            },
        ],
        touched: false,
    },
]);

const ContactUsForm = () => {
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        organization: '',
        phoneNumber: '',
        comment: '',
    });
    const [validationField] = useState(validator.valid());
    const [isLoading, setIsLoading] = useState(false);

    let isValidate = true;

    const handleChange = e => {
        e.preventDefault();
        validator.touched(e.target.name);
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handlePhoneNumberChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            validator.addConstraint({
                field: 'phoneNumber',
                constraints: [
                    {
                        method: isNumeric, validWhen: true, message: 'phoneNumber allows only numeric characters'
                    },
                    {
                        method: 'isLength', validWhen: true, message: 'phoneNumber sholud be max 10 characters', args: [{ max: 10 }],
                    },
                ],
                touched: true,
            });
        } else {
            validator.removeConstraint('phoneNumber');
        }

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = async () => {
        let errorMessage = [];
        errorMessage = validator.getErrorMessage(values);
        if (errorMessage.length <= 0) {
            let data = {
                firstName: values.firstName,
                lastName: values.lastName,
                organization: values.organization,
                emailId: values.emailId,
                phoneNumber: values.phoneNumber,
                comment: values.comment
            }
            setIsLoading(true);
            await ContactUsService.contactUs(data)
                .then(async (res) => {
                    if (res.status === 200) {
                        try {
                            res.text().then(async (res) => {
                                let result = JSON.parse(res);
                                if (result.code === 0) {
                                    handleReset();
                                    alertSuccessMessage(`${result.message}`);
                                    setIsLoading(false);
                                } else {
                                    setIsLoading(false);
                                    alertDangerConfirmMessage(`${result.message}`);
                                }
                            });
                        } catch (err) {
                            console.log(err);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        } else {
            alertDangerConfirmMessage(`${errorMessage[0]}`);
        }

    }

    const handleReset = () => {
        setValues({
            ...values,
            firstName: '',
            lastName: '',
            comment: '',
            phoneNumber: '',
            organization: '',
            emailId: ''
        });
        validator.unTouched();
    }

    const validation = isValidate
        ? validator.validate(values)
        : validationField;

    return (
        <Strip id="tst-ContactUsForm"
            className="strip strip-no-padding"
            containerType="container-fluid">
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                    <Form>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    First Name*:
                                </Label>
                                <div className={IsInputInError(
                                    validation.firstName.isInvalid
                                )}>
                                    <Input
                                        type="text"
                                        placeholder="First Name"
                                        id="First Name"
                                        name="firstName"
                                        className="form-control common-form-control"
                                        value={values.firstName}
                                        onChange={handleChange}
                                    />
                                    <p className="p-small help-block">
                                        {validation.firstName.message}
                                    </p>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    Last Name*:
                                </Label>
                                <div className={IsInputInError(
                                    validation.lastName.isInvalid
                                )}>
                                    <Input
                                        type="text"
                                        placeholder="Last Name"
                                        id="Last Name"
                                        name="lastName"
                                        className="form-control common-form-control"
                                        value={values.lastName}
                                        onChange={handleChange}
                                    />
                                    <p className="p-small help-block">
                                        {validation.lastName.message}
                                    </p>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    Organization:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Organization"
                                    id="Organization"
                                    name="organization"
                                    className="form-control common-form-control"
                                    value={values.organization}
                                    onChange={handleChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    Email Id*:
                                </Label>
                                <div className={IsInputInError(
                                    validation.emailId.isInvalid
                                )}>
                                    <Input
                                        type="text"
                                        placeholder="Email Id"
                                        id="Email Id"
                                        name="emailId"
                                        className="form-control common-form-control"
                                        value={values.emailId}
                                        onChange={handleChange}
                                    />
                                    <p className="p-small help-block">
                                        {validation.emailId.message}
                                    </p>
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    Phone Number:
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Phone Number"
                                    id="Phone Number"
                                    name="phoneNumber"
                                    className="form-control common-form-control"
                                    value={values.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    maxLength={10}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mx-0 align-items-center">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Label className="common-label text-left">
                                    Question/Comment*:
                                </Label>
                                <div className={IsInputInError(
                                    validation.comment.isInvalid
                                )}>
                                    <Input
                                        type="textarea"
                                        placeholder="Message (10 Character minimum)" 
                                        id="comment"
                                        name="comment"
                                        className="form-control common-form-control"
                                        value={values.comment}
                                        onChange={handleChange}
                                        rows={10}
                                    />
                                    <p className="p-small help-block">
                                        {validation.comment.message}
                                    </p>
                                </div>
                            </Col>
                        </FormGroup>

                        <FormGroup row className="mx-0 align-items-center pt-3 pb-4">
                            <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                                <Row>
                                    <Col xs={12} sm={12} md={8} lg={5} xl={5} className="ml-auto">
                                        <Button className="custom-light-blue-btn btn-block" onClick={() => handleSubmit()}
                                            disabled={values.firstName !== '' && values.lastName !== '' && values.emailId !== '' && values.comment !== '' ? false : true}
                                        >
                                            Submit
                                     </Button>
                                    </Col>
                                </Row>
                            </Col>

                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            {isLoading ? (
                <div className="loader-position">
                    <Loader containerClassName="" />
                </div>
            ) : null}
        </Strip>
    )
}

export default ContactUsForm;