import React, { Component } from "react";
import {Row, Col, container, Table} from "react-bootstrap";
import ReactPlayer from "react-player";
import './audioplay.css';


import ExternalLayout from "../../../customComponent/ExternalLayout/ExternalLayout";
import RenderPage from "../../../customComponent/RenderPage/RenderPage";
import Strip from "../../../customComponent/Strip/Strip";
import { useWindowSize } from "@react-hook/window-size";
import SEOComponent from "../../../customComponent/SEO/SEOComponent";
import PropTypes from "prop-types";

 import man_logo from "../../../assets/man_logo.png";
 import woman_logo from "../../../assets/woman_logo.png";


// import Male Audio Samples
import audio_am1 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_am_1.mp3"; // Male audio
import audio_am2 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_am_2.mp3"; // Male audio
import audio_af1 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_af_1.mp3"; // Female audio
import audio_af2 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_af_2.mp3"; // Female audio

import audio_bm1 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_bm_1.mp3"; // Male audio
import audio_bm2 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_bm_2.mp3"; // Male audio
import audio_bf1 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_bf_1.mp3"; // Female audio
import audio_bf2 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_bf_2.mp3"; // Female audio

import audio_cm1 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_cm_1.mp3"; // Male audio
import audio_cm2 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_cm_2.mp3"; // Male audio
import audio_cf1 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_cf_1.mp3"; // Female audio
import audio_cf2 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_cf_2.mp3"; // Female audio

import audio_dm1 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_dm_1.mp3"; // Male audio
import audio_dm2 from "../../../assets/datasetsample/chhattisgarhi/male/Chhattisgarhi_dm_2.mp3"; // Male audio
import audio_df1 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_df_1.mp3"; // Female audio
import audio_df2 from "../../../assets/datasetsample/chhattisgarhi/female/Chhattisgarhi_df_2.mp3"; // Female audio


function Chhattisgarhi(){
    return( 
    <div className="container">
        <table className="audioTable">
            {/* Table Heading */}
            <thead>
            <tr>
                <th className="headTable">Dialect</th>
                <th className="headTable">Audio</th>
                <th className="headTable">Text</th>
            </tr>
            </thead>
            <tbody>
                {/* Table Dialect 1 == Central Chhattisgarhi */}
            <tr> {/* Table Row Sample 1 */}
                <td className="tableEvenDialect"> <p className="p-large">Central Chhattisgarhi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_am1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText">
                    <p className="p-medium-line-spacing ">
                    किसानी के अतेक महत्ता हे तभो ले देस म किसान मन के इस्थिति ह ओतेक बड़िहा नइये
                    </p>
                </td>
            </tr>
            <tr> {/* Table Row Sample 2 */}
                    <td className="tableOddDialect"><p className="p-large">Central Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">अलग अलग किसम के लकड़ी के अलग अलग जघा म महत्ता रथे</p></td>
            </tr>
            <tr> {/* Table Row Sample 3 */}
                <td className="tableEvenDialect"><p className="p-large">Central Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_am2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">वाणिज्य बेंक ह ये सबो खाता मन मा जमा कराए के सुबिधा देथे</p></td>
            </tr>
            <tr> {/* Table Row Sample 4 */}
                    <td className="tableOddDialect"><p className="p-large">Central Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_af2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">नवा नवा कोनो परकार के दुकान के खोलब म कतको झन मन ह उधारी बाड़ही देय बर नइ धरय</p></td>
            </tr>
            {/* Table Dialect 2 == Eastern Chhattisgarhi */}
            <tr> {/* Table Row Sample 5 */}
                <td className="tableEvenDialect"><p className="p-large">Eastern Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_bm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">आयकर बिभाग वाले मन ह कोनो मनखे के घर म छापा इहीं नांव लेके ही मारथे</p></td>
            </tr>
            <tr> {/* Table Row Sample 6 */}
                    <td className="tableOddDialect"><p className="p-large">Eastern Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">कइ बखत मानसून आए के बाद म सरलग कइ दिन ले बरसा होवत रहिथे</p></td>
            </tr>

            <tr> {/* Table Row Sample 7 */}
                <td className="tableEvenDialect"> <p className="p-large">Eastern Chhattisgarhi </p></td>
                <td className="tableEvenAudio">
                   
                <img className="img_logo_audio"
                        src={man_logo}/>
                    <ReactPlayer   
                        url={audio_bm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing ">मनखे मन ह कोनो भी अलहन आए ले पइसा मिल जाए कहिके ही पइसा निवेस करे रहिथे</p></td>
            </tr>
            <tr> {/* Table Row Sample 8 */}
                    <td className="tableOddDialect"><p className="p-large">Eastern Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                        <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_bf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">अलग अलग फसल के हिसाब ले भारत म बनेच किसम के फसल तिहार मनाए जाथे</p></td>
            </tr>
            {/* Table Dialect 3 == Western Chhattisgarhi */}
            <tr> {/* Table Row Sample 9 */}
                <td className="tableEvenDialect"><p className="p-large">Western Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">जादा करजा म घलो लद के बिहाव नइ करना चाही</p></td>
            </tr>
            <tr> {/* Table Row Sample 10 */}
                    <td className="tableOddDialect"><p className="p-large">Western Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">कोनो भी पेड़ पउधा होवय ते फसल पानी होवय ओखर बर बरोबर सकल जतन करे बर परथे</p></td>
            </tr>
            <tr> {/* Table Row Sample 11 */}
                <td className="tableEvenDialect"><p className="p-large">Western Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_cm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">बटरा के फसल एक परकार के दलहन वाले फसल हरय</p></td>
            </tr>
            <tr> {/* Table Row Sample 12 */}
                    <td className="tableOddDialect"><p className="p-large">Western Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_cf2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">मनखे तीर जउन भी मकान , जमीन होथे सरकार ह ओखर हिसाब ले टेक्स लेथे</p></td>
            </tr>
                {/* Table Dialect 4 == Northern Chhattisgarhi */}
            <tr> {/* Table Row Sample 13 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">टागी हर मोट लोहा कर होथे अउ ओमहा ढेरेच धार रहेल</p></td>
            </tr>
            <tr> {/* Table Row Sample 14 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df1}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">गाड़ा ल बिगर बइला भइसा कर एक जगहा ले दूसर जगहा लइजई ढेरे कठिन काम हवे</p></td>
            </tr>
            <tr> {/* Table Row Sample 15 */}
                <td className="tableEvenDialect"><p className="p-large">Southern Chhattisgarhi </p></td>
                    <td className="tableEvenAudio">
                    <img className="img_logo_audio"
                        src={man_logo}/>
                        <ReactPlayer   
                        url={audio_dm2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableEvenText"><p className="p-medium-line-spacing">गाँव में जेन ढेरेच गरीब रहिस उहीं मन हर चिटफंड कंपनी के धोखाघड़ी ले बाचे हे</p></td>
            </tr>
            <tr> {/* Table Row Sample 16 */}
                    <td className="tableOddDialect"><p className="p-large">Southern Chhattisgarhi</p></td>
                    <td className="tableOddAudio">
                    <img className="img_logo_audio"
                        src={woman_logo}/>
                        <ReactPlayer   
                        url={audio_df2}
                        width="200pt"
                        height="50px"
                        playing={false}
                        controls={true}
                        type = 'audio/mp3'
                    />
                </td>
                <td className="tableOddText"><p className="p-medium-line-spacing">दूद के मांग ल देखके सरकार हर डेयरी बेवसाय ल ढेरे बढ़ावा देहत हे</p></td>
            </tr>



            </tbody>

           
            
        </table>


    </div>

    );
}


const SampleChhattisgarhi = (props) => {
    const [width] = useWindowSize();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-SampleChhattisgarhi"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-SampleChhattisgarhi"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="SampleChhattisgarhi"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/SampleChhattisgarhi" />
                    <Strip
                        id="tst-strip-SampleChhattisgarhi"
                        className="strip strip-no-padding"
                        containerType="container">

                    <div className="mx-0  pt-5">  
                        <h5 className="h5-medium section-title jostRegular title-navy-blue align-left pt-5 pb-5 line-height-48px pl-3"> Sample Chhattisgarhi Audio</h5>
                            <div className="mx-0 mb-5 pb-5">
                                <Chhattisgarhi />
                            </div>
                    </div>

                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

export default SampleChhattisgarhi;