import React, { useState } from 'react';
import { ModalBody, Row, Col, Modal, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Axios from "axios";
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import DatasetsService from '../../../../api/services/DatasetsService';
import Strip from '../../../customComponent/Strip/Strip';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponent/Loader/Loader';

const AddDatasets = (props) => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [time, setTime] = useState('');
    const [detailTitle, setDetailTitle] = useState('');
    const [description, setDescription] = useState('')
    const [linguality, setLinguality] = useState('');
    const [language, setLanguage] = useState('');
    const [catalogue, setCatalogue] = useState('');
    const [datasize1, setDatasize1] = useState('');
    const [datasize2, setDatasize2] = useState('');
    const [datasize3, setDatasize3] = useState('');
    const [annotation, setAnnotation] = useState('');
    const [sampling, setSampling] = useState('');
    const [validation, setValidation] = useState('');
    const [datacreator, setDatacreator] = useState('');
    const [publishingyear, setPublishingyear] = useState('');
    const [researchpurpose, setResearchpurpose] = useState('');
    const [publications, setPublications] = useState('');
    const [download, setDownload] = useState('');
    const [samplefiles, setSamplefiles] = useState('');
    const [feedback, setFeedback] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [photo, setPhoto] = useState('');
    const [photoFileName, setPhotoFileName] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setTitle('');
            setDetailTitle('');
            setDescription('');
            setTime('');
            setLinguality('');
            setLanguage('');
            setCatalogue('');
            setDatasize1('');
            setDatasize2('');
            setDatasize3('');
            setAnnotation('');
            setSampling('');
            setValidation('');
            setDatacreator('');
            setPublishingyear('');
            setResearchpurpose('');
            setPublications('');
            setDownload('');
            setSamplefiles('');
            setFeedback('');
            setId('');
        } else {
            setTitle(props.item.title)
            setDetailTitle(props.item.detailTitle)
            setDescription(props.item.description)
            setId(props.item.id)
            setTime(props.item.time)
            setLinguality(props.item.linguality)
            setLanguage(props.item.language)
            setCatalogue(props.item.catalogue)
            setDatasize1(props.item.datasize1)
            setDatasize2(props.item.datasize2)
            setDatasize3(props.item.datasize3)
            setAnnotation(props.item.annotation)
            setSampling(props.item.sampling)
            setValidation(props.item.validation)
            setDatacreator(props.item.datacreator)
            setPublishingyear(props.item.publishingyear)
            setResearchpurpose(props.item.researchpurpose)
            setPublications(props.item.publications)
            setDownload(props.item.download)
            setSamplefiles(props.item.samplefiles)
            setFeedback(props.item.feedback)
        }
        setIsFileChanged(false);
    }, [isAdd, props]);

    const handleTitle = (event) => {
        setTitle(event.target.value)
    };

    const handleDetailTitle= (event) => {
        setDetailTitle(event.target.value)
    };

    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleTime = (event) => {
        setTime(event.target.value)
    }

    const handleLinguality = (event) => {
        setLinguality(event.target.value)
    }

    const handleLanguage = (event) => {
        setLanguage(event.target.value)
    }

    const handleCatalogue = (event) => {
        setCatalogue(event.target.value)
    }

    const handleDatasize1 = (event) => {
        setDatasize1(event.target.value)
    }

    const handleDatasize2 = (event) => {
        setDatasize2(event.target.value)
    }

    const handleDatasize3 = (event) => {
        setDatasize3(event.target.value)
    }

    const handleAnnotation = (event) => {
        setAnnotation(event.target.value)
    }

    const handleSampling = (event) => {
        setSampling(event.target.value)
    }

    const handleValidation = (event) => {
        setValidation(event.target.value)
    }

    const handleDatacreator = (event) => {
        setDatacreator(event.target.value)
    }

    const handlePublishingyear = (event) => {
        setPublishingyear(event.target.value)
    }

    const handleResearchpurpose = (event) => {
        setResearchpurpose(event.target.value)
    }

    const handlePublications = (event) => {
        setPublications(event.target.value)
    }

    const handleDownload = (event) => {
        setDownload(event.target.value)
    }

    const handleSamplefiles = (event) => {
        setSamplefiles(event.target.value)
    }

    const handleFeedback = (event) => {
        setFeedback(event.target.value)
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            title: title,
            userId: userId,
            id: id,
            description: description,
            detailTitle: detailTitle,
            time: time,
            language: language,
            linguality: linguality,
            catalogue: catalogue,
            datasize1: datasize1,
            datasize2: datasize2,
            datasize3: datasize3,
            annotation: annotation,
            sampling: sampling,
            validation: validation,
            publishingyear: publishingyear,
            researchpurpose: researchpurpose,
            datacreator: datacreator,
            publications: publications,
            samplefiles: samplefiles,
            download: download,
            feedback: feedback,
        };

        if (isFileChanged) {
            UploadImage(photoFile, data)
        } else {
            insertOrUpdate(data);
        }
    }

    const UploadImage = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/banner/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        console.log('photos : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };


    const insertOrUpdate = async (data) => {
        await DatasetsService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-datasets"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-dark-pink">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                            <Row>
                                <Col xs={10} sm={10} md={10} lg={6} xl={12} className="m-auto">
                                    <Row className="m-auto">
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className="pt-3 pb-5"
                                        >
                                            <Row className="mt-2">
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Form>
                                                        <FormGroup row className="mx-0 align-items-center justify-content-center">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                     Title*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="title"
                                                                    id="title"
                                                                    name="title"
                                                                    className="form-control common-form-control"
                                                                    value={title}
                                                                    onChange={(event) => handleTitle(event)}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                     Time*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="time"
                                                                    name="time"
                                                                    className="form-control common-form-control"
                                                                    value={time}
                                                                    onChange={(event) => handleTime(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Detail Title*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder=" Detail title"
                                                                    id="detailtitle"
                                                                    name="detailtitle"
                                                                    className="form-control common-form-control"
                                                                    value={detailTitle}
                                                                    onChange={(event) => handleDetailTitle(event)}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                    Description*:
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    placeholder="Description"
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control common-form-control"
                                                                    value={description}
                                                                    onChange={(event) => handleDescription(event)}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Language(s):
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Language"
                                                                    id="Language"
                                                                    name="Language"
                                                                    className="form-control common-form-control"
                                                                    value={language}
                                                                    onChange={(event) => handleLanguage(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Linguality:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text/text"
                                                                    id="Linguality"
                                                                    name="Linguality"
                                                                    className="form-control common-form-control"
                                                                    value={linguality}
                                                                    onChange={(event) => handleLinguality(event)}
                                                                    rows={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Catalogue no:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="number"
                                                                    id="Catalogue"
                                                                    name="Catalogue"
                                                                    className="form-control common-form-control"
                                                                    value={catalogue}
                                                                    onChange={(event) => handleCatalogue(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Data Size (Hours)*:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="HH:MM:SS"
                                                                    id="datasize1"
                                                                    name="datasize1"
                                                                    className="form-control common-form-control"
                                                                    value={datasize1}
                                                                    onChange={(event) => handleDatasize1(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Data Size (#Sentences):
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="datasize2"
                                                                    name="datasize2"
                                                                    className="form-control common-form-control"
                                                                    value={datasize2}
                                                                    onChange={(event) => handleDatasize2(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Data size (# Speakers):
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text:XX,text:XX"
                                                                    id="datasize3"
                                                                    name="datasize3"
                                                                    className="form-control common-form-control"
                                                                    value={datasize3}
                                                                    onChange={(event) => handleDatasize3(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Annotation file availability:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="yes/no"
                                                                    id="annotation"
                                                                    name="annotation"
                                                                    className="form-control common-form-control"
                                                                    value={annotation}
                                                                    onChange={(event) => handleAnnotation(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Sampling rate/ encoding/ recording chara.:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="sampling"
                                                                    name="sampling"
                                                                    className="form-control common-form-control"
                                                                    value={sampling}
                                                                    onChange={(event) => handleSampling(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Validation status:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="yes/no"
                                                                    id="validation"
                                                                    name="validation"
                                                                    className="form-control common-form-control"
                                                                    value={validation}
                                                                    onChange={(event) => handleValidation(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Data creator:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="datacreator"
                                                                    name="datacreator"
                                                                    className="form-control common-form-control"
                                                                    value={datacreator}
                                                                    onChange={(event) => handleDatacreator(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Year of publishing:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="year"
                                                                    id="publishingyear"
                                                                    name="publishingyear"
                                                                    className="form-control common-form-control"
                                                                    value={publishingyear}
                                                                    onChange={(event) => handlePublishingyear(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Suggested research purpose/ areas:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="researchpurpose"
                                                                    name="researchpurpose"
                                                                    className="form-control common-form-control"
                                                                    value={researchpurpose}
                                                                    onChange={(event) => handleResearchpurpose(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Publications:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="publications"
                                                                    name="publications"
                                                                    className="form-control common-form-control"
                                                                    value={publications}
                                                                    onChange={(event) => handlePublications(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Sample files:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="samplefiles"
                                                                    name="samplefiles"
                                                                    className="form-control common-form-control"
                                                                    value={samplefiles}
                                                                    onChange={(event) => handleSamplefiles(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row className="mx-0 align-items-center">
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Download:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="link"
                                                                    id="download"
                                                                    name="download"
                                                                    className="form-control common-form-control"
                                                                    value={download}
                                                                    onChange={(event) => handleDownload(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                                                <Label className="common-label text-left">
                                                                Feedback:
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="text"
                                                                    id="Feedback"
                                                                    name="Feedback"
                                                                    className="form-control common-form-control"
                                                                    value={feedback}
                                                                    onChange={(event) => handleFeedback(event)}
                                                                    rows={10}
                                                                /> 
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="no-margin">
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={{ offset: 4, size: 6 }}
                                                    lg={{ offset: 4, size: 6 }}
                                                    xl={{ offset: 4, size: 6 }}
                                                >
                                                    <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button
                                                                className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                onClick={toggle}
                                                            >
                                                                Cancel
                                                          </Button>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <Button

                                                                className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                onClick={() => handleSubmit()}
                                                            >
                                                                Submit
                                                         </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}

export default AddDatasets;