import React,{useState} from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "@react-hook/window-size";
import { Row, Col } from "reactstrap";
import application_contants from "../../constants/application_contant";
import Strip from "../../customComponent/Strip/Strip";
import RenderPage from "../../customComponent/RenderPage/RenderPage";
import SEOComponent from "../../customComponent/SEO/SEOComponent";
import ExternalLayout from "../../customComponent/ExternalLayout/ExternalLayout";
import MediaData from "../../Utils/Json/MediaData"
import MediaComponent from "../../customComponent/MediaComponent/MediaComponent";
import debugLogger from "../../Utils/debugLogger";
import MediaService from '../../../api/services/MediaService';

const Media = (props) => {
    const [width] = useWindowSize();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    React.useEffect(() => {
        window.scrollTo(0, 0);
        getList();
    }, [])

    const getList = async () => {
        setIsLoading(true);
        await MediaService.getMediaList()
            .then(async (res) => {
                console.log("openings result",res)
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log("before data list",result)
                            if (result.code === 0) {
                                if (result.data) {
                                    debugLogger(result.data);
                                    const lists = result.data.list.filter((it) => it.status === 1);
                                    setData([...lists])
                                    console.log("data list",...result.data.list)
                                    // console.log(result.data.docUrl)
                                    setIsLoading(false);
                                } else {
                                    setData([]);
                                    setIsLoading(false);
                                }
                            } else {
                                setData([]);
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <ExternalLayout>
            <RenderPage
                id="tst-media"
                className={`render px-0 ${props.isClassName ? 'render-page ' : 'min-height-auto'}`}
                containerType="container-fluid"
            >
                <Strip
                    id="tst-media"
                    className="strip strip-no-padding"
                    containerType="container"
                >
                    <SEOComponent
                        title="Media"
                        keywords="Speech Recognition, respin, SPIRE LAB, Navana"
                        description="Speech recognition in agriculture and finance for the poor is an initiative predominantly to create resources and make them available as a digital public good"
                        siteUrl="/media" />
                    <Strip
                        id="tst-strip-openings"
                        className="strip strip-no-padding"
                        containerType="container">
                        <Row className="mx-0">
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto pt-4 px-0">
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-5 pb-4 px-0">
                                        {width <= application_contants.MOBILE_BREAKPOINT ? <h5 className="h5-medium section-title align-left">Media</h5> :
                                            <h3 className="h3-large section-title align-left">Media</h3>}
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pb-4 pt-4 px-0">
                                        <Row className="mx-0 pb-5">
                                            { data.map((item, index) => {
                                                return <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index} className="px-0">
                                                    <Row className=" mx-0">
                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 ">
                                                        <p className="p-large nunitoSans-Regular align-left media-card-position pt-4">{item.title}</p>
                                                        <MediaComponent data={item.title} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Strip>
                </Strip>
            </RenderPage>
        </ExternalLayout>
    );
};

Media.defaultProps = {
    isClassName: true,
};

Media.propTypes = {
    isClassName: PropTypes.bool,
};

export default Media;
