import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody, Form, Label, Button, FormGroup, Input } from 'reactstrap';
import DocumentsService from '../../../../api/services/DocumentsService';
import { alertDangerConfirmMessage, alertSuccessMessage } from '../../../customComponent/confirmationMessage/ConfirmationMessage';
import Loader from '../../../customComponent/Loader/Loader';
import Strip from '../../../customComponent/Strip/Strip';
import Axios from "axios";
import { ApiConfig } from '../../../../api/apiConfig/apiConfig';
import FileUploader from '../../../customComponent/FileUploader/FileUploader';

const AddDocuments = (props) => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [docTypeTitle, setDocTypeTitle] = useState('');
    const [date, setDate] = useState('');
    const [docUrl, setDocUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState('');
    const [pdfFileName, setPdfFileName] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const { isModal, toggle, isAdd } = props;

    React.useEffect(() => {
        if (isAdd) {
            setTitle('');
            setId('');
            setDocTypeTitle('')
            setDate('');
            setDocUrl('')
        } else {
            setTitle(props.item.title);
            setId(props.item.id);
            setDocTypeTitle(props.item.docTypeTitle)
            setDate(props.item.date);
            setDocUrl(props.item.docUrl);
            setDocUrl(props.item.docUrl);
            setPdfFileName(props.item.docUrl)
        }
    }, [isAdd, props]);

    const handleTitle = (e) => {
        setTitle(e.target.value)
    }

    const handleDocTypeTitle = (e) => {
        setDocTypeTitle(e.target.value)
    }

    const handleDate = (e) => {
        setDate(e.target.value)
    }
    const getPdfFile = async (item) => {
        await setPdfFile(item[0].file);
        await setPdfFileName(item[0].file.name);
        setIsFileChanged(true);
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            userId: userId,
            id: id,
            title: title,
            docTypeTitle: docTypeTitle,
            date: date,
            docUrl: isFileChanged ? `/documents/${pdfFileName}` : pdfFileName,
        }
        if (isFileChanged) {
            UploadFiles(pdfFile, data)
        } else {
            insertOrUpdate(data);
        }
    }
    const UploadFiles = async (file, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/documents/");
        await data.append("image", file);
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        console.log('pdf : ', data);
        Axios.post(`${ApiConfig.baseUrl}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };
    const insertOrUpdate = async (data) => {
        await DocumentsService.insertOrUpdate(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            console.log()
                            if (result.code === 0) {
                                alertSuccessMessage(`${result.message}`);
                                props.handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-opening"
            className="strip strip-no-padding"
            containerType="container-fluid"
        >
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Modal
                        isOpen={isModal}
                        toggle={toggle}
                        className="modal-container modal-xl"
                    >
                        <div className="modal-header-container bg-dark-pink">
                            <Row className="no-margin align-items-center py-2">
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <p className="mb-0 p-large align-left title-white fw-blod">
                                        {isAdd ? "ADD" : "UPDATE "}
                                    </p>
                                </Col>
                                <Col
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    className="cursor-pointer"
                                    onClick={toggle}
                                >
                                    <p className="mb-0 p-medium internal-page__title align-right title-white">Close</p>
                                </Col>
                            </Row>
                        </div>
                        <ModalBody className="modal-content-container">
                                <Row>
                                    <Col xs={11} sm={11} md={11} lg={11} xl={11} className="m-auto">
                                        <Row className="m-auto">
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="pt-3 pb-5"
                                            >
                                                <Row className="mt-2">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Form>
                                                           <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Document Title*:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Document Title"
                                                                        id="title"
                                                                        name="title"
                                                                        className="form-control common-form-control"
                                                                        value={title}
                                                                        onChange={(event) => handleTitle(event)}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                    Document Type Name:
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Document Type Name"
                                                                        id="doc type"
                                                                        name="doc type"
                                                                        className="form-control common-form-control"
                                                                        value={docTypeTitle}
                                                                        onChange={(event) => handleDocTypeTitle(event)}
                                                                    />
                                                                </Col>
                                                               </FormGroup>
                                                            <FormGroup row className="mx-0 align-items-start">
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                        <Label className="common-label text-left">
                                                                        Date:
                                                                        </Label>
                                                                        <Input
                                                                            type="date"
                                                                            placeholder="Date"
                                                                            id="date"
                                                                            name="date"
                                                                            className="form-control common-form-control"
                                                                            value={date}
                                                                            onChange={(event) => handleDate(event)}
                                                                            dateFormat="MM/DD/YYYY"
                                                                        />
                                                                    </Col>

                                                                 <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                                    <Label className="common-label text-left">
                                                                        Upload Report File :
                                                                    </Label>
                                                                    <FileUploader
                                                                        isImg={false}
                                                                        getPdfFile={(item) =>
                                                                            getPdfFile(item)
                                                                        }
                                                                        value={pdfFileName}
                                                                        isEditable={false}
                                                                        labelName="Upload Report File"
                                                                    />
                                                                </Col>
                                                                </FormGroup>
                                                      </Form>
                                                    </Col>
                                                </Row>
                                                <Row className="no-margin">
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={{ offset: 3, size: 6 }}
                                                        lg={{ offset: 3, size: 6 }}
                                                        xl={{ offset: 3, size: 6 }}
                                                    >
                                                        <Row>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button
                                                                    className="btn btn-block custom-danger-btn custom-btn--small br-4px"
                                                                    onClick={toggle}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                                <Button

                                                                    className="btn btn-block custom-theme-btn custom-btn--small br-4px"
                                                                    onClick={() => handleSubmit()}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            {isLoading ? (
                                <div className="loader-position">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}

                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </Strip>
    )
}
export default AddDocuments;